import React, { createContext, useContext, useEffect, useState } from "react";
interface BoardsContextType {
  isDialogOpen: boolean;
  setIsDialogOpen: (value: boolean) => void;
  isDraggingDisable: boolean;
  setIsDraggingDisable: (value: boolean) => void;
}
const BoardsContext = createContext<BoardsContextType>({
  isDialogOpen: false,
  setIsDialogOpen: () => {},
  isDraggingDisable: false,
  setIsDraggingDisable: () => {},
});

export const BoardsProvider = ({ children }: { children: React.ReactNode }) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isDraggingDisable, setIsDraggingDisable] = useState(false);

  useEffect(() => {
    console.log("isDraggingDisable", isDraggingDisable);
  }, [isDraggingDisable]);
  return (
    <BoardsContext.Provider
      value={{
        isDialogOpen,
        setIsDialogOpen,
        isDraggingDisable,
        setIsDraggingDisable,
      }}
    >
      {children}
    </BoardsContext.Provider>
  );
};

export const useBoardsContext = () => useContext(BoardsContext);
