import { QueryData } from "@/hooks/useFilter";
import { apis, baseUrl } from ".";

const useSearch = () => {
  const autoSuggestion = async (query: string) => {
    const { data } = await apis.get(
      `${baseUrl}/client/api/v1/search/auto-suggest`,
      {
        params: {
          query,
        },
      },
    );
    return data?.data;
  };

  const searchData = async (params: QueryData) => {
    if (!params?.query?.searchText) return;
    const { data } = await apis.post(`${baseUrl}/client/api/v1/search`, {
      data: params,
    });
    return data?.data;
  };

  return { autoSuggestion, searchData };
};

export default useSearch;
