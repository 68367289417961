import React from "react";
import {
  Sheet,
  SheetClose,
  SheetContent,
  SheetFooter,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from "@/components/ui/sheet";
import { Button } from "@/components/ui/button";
import { ChevronRight, X } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "@/hooks/useAuth";
import Header from "@/components/Global/header/Header";
import { Separator } from "@/components/ui/separator";
import { useUserContext } from "@/context/user";

const menuItems = [
  {
    icon: "/profile/editprofile.svg",
    label: "Your profile",
    href: "/user/profile",
  },
  {
    icon: "/profile/profileinfo.svg",
    label: "Account details",
    href: "/account/details",
  },
  {
    icon: "/profile/paint.svg",
    label: "Artists you follow",
    href: "/artists/following",
  },
  {
    icon: "/profile/work.svg",
    label: "Businesses you follow",
    href: "/businesses/following",
  },
  // { icon: "/profile/cube.svg", label: "My purchases", href: "/purchases" },
  // { icon: "/profile/rupee.svg", label: "My sales", href: "/sales" },
  // { icon: "/profile/stats.svg", label: "Insights", href: "/insights" },
  // {
  //   icon: "/profile/percentage.svg",
  //   label: "Object royalties",
  //   href: "/royalties",
  // },
  // {
  //   icon: "/profile/protect.svg",
  //   label: "Insurance manager",
  //   href: "/insurance",
  // },
  { icon: "/profile/call.svg", label: "Contact Asign Care", href: "/contact" },
  { icon: "/profile/exit.svg", label: "Log out", href: null }, // Handle logout separately
];

const Sidebar = ({ children }: { children: React.ReactNode }) => {
  const navigate = useNavigate();
  const { me, updateUser, updateUserLogin } = useUserContext();
  const { logout } = useAuth();

  const handleMenuClick = (href: string | null) => {
    if (href) {
      navigate(href);
    } else {
      new Promise((resolve) => {
        updateUserLogin.mutate({
          isLoggedIn: false,
        });
        resolve(true);
      }).then(() => {
        logout();
      });
    }
  };

  return (
    <Sheet>
      <SheetTrigger asChild>{children}</SheetTrigger>
      <SheetContent className="w-[300px] mq750:w-full overflow-auto h-full py-8 px-10">
        <SheetHeader className="py-4 border-b border-gray-200">
          <div className="flex flex-col gap-x-[30px] justify-between">
            <SheetTitle className="flex items-center justify-between text-xl font-semibold ">
              <img
                loading="lazy"
                alt="Aisgn Logo"
                src="/logo.svg"
                className="cursor-pointer"
                onClick={() => navigate("/discover/feed")}
              />
              <SheetClose>
                <X className="w-6 h-6 " strokeWidth={1} />
              </SheetClose>
            </SheetTitle>
            <Separator className="my-[32px] " />
          </div>
        </SheetHeader>
        <div className="flex flex-col gap-5 py-1">
          {menuItems.map((item, index) => (
            <SheetClose asChild>
              <Button
                key={item.href}
                variant="link"
                className="justify-start no-underline [&:nth-last-child(2)]:border-[#E5E5E5] [&:nth-last-child(2)]:border-t [&:nth-last-child(2)]:rounded-none [&:nth-last-child(2)]:pt-8 w-full p-0 text-base font-normal"
                onClick={() => handleMenuClick(item.href)}
                disabled={item.label === "Your profile" && me?.userType === 5}
              >
                <div className="flex items-center gap-3 ">
                  <img src={item.icon} className="mr-4 text-lg" />
                  {item.label}
                </div>
                {index !== 5 && (
                  <ChevronRight className="w-5 h-5 ml-auto text-[#1D1D1D]" />
                )}
              </Button>
            </SheetClose>
          ))}
        </div>
      </SheetContent>
    </Sheet>
  );
};

export default Sidebar;
