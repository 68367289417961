import Collectors from "@/pages/Pre-login/Collectors";

export const faqs: any = {
  landing_page: [
    {
      title: "What is Asign?",
      content:
        "<p class='text-[18px] max-md:text-[14px] text-[#696969] tracking-0 leading-[120%] max-w-[90%]'>Asign is a one-of-its-kind platform that allows you to create a registry of your artwork by physically connecting your artwork to a digital platform. Asign is the only platform that allows you to organise, register, display, buy, and sell artwork, all on one platform. We are building a community for all—artists, collectors, and businesses—in one place. Asign provides a new-world experience with new buyers and markets in a safe and secure space where you can connect with like-minded individuals and buy and sell art and collectibles.</p>",
    },
    {
      title: "How does Asign work?",
      content:
        "<p class='text-[18px] max-md:text-[14px] text-[#696969] tracking-0 leading-[120%] max-w-[90%]'>Asign verifies artists, collectors, and galleries and connects them on one digital platform. This digital platform is commonplace for artists to sell artworks verified by Asign and for collectors and galleries to showcase their portfolios and buy and resell authenticated artwork. Asign regulates these interactions and provides a secure ecosystem for art transactions.</p>",
    },
    {
      title: "How does Asign catalogue a collection?",
      content:
        "<p class='text-[18px] max-md:text-[14px]  text-[#696969] tracking-0 leading-[120%] max-w-[90%]'>After joining Asign, art owners can request to add their artwork to the platform. Our team of experts will verify and authenticate these assets physically through online and offline interactions. After verification and authentication of assets, Asign stores each asset’s information on a digital dashboard to create a catalogue. Users can keep this collection private or showcase it to other Asign users.</p>",
    },
    {
      title: "How do I authenticate my artwork with Asign?",

      content:
        '<div><p class="text-[18px] max-md:text-[14px] text-[#696969] max-w-[90%] leading-[120%] tracking-0">Asign teams up with art experts to verify, authenticate, and label your artwork.<br /><br />Follow these steps to authenticate your assets:</p><ol class="text-[18px] max-md:text-[14px] pt-[16px]  list-square ml-[18px] text-[#696969] max-w-[90%] space-y-[16px] leading-[120%] tracking-0"><li>Request to upload your artwork on Asign</li><li>Schedule a physical survey</li><li>Schedule agent appointment and provide documentation</li><li>Artwork will be added to your Asign catalogue</li></ol></div>',
    },
    {
      title: "How can one buy or sell artwork?",

      content:
        "<p class='text-[18px] max-md:text-[14px] text-[#696969] tracking-0 leading-[120%] max-w-[90%]'>Once an artwork is uploaded on Asign, art owners can request to make their artwork available for sale on the marketplace. Asign then curates the artwork and may make it visible and discoverable on the marketplace. Potential buyers will have different modes of buying the artwork, including buying at a fixed price, making an offer to the art owner, reserving the artwork till they can be sure, and entering art auctions.</p>",
    },
    {
      title: "How do I get Asign?",

      content:
        '<p class="text-[18px] max-md:text-[14px] text-[#696969] tracking-0 leading-[120%] max-w-[90%]">At present, you can join Asign through invitation only. Asign currently offers its physical KYC, artwork verification and authentication, and Advisory Services in Chennai, Delhi NCR, and Mumbai.<br /><br />After receiving an invitation to join, you can create an account on Asign. Following account creation, you will sign contracts with Asign and get onboarded on the Asign platform.</p>',
    },
  ],
  artist: [
    {
      title: "Is there a fee to register on Asign?",

      content:
        "<p class='text-[18px] max-md:text-[14px] text-[#696969] tracking-0 leading-[120%] max-w-[90%]'> You can get Asign for free. We help you onboard and sell your artwork on the platform. We only charge a commission on the sale of your artwork.</p>",
    },
    {
      title: "How do I build my Catalogue Raisonné?",

      content:
        "<div><p class=\"text-[18px] max-md:text-[14px] text-[#696969] max-w-[90%] leading-[120%] tracking-0\">Asign helps you build your Catalogue Raisonné in five simple steps:<br /></p><ol class=\"text-[18px] max-md:text-[14px] pt-[16px]  list-none  text-[#696969]  space-y-[24px] leading-[120%] tracking-0\"><li><span class='text-[#1D1D1D]'>Step 1:</span> Create your Asign account</li><li><span class='text-[#1D1D1D]'>Step 2:</span> Request to add your artwork to the Asign platform</li><li><span class='text-[#1D1D1D]'>Step 3:</span> Schedule an agent visit to your premises or studio</li><li><span class='text-[#1D1D1D]'>Step 4:</span> Our agents label your artwork with Asign’s proprietary technology</li><li><span class='text-[#1D1D1D]'>Step 5:</span> Your artwork’s data will be uploaded to the Asign platform and made visible on your profile</li></ol></div>",
    },
    {
      title: "How does Asign help me secure my rights?",

      content:
        "<p class='text-[18px] max-md:text-[14px] text-[#696969] tracking-0 leading-[120%] max-w-[90%]'> Asign has a dedicated team to help you with artist resale rights, copyrights, and royalties. You can contact us or your advisor for further information.</p>",
    },
    {
      title: "How does Asign help me build my community?",

      content:
        "<p class='text-[18px] max-md:text-[14px] text-[#696969] tracking-0 leading-[120%] max-w-[90%]'> By creating an account on Asign, you are part of a high-visibility platform with a global audience. Asign’s Advisory Services help you communicate with our audiences and share your ideas, thoughts and art effectively.</p>",
    },
  ],
  business: [
    {
      title: "Are there any fees for registration on Asign?",

      content:
        "<p class='text-[18px] max-md:text-[14px] text-[#696969] tracking-0 leading-[120%]'> You can get Asign for free. We help you onboard and sell your artwork on the platform. We only charge a commission on the sale of your artwork.</p>",
    },
    {
      title: "Can I as an individual create an account for my business?",

      content:
        "<p class='text-[18px] max-md:text-[14px] text-[#696969]  leading-[120%] tracking-0'>You can create an account on behalf of your business. However, we will need to verify your business details as part of our onboarding process.</p>",
    },
    {
      title: "How do I build my digital catalogue on Asign?",

      content:
        "<div><p class=\"text-[18px] max-md:text-[14px] text-[#696969] max-w-[90%] leading-[120%] tracking-0\">Asign helps you build your Catalogue Raisonné in five simple steps:<br /></p><ol class=\"text-[18px] max-md:text-[14px] pt-[16px]  list-none  text-[#696969]  space-y-[24px] leading-[120%] tracking-0\"><li><span class='text-[#1D1D1D]'>Step 1:</span> Create your Asign account</li><li><span class='text-[#1D1D1D]'>Step 2:</span> Request to add your artwork to the Asign platform</li><li><span class='text-[#1D1D1D]'>Step 3:</span> Schedule an agent visit to your premises or studio</li><li><span class='text-[#1D1D1D]'>Step 4:</span> Our agents label your artwork with Asign’s proprietary technology</li><li><span class='text-[#1D1D1D]'>Step 5:</span> Your artwork’s data will be uploaded to the Asign platform and made visible on your profile</li></ol></div>",
    },
  ],
  collectors: [
    {
      title: "How do I request labels for artworks in my collection?",

      content:
        "<p class='text-[18px] max-md:text-[14px] text-[#696969] tracking-0 leading-[120%]'> After creating an account on Asign, you can request on your dashboard to add our proprietary Asign Protect+ labels to your artwork.</p>",
    },
    {
      title: "How does Asign digitise my collection?",

      content:
        "<p class='text-[18px] max-md:text-[14px] text-[#696969]  leading-[120%] tracking-0'>Once you request to label your artworks with Asign Protect+, our field agents will coordinate a survey of your collection, complete the documentation, affix the label, and add it to your digital catalogue which can be viewed on your Asign account.<br/><br/>After labelling, each artwork is scannable and is given a unique global identification number. </p>",
    },
    {
      title: "What is artwork provenance?",

      content:
        "<p class='text-[18px] max-md:text-[14px] text-[#696969]  leading-[120%] tracking-0'>Provenance involves tracking an artwork’s ownership history from when it was created. All artworks on the Asign marketplace come with detailed provenance. We also provide paid provenance tracking services to help you discover the artwork's creator and when and how it changed owners.</p>",
    },
    {
      title: "How do I know the right price for artworks in my collection?",

      content:
        "<p class='text-[18px] max-md:text-[14px] text-[#696969]  leading-[120%] tracking-0'>We help sellers find the right price for their artwork through Valuation as part of our Advisory Services. We provide data-driven pricing models to help you decide the best price for your artwork.</p>",
    },
  ],
  everyone: [
    {
      title: "Can I register on Asign if I don’t know anything about art?",

      content:
        "<p class='text-[18px] max-md:text-[14px] text-[#696969] tracking-0 leading-[120%]'> Yes. Asign is a digital art ecosystem for all. We provide exclusive services and resources for persons who wish to learn more about the art world and want to begin investing in art.</p>",
    },
    {
      title: "How do I begin buying art?",

      content:
        "<p class='text-[18px] max-md:text-[14px] text-[#696969]  leading-[120%] tracking-0'>Buying art is a personal experience, and it takes time and effort as each collector has different likes and preferences. To effectively facilitate this, we will provide comprehensive resources, which include learning programs, videos, and blogs. <br/><br/> We also bring you a global marketplace to explore different kinds of art, discover artists and galleries, and ultimately find the artwork you like.</p>",
    },
    {
      title: "How do I know if an artwork is authentic?",

      content:
        "<p class='text-[18px] max-md:text-[14px] text-[#696969]  leading-[120%] tracking-0'>At Asign, our team of experts work tirelessly to ensure that only authentic artworks from genuine artists make it onto the platform. We tag all artwork with our proprietary labelling technology called Asign Protect+. This tag ensures that artworks are verified authentic.</p>",
    },
    {
      title: "Can Asign help me buy artwork?",

      content:
        "<p class='text-[18px] max-md:text-[14px] text-[#696969]  leading-[120%] tracking-0'>Yes, we can! By signing up on Asign, you access a global marketplace of artists and galleries where you can find the artwork or collectible you like. We make guided suggestions of artworks based on your preferences and provide different buying modules, including buying at a fixed price, making an offer to the owner of the object, reserving the artwork till you are sure, or bidding at auctions.</p>",
    },
    {
      title: "How are artworks priced?",

      content:
        "<p class='text-[18px] max-md:text-[14px] text-[#696969]  leading-[120%] tracking-0'>All artworks on Asign have a listed price and each seller has their own pricing structure. A variety of factors influence an artwork’s price. It could include the artist’s reputation, the provenance of the work, and the exhibition history, among others.</p>",
    },
    {
      title: "How can I pay for the artwork I want to buy?",

      content:
        "<p class='text-[18px] max-md:text-[14px] text-[#696969]  leading-[120%] tracking-0'>We accept payments up to a certain amount on the Asign platform via a secure payment gateway. Once the payment is confirmed, our team will contact you to facilitate the completion of the transaction.</p>",
    },
    {
      title: "Are the learning resources on Asign available for free?",

      content:
        "<p class='text-[18px] max-md:text-[14px] text-[#696969]  leading-[120%] tracking-0'>Asign has developed interactive learning modules for everyone to learn about art, artists, galleries, collectibles, art services, and more. Details on pricing for these services will be announced soon.</p>",
    },
    {
      title: "Can I resell artwork that I buy on Asign?",

      content:
        "<p class='text-[18px] max-md:text-[14px] text-[#696969]  leading-[120%] tracking-0'>Yes. Once you buy an artwork from Asign, it will get added to your collection. You can request to add the artwork to the marketplace later. Since all your data is on the Asign platform already, buying and selling will be a quick and seamless process.</p>",
    },
  ],
};
