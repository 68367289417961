import Image from "@/components/Global/Image";
import Like from "@/components/Global/intreactions/Like";
import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import { useUserContext } from "@/context/user";
import { findById, getId } from "@/lib/helper";
import { cn } from "@/lib/utils";
import { Artist } from "@/types/atrists.type";
import { format } from "date-fns";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import Slider from "react-slick";

interface Props {
  data: Artist[];
  title: string;
  sectionId?: string;
}
const CustomDot = () => (
  <div className="w-[8px] h-[8px] rounded-full bg-gray-80"></div>
);

const BigBanner = ({ data, title, sectionId }: Props) => {
  const sliderRef = useRef<Slider | null>(null);

  const next = () => {
    sliderRef.current?.slickNext();
  };

  const previous = () => {
    sliderRef.current?.slickPrev();
  };
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [containerWidth, setContainerWidth] = useState(0);
  const navigate = useNavigate();
  let dragging = false;
  const settings = {
    dots: true,
    customPaging: CustomDot,
    dotsClass: "slick-dots dots-inside-the-slider !bottom-[20px]",
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    beforeChange: () => (dragging = true),
    afterChange: () => (dragging = false),
    fade: true,
    cssEase: "linear",
  };

  useEffect(() => {
    const container = containerRef.current;
    if (!container) return;

    const updateWidth = () => setContainerWidth(container.offsetWidth);
    const resizeObserver = new ResizeObserver(updateWidth);
    resizeObserver.observe(container);
    updateWidth();

    return () => resizeObserver.disconnect();
  }, []);

  const isSmallWidth = containerWidth < 450;

  const renderSlide = (item: Artist | number, index: number) => {
    const isPlaceholder = typeof item === "number";
    const artist = isPlaceholder ? null : item;

    return (
      <div className="relative" key={isPlaceholder ? index : artist?._id}>
        <div
          className={cn(
            "relative overflow-hidden",
            isSmallWidth ? "aspect-[9/12]" : "aspect-video",
            isPlaceholder || !artist?.profilePic
              ? "bg-gradient-to-b from-gray-200 to-black"
              : "",
          )}
        >
          {!isPlaceholder && artist?.profilePic && (
            <>
              <Image
                src={artist?.image ?? artist.profilePic}
                alt="profile pic"
                className="object-cover w-full h-full "
                loading="lazy"
              />
              <div className="absolute top-0 left-0 w-full h-full bg-gradient-to-t from-black to-75%"></div>
            </>
          )}
        </div>

        {!isSmallWidth && (
          <>
            <Badge className="absolute capitalize top-[22px] left-5 lg:p-[12px_16px]">
              {artist?.tags}
            </Badge>

            {[false, true].map((flip, index) => (
              <img
                key={index}
                className={cn(
                  "p-[3px] mx-3 bg-[#EEEEEA] rounded-[100%] h-[29px] w-[29px] absolute top-1/2  cursor-pointer group-hover:visible invisible z-10",
                  flip && " left-[48px] rotate-180 ",
                  !flip && "right-[48px] ",
                )}
                src="/arrow-right.svg"
                alt={flip ? "Previous" : "Next"}
                onClick={flip ? previous : next}
              />
            ))}
            <div className="absolute w-full text-white top-1/2 -translate-y-1/2 flex flex-col items-center justify-center gap-[10px]">
              <h1
                className="text-[48px] font-thin tracking-wide leading-[57.6px] capitalize cursor-pointer font-eb"
                onClick={() => {
                  if (dragging) return;
                  navigate(`/artist/detail/${artist?._id}`);
                }}
              >
                {isPlaceholder ? "Untitled" : artist?.name}
              </h1>
              <p className="text-sm font-sh5">
                {artist?.description ?? "Abstract artist based out of New York"}
              </p>
              <FollowArtistButton
                id={getId(artist)}
                className="p-[10px_20px] mt-[10px]"
              />
            </div>
            <Button
              className="mt-1 bg-white rounded-[33px] absolute bottom-10 left-1/2 -translate-x-1/2 w-full max-w-[208px] font-sh5 font-medium p-[9px_24px] h-[42px]"
              variant="outline"
              onClick={() => navigate(`/artist/view/${sectionId}`)}
            >
              VIEW ALL ARTISTS
            </Button>
          </>
        )}

        {isSmallWidth && (
          <div className="absolute left-0 flex items-center justify-between w-full px-5 text-white bottom-12">
            <div>
              <h1 className="text-xl font-normal tracking-wide font-eb leading-[120%]">
                {isPlaceholder ? "name" : artist?.name}
              </h1>
              <p className="text-sm font-sh5 line-clamp-2 max-w-[150px] ">
                {artist?.description}
              </p>
            </div>

            <FollowArtistButton
              id={getId(artist)}
              className="text-white border-white rounded-[33px] px-5 py-[10px]"
            />
          </div>
        )}
      </div>
    );
  };

  return (
    <div ref={containerRef}>
      <Slider {...settings} ref={sliderRef} className="group">
        {data?.length <= 1
          ? [1, 2, 3, 4, 5].map((item, index) => renderSlide(item, index))
          : data?.map((item, index) => renderSlide(item, index))}
      </Slider>
      {isSmallWidth && (
        <div className="flex justify-center">
          <Button
            className="w-full mq450:w-[90%] mq450:m-auto mq450:mt-2 mt-1 rounded-[33px] bg-other-cta uppercase font-medium font-sh5 p-[9px_24px] text-base"
            onClick={() => navigate(`/artist/view/${sectionId}`)}
          >
            View All Artists
          </Button>
        </div>
      )}
    </div>
  );
};

export default BigBanner;

export const FollowArtistButton = ({
  id,
  className,
}: {
  id: string;
  className?: string;
}) => {
  const { interactions } = useUserContext();
  return (
    <Like
      itemId={id ?? " "}
      action={
        findById(interactions?.artistsFollowed, id ?? "") ? "remove" : "add"
      }
      itemType="artist"
    >
      <Button
        variant="outline"
        size="sm"
        className={cn(
          "text-white border-white rounded-[33px] h-[37px] -translate-y-[3px] p-[8px_20px]",
          className,
        )}
      >
        {findById(interactions?.artistsFollowed, id ?? "")
          ? "Following"
          : "Follow"}
      </Button>
    </Like>
  );
};
