import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useRef,
  useMemo,
  useCallback,
} from "react";
import Keycloak from "keycloak-js";
import Cookies from "universal-cookie";
import toast from "react-hot-toast";
import { jwtDecode } from "jwt-decode";
import useUserAPI from "@/apis/user";
import { useUserContext } from "@/context/user";

const KEYCLOAK_CONFIG = {
  url: "https://lv.up.railway.app",
  realm: "asign",
  clientId: "asign_client",
};

const BASE_COOKIE_OPTIONS = {
  path: "/",
  secure: true,
  sameSite: "strict" as const,
  domain: window.location.hostname,
};

const cookies = new Cookies();

interface AuthState {
  isLogin: boolean;
  token: string | null;
  error: string | null;
  isLoading: boolean;
  email_verified: boolean;
}

interface TokenPayload {
  exp: number;
  userType: string;
  email_verified: boolean;
}

interface AuthContextType extends AuthState {
  login: (token: string) => Promise<boolean>;
  logout: () => Promise<void>;
  keycloak: Keycloak.KeycloakInstance;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

const AuthProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { refreshToken } = useUserAPI();
  const refreshTimerRef = useRef<NodeJS.Timeout | null>(null);
  const keycloak = useMemo(() => new Keycloak(KEYCLOAK_CONFIG), []);
  const COOKIE_OPTIONS = useMemo(() => ({ ...BASE_COOKIE_OPTIONS }), []);

  const [authState, setAuthState] = useState<AuthState>({
    isLogin: false,
    token: null,
    error: null,
    isLoading: true,
    email_verified: false,
  });

  const setAuthStateCallback = useCallback(
    (updater: (prev: AuthState) => AuthState) => {
      setAuthState((prev) => {
        const newState = updater(prev);
        return JSON.stringify(prev) === JSON.stringify(newState)
          ? prev
          : newState;
      });
    },
    [],
  );
  const clearAllCookies = useCallback(() => {
    // Simplify cookie clearing logic
    const allCookies = cookies.getAll();
    Object.keys(allCookies).forEach((cookieName) => {
      // Remove with all possible domain combinations
      cookies.remove(cookieName, { ...COOKIE_OPTIONS });
      cookies.remove(cookieName, {
        ...COOKIE_OPTIONS,
        domain: window.location.hostname,
      });
      cookies.remove(cookieName, {
        ...COOKIE_OPTIONS,
        domain: `.${window.location.hostname}`,
      });

      // Also remove using native JavaScript for complete cleanup
      document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/; domain=${window.location.hostname};`;
      document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/;`;
    });
  }, [COOKIE_OPTIONS]);

  const setCookie = useCallback(
    (name: string, value: string) => {
      // Remove only the specific cookie being set, not all cookies
      cookies.remove(name, { ...COOKIE_OPTIONS });
      cookies.remove(name, {
        ...COOKIE_OPTIONS,
        domain: window.location.hostname,
      });
      cookies.remove(name, {
        ...COOKIE_OPTIONS,
        domain: `.${window.location.hostname}`,
      });

      // Set the new cookie
      cookies.set(name, value, {
        ...COOKIE_OPTIONS,
        expires: new Date(Date.now() + 24 * 60 * 60 * 1000), // 1 day
      });
    },
    [COOKIE_OPTIONS],
  );

  const checkTokenValidity = useCallback((token: string) => {
    try {
      const decoded = jwtDecode<TokenPayload>(token);
      const currentTime = Math.floor(Date.now() / 1000);
      const timeUntilExpiry = decoded.exp - currentTime;
      return { isValid: timeUntilExpiry > 0, timeUntilExpiry };
    } catch (error) {
      console.error("Error decoding token:", error);
      return { isValid: false, timeUntilExpiry: 0 };
    }
  }, []);

  const checkUserAccess = useCallback((token: string) => {
    try {
      const decoded = jwtDecode<TokenPayload>(token);
      const userType = Number(decoded.userType);
      return {
        isValidUser: userType !== 3,
        message: userType === 3 ? "You are not authorized" : "Welcome",
        email_verified: decoded?.email_verified,
      };
    } catch (error) {
      console.error("Error checking user access:", error);
      return {
        isValidUser: false,
        message: "Invalid token or error decoding the token",
      };
    }
  }, []);

  const startTokenRefresh = useCallback(
    (token: string) => {
      if (refreshTimerRef.current) {
        clearInterval(refreshTimerRef.current);
      }

      const { timeUntilExpiry } = checkTokenValidity(token);
      //refresh token in 10 minute before expiry
      const refreshInterval = Math.min(timeUntilExpiry * 1000 - 600000, 30000);

      refreshTimerRef.current = setInterval(async () => {
        try {
          const refreshed = await refreshToken();
          if (refreshed) {
            const { isValidUser, email_verified } = checkUserAccess(
              refreshed.access_token,
            );
            if (isValidUser) {
              setCookie("token", refreshed.access_token);
              if (refreshed.refresh_token) {
                setCookie("refreshToken", refreshed.refresh_token);
              }
              setAuthStateCallback((prev) => ({
                ...prev,
                token: refreshed.access_token,
                error: null,
                email_verified: email_verified || prev.email_verified,
              }));
            } else {
              throw new Error("User no longer has access");
            }
          }
        } catch (error) {
          console.error("Failed to refresh token:", error);
          toast.error("Session expired. Please login again.");
          await logout();
        }
      }, refreshInterval);
    },
    [checkTokenValidity, checkUserAccess, refreshToken, setCookie],
  );

  const logout = useCallback(async () => {
    // const { updateUser } = useUserContext();
    try {
      if (refreshTimerRef.current) {
        clearInterval(refreshTimerRef.current);
      }
      toast.success("Logged out successfully");
      // updateUser.mutate({
      //   isLoggedIn: false,
      // });
      setTimeout(() => {
        {
          clearAllCookies();
          setAuthStateCallback((prev) => ({
            ...prev,
            isLogin: false,
            token: null,
            error: null,
            isLoading: false,
          }));
          window.location.reload();
        }
      }, 1000);
    } catch (error) {
      console.error("Logout failed:", error);
      toast.error("Logout failed");
    }
  }, [clearAllCookies, setAuthStateCallback]);

  const login = useCallback(
    async (token: string): Promise<boolean> => {
      try {
        const { isValid } = checkTokenValidity(token);
        if (!isValid) throw new Error("Token is expired or invalid");

        const { isValidUser, message } = checkUserAccess(token);
        if (!isValidUser) throw new Error(message);

        keycloak.token = token;
        setAuthStateCallback((prev) => ({
          ...prev,
          isLogin: true,
          token: token,
          error: null,
          isLoading: false,
        }));
        startTokenRefresh(token);
        return true;
      } catch (error) {
        console.error("Login failed:", error);
        toast.error(
          error instanceof Error
            ? error.message
            : "Login failed. Please try again.",
        );
        setAuthStateCallback((prev) => ({
          ...prev,
          isLogin: false,
          token: null,
          error: "Login failed",
          isLoading: false,
        }));
        return false;
      }
    },
    [
      checkTokenValidity,
      checkUserAccess,
      startTokenRefresh,
      keycloak,
      setAuthStateCallback,
    ],
  );

  // New function to handle token refresh
  const handleTokenRefresh = useCallback(async () => {
    try {
      const refreshed = await refreshToken();
      if (refreshed?.access_token) {
        // Check specifically for access_token
        const { isValid } = checkTokenValidity(refreshed.access_token);
        if (!isValid) {
          throw new Error("Refreshed token is invalid");
        }

        const { isValidUser, email_verified } = checkUserAccess(
          refreshed.access_token,
        );
        if (isValidUser) {
          // Clear old tokens first
          clearAllCookies();

          // Set new tokens
          setCookie("token", refreshed.access_token);
          if (refreshed.refresh_token) {
            setCookie("refreshToken", refreshed.refresh_token);
          }

          // Update keycloak instance
          keycloak.token = refreshed.access_token;
          if (refreshed.refresh_token) {
            keycloak.refreshToken = refreshed.refresh_token;
          }

          setAuthStateCallback((prev) => ({
            ...prev,
            isLogin: true,
            token: refreshed.access_token,
            error: null,
            isLoading: false,
            email_verified: email_verified || prev.email_verified,
          }));

          startTokenRefresh(refreshed.access_token);
          return true;
        }
      }
      throw new Error("Invalid refresh response");
    } catch (error) {
      console.error("Token refresh failed:", error);
      // Clear everything on refresh failure
      clearAllCookies();
      setAuthStateCallback((prev) => ({
        ...prev,
        isLogin: false,
        token: null,
        error: "Session expired",
        isLoading: false,
      }));
      return false;
    }
  }, [
    refreshToken,
    checkTokenValidity,
    checkUserAccess,
    clearAllCookies,
    setCookie,
    keycloak,
    setAuthStateCallback,
    startTokenRefresh,
  ]);

  useEffect(() => {
    const initializeAuth = async () => {
      const existingToken = cookies.get("token");

      // Always try to refresh the token first on page load
      const refreshSuccess = await handleTokenRefresh();
      if (refreshSuccess) return;

      // If refresh fails, fall back to existing token validation
      if (!existingToken) {
        setAuthStateCallback((prev) => ({ ...prev, isLoading: false }));
        return;
      }

      const { isValid } = checkTokenValidity(existingToken);
      if (!isValid) {
        clearAllCookies();
        setAuthStateCallback((prev) => ({ ...prev, isLoading: false }));
        return;
      }

      try {
        const { isValidUser, email_verified } = checkUserAccess(existingToken);
        if (isValidUser) {
          keycloak.token = existingToken;
          setAuthStateCallback((prev) => ({
            ...prev,
            isLogin: true,
            token: existingToken,
            error: null,
            isLoading: false,
            email_verified: email_verified || false,
          }));
          startTokenRefresh(existingToken);
        } else {
          clearAllCookies();
          setAuthStateCallback((prev) => ({ ...prev, isLoading: false }));
        }
      } catch (error) {
        clearAllCookies();
        setAuthStateCallback((prev) => ({ ...prev, isLoading: false }));
      }
    };

    initializeAuth();

    return () => {
      if (refreshTimerRef.current) {
        clearInterval(refreshTimerRef.current);
      }
    };
  }, []);

  const memoizedValue = useMemo(
    () => ({
      ...authState,
      login,
      logout,
      keycloak,
    }),
    [authState, login, logout, keycloak],
  );

  return (
    <AuthContext.Provider value={memoizedValue}>
      {children}
    </AuthContext.Provider>
  );
};

const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuthContext must be used within an AuthProvider");
  }
  return context;
};

export { AuthProvider, useAuth };
