import React, { useState } from "react";
import { Filter, X } from "lucide-react";
import { Button } from "@/components/ui/button";
import {
  Sheet,
  SheetContent,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from "@/components/ui/sheet";
import { Checkbox } from "@/components/ui/checkbox";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Switch } from "@/components/ui/switch";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion";

import { ScrollArea } from "@/components/ui/scroll-area";
import { useMaster } from "@/context/useMaster";
import { CustomSwitch } from "@/pages/add-object/CustomSwitch";
import { Slider } from "@/components/ui/slider";
import { useMediaQuery } from "@mui/material";
import { Drawer, DrawerContent, DrawerTrigger } from "@/components/ui/drawer";

export function ArtFilters() {
  const [sheetOpen, setSheetOpen] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState<
    Record<string, string[]>
  >({});

  console.log(selectedFilters);
  console.log("rerender");

  const isDesktop = useMediaQuery("(min-width: 1000px)");

  const { useSchema } = useMaster();
  const objectTypeSchema = useSchema("objectType");
  const mediumSchema = useSchema("medium");
  const movements = useSchema("movement");
  // const materialSchema = useSchema("material");
  const citySchema = useSchema("City");

  const filterCategories = {
    saleStatus: [
      { label: "Available", count: 145, value: "available" },
      { label: "Sold", count: 89, value: "sold" },
      { label: "On Hold", count: 12, value: "on_hold" },
    ],
    objectType:
      objectTypeSchema?.map((item: any) => ({
        label: item.label,
        count: item.count || 0,
        value: item.value,
      })) || [],
    priceRanges: [
      { label: "Under $1,000", value: "0-1000" },
      { label: "$1,000 - $5,000", value: "1000-5000" },
      { label: "$5,000 - $10,000", value: "5000-10000" },
      { label: "$10,000 - $50,000", value: "10000-50000" },
      { label: "Over $50,000", value: "50000-plus" },
    ],
    medium:
      mediumSchema?.map((item: any) => ({
        label: item.label,
        count: item.count || 0,
        value: item.value,
      })) || [],
    material:
      citySchema?.map((item: any) => ({
        label: item.label,
        count: item.count || 0,
        value: item.value,
      })) || [],
    years: [
      { label: "2020s", count: 85, value: "2020-2029" }, //change this and make this a number value. or else filter won't work
      { label: "2010s", count: 120, value: "2010-2019" },
      { label: "2000s", count: 95, value: "2000-2009" },
      { label: "1990s", count: 45, value: "1990-1999" },
      { label: "Before 1990", count: 30, value: "pre-1990" },
    ],
    locations:
      citySchema?.map((item: any) => ({
        label: item.label,
        count: item.count || 0,
        value: item.value,
      })) || [],
    sizes: [
      { label: "Small (under 40cm)", count: 45, value: "small" },
      { label: "Medium (40-100cm)", count: 85, value: "medium" },
      { label: "Large (over 100cm)", count: 35, value: "large" },
    ],
    contentType: [
      { label: "Abstract", count: 55, value: "abstract" },
      { label: "Landscape", count: 45, value: "landscape" },
      { label: "Portrait", count: 35, value: "portrait" },
      { label: "Still Life", count: 25, value: "still_life" },
      { label: "Conceptual", count: 30, value: "conceptual" },
    ],
    collections: [
      { label: "Modern Masters", count: 45, value: "modern_masters" },
      { label: "Contemporary Voices", count: 65, value: "contemporary_voices" },
      { label: "Emerging Artists", count: 85, value: "emerging_artists" },
    ],
    movements:
      movements?.map((item: any) => ({
        label: item.label,
        count: item.count || 0,
        value: item.value,
      })) || [],
    sortOptions: [
      { label: "Recently added", value: "recent" },
      { label: "Price: Low to High", value: "price_asc" },
      { label: "Price: High to Low", value: "price_desc" },
      { label: "Artist: A-Z", value: "artist_asc" },
    ],
  };

  const handleFilterChange = (
    category: string,
    value: string,
    checked: boolean,
  ) => {
    setSelectedFilters((prev) => {
      const currentFilters = prev[category] || [];
      if (checked) {
        return { ...prev, [category]: [...currentFilters, value] };
      }
      return {
        ...prev,
        [category]: currentFilters.filter((v) => v !== value),
      };
    });
  };

  const SliderFilter = ({
    categoryKey,
    subCat,
  }: {
    categoryKey: string;
    subCat?: string;
  }) => {
    const [rangeValues, setRangeValues] = useState({ min: 0, max: 100 });

    const handleRangeChange = (values: number[]) => {
      setRangeValues({ min: values[0], max: values[1] });
    };

    const handleInputChange = (key: string, value: string) => {
      setRangeValues((prev) => ({ ...prev, [key]: value }));
    };
    return (
      <div className="mt-2 space-y-4">
        <div>
          <Slider
            min={0}
            max={100}
            step={1}
            value={[rangeValues.min, rangeValues.max]}
            onValueChange={handleRangeChange}
            className="mt-4 mb-2 h-[2px]"
            thumbSize={2}
          />
          <div className="flex justify-between">
            <p className="text-[14px] leading-[16.8px]">0</p>
            <p className="text-[14px] leading-[16.8px]">100</p>
          </div>
        </div>
        <div className="flex w-full justify-between">
          <div className="w-[48%]">
            <p className="text-xs text-gray-60">Min</p>
            <input
              type="number"
              value={rangeValues.min}
              onChange={(e) => {
                handleInputChange("min", e.target.value);
              }}
              className="mt-1 max-w-[84px] border-solid border border-gray-20 p-[10px_12px] text-center font-sh5"
            />
          </div>
          <div className="w-[48%] flex flex-col items-end">
            <p className="text-xs text-gray-60">Max</p>
            <input
              type="number"
              value={rangeValues.max}
              onChange={(e) => handleInputChange("max", e.target.value)}
              className="mt-1 max-w-[84px] border-solid border border-gray-20 p-[10px_12px] text-center font-sh5"
            />
          </div>
        </div>
      </div>
    );
  };

  const renderFilterItems = (categoryKey: any, categoryLabel: any) => {
    return (
      <AccordionItem value={categoryKey}>
        <AccordionTrigger className="py-6 border-t border-solid border-bdr-10">
          {categoryLabel}
        </AccordionTrigger>
        <AccordionContent className="pb-6 pt-0">
          {categoryKey === "priceRanges" ? (
            <SliderFilter categoryKey={categoryKey} />
          ) : categoryKey === "sizes" ? (
            <div>
              <div className="flex items-center gap-2 my-2">
                <span>cm</span>
                <CustomSwitch />
                <span>in</span>
              </div>
              <Accordion type="multiple">
                <AccordionItem value="height">
                  <AccordionTrigger className="py-5">Height</AccordionTrigger>
                  <AccordionContent className="pb-6 pt-0">
                    <SliderFilter categoryKey={categoryKey} subCat={"height"} />
                  </AccordionContent>
                </AccordionItem>
                <AccordionItem value="width">
                  <AccordionTrigger className="py-5 border-t border-solid border-bdr-10">
                    Width
                  </AccordionTrigger>
                  <AccordionContent className="pb-1 pt-0">
                    <SliderFilter categoryKey={categoryKey} subCat={"width"} />
                  </AccordionContent>
                </AccordionItem>
              </Accordion>
            </div>
          ) : (
            <div className="space-y-6">
              {(filterCategories as any)[categoryKey].map((item: any) => (
                <label key={item.value} className="flex items-center space-x-3">
                  <Checkbox
                    className="h-5 w-5 m-0 data-[state=checked]:border-[#4F4F4F]"
                    checked={selectedFilters[categoryKey]?.includes(item.value)}
                    onCheckedChange={(checked) =>
                      handleFilterChange(
                        categoryKey,
                        item.value,
                        checked as boolean,
                      )
                    }
                  />
                  <span className="text-base leading-[19.2px] text-gray-60">
                    {item.label} {item.count ? `(${item.count})` : ""}
                  </span>
                </label>
              ))}
            </div>
          )}
        </AccordionContent>
      </AccordionItem>
    );
  };

  const FilterScrollArea = () => {
    return (
      <ScrollArea className="h-[calc(100vh-80px)] pr-4">
        <Accordion type="multiple" className="w-full">
          {Object.entries({
            saleStatus: "Sale Status",
            objectType: "Object Type",
            priceRanges: "Price",
            medium: "Medium",
            material: "Material",
            years: "Year",
            locations: "Object Location",
            sizes: "Size",
            contentType: "Content Type",
            collections: "Collection",
          }).map(([key, label]) => renderFilterItems(key, label))}
        </Accordion>
      </ScrollArea>
    );
  };

  return (
    <div className="w-full">
      {isDesktop ? (
        <div className="flex items-center gap-4 px-0 p-10 bg-background">
          <Sheet open={sheetOpen} onOpenChange={setSheetOpen}>
            <SheetTrigger asChild>
              <Button
                variant="outline"
                className="gap-2 text-gray-60 border border-solid border-[#cfcfcf] bg-[#eeeeea] rounded-full hover:text-gray-60 hover:bg-[#e7e7e1]"
              >
                <Filter className="w-4 h-4 text-gray-60 " />
                All Filters
              </Button>
            </SheetTrigger>
            <SheetContent
              side="left"
              className="w-[300px] sm:w-[360px] px-5 py-10"
            >
              <SheetHeader>
                <div className="flex items-center justify-between mb-8">
                  <h1 className="text-base leading-[19.2px] text-gray-60 font-normal">
                    Filters
                  </h1>
                  <Button
                    variant="ghost"
                    size="icon"
                    className="h-5 w-5"
                    onClick={() => setSheetOpen(false)}
                  >
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M3.3335 3.33594L16.6668 16.669"
                        stroke="#303030"
                        stroke-width="1.5"
                        stroke-linecap="square"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M3.33319 16.669L16.6665 3.33594"
                        stroke="#303030"
                        stroke-width="1.5"
                        stroke-linecap="square"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </Button>
                </div>
              </SheetHeader>
              <FilterScrollArea />
            </SheetContent>
          </Sheet>
          <div className="flex items-center gap-2">
            <span className="text-sm text-gray-100">Objects for sale</span>
            <CustomSwitch />
          </div>
          {["locations", "movements", "sortOptions"].map((key) => (
            <Select key={key}>
              <SelectTrigger className="w-fit px-5 py-3 text-sm leading-[16.8px] gap-1 text-[#696969] rounded-full h-10 capitalize">
                <SelectValue placeholder={key} />
              </SelectTrigger>
              <SelectContent>
                {(filterCategories as any)[key].map((item: any) => (
                  <SelectItem key={item.value} value={item.value}>
                    {item.label}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          ))}
        </div>
      ) : (
        <Drawer open={sheetOpen} onOpenChange={setSheetOpen}>
          <DrawerTrigger asChild>
            <Button
              variant="outline"
              className="gap-2 text-gray-60 border border-solid border-[#e5e5e5] h-[52px] w-[52px] rounded-full hover:bg-[#fff]"
            >
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M4.50065 1.75H15.5007C16.4173 1.75 17.1673 2.5 17.1673 3.41667V5.25C17.1673 5.91667 16.7507 6.75 16.334 7.16667L12.7507 10.3333C12.2507 10.75 11.9173 11.5833 11.9173 12.25V15.8333C11.9173 16.3333 11.584 17 11.1673 17.25L10.0007 18C8.91732 18.6667 7.41732 17.9167 7.41732 16.5833V12.1667C7.41732 11.5833 7.08398 10.8333 6.75065 10.4167L3.58398 7.08333C3.16732 6.66667 2.83398 5.91667 2.83398 5.41667V3.5C2.83398 2.5 3.58398 1.75 4.50065 1.75Z"
                  stroke="#303030"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </Button>
          </DrawerTrigger>
          <DrawerContent className="px-5 py-10 h-screen">
            <div className="flex items-center justify-between mb-8">
              <h1 className="text-base leading-[19.2px] text-gray-60 font-normal">
                Filters
              </h1>
              <Button
                variant="ghost"
                size="icon"
                className="h-5 w-5"
                onClick={() => setSheetOpen(false)}
              >
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M3.3335 3.33594L16.6668 16.669"
                    stroke="#303030"
                    stroke-width="1.5"
                    stroke-linecap="square"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M3.33319 16.669L16.6665 3.33594"
                    stroke="#303030"
                    stroke-width="1.5"
                    stroke-linecap="square"
                    stroke-linejoin="round"
                  />
                </svg>
              </Button>
            </div>
            <FilterScrollArea />
          </DrawerContent>
        </Drawer>
      )}
    </div>
  );
}
