import React, { useState } from "react";
import { faqs } from "../../../constant/faqs";
const Accordion = ({ page }: { page: string }) => {
  const [openIndex, setOpenIndex] = useState(null);

  const handleToggle = (indexData: any) => {
    setOpenIndex(openIndex === indexData ? null : indexData);
  };

  // <p className="text-[18px] text-[#696969] tracking-0 leading-[120%] max-w-[90%]">
  //   Once an artwork is uploaded on Asign, art owners can request to make their
  //   artwork available for sale on the marketplace. Asign then curates the
  //   artwork and may make it visible and discoverable on the marketplace.
  //   Potential buyers will have different modes of buying the artwork, including
  //   buying at a fixed price, making an offer to the art owner, reserving the
  //   artwork till they can be sure, and entering art auctions.
  // </p>;

  return (
    <section className="faqs py-[120px] max-md:py-[60px] max-md:px-[20px] bg-[#f2f2f2] 2xl:container">
      <h2 className="text-[48px] max-md:text-[28px] font-normal leading-[100%] text-center mb-[40px]">
        Knowledge Centre
      </h2>
      <div className="faqs__list max-w-3xl mx-auto">
        {faqs[page].map((item: any, index: number) => (
          <div key={index} className="m-accordion border-b">
            <h3>
              <button
                type="button"
                className="w-full flex justify-between items-center  text-left text-[24px] max-md:text-[18px]  text-[#1D1D1D] leading-[100%] border-t max-md:py-[28px] py-[40px] border-[#CFCFCF] tracking-0 font-normal focus:outline-none"
                onClick={() => handleToggle(index)}
                aria-expanded={openIndex === index}
                aria-controls={`panel-${index}`}
              >
                {item.title}
                <span className="m-accordionIcon">
                  {openIndex === index ? (
                    <svg
                      className="w-6 h-6"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M18 12H6"
                      ></path>
                    </svg>
                  ) : (
                    <svg
                      className="w-6 h-6"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M12 4v16m8-8H4"
                      ></path>
                    </svg>
                  )}
                </span>
              </button>
            </h3>
            <div
              id={`panel-${index}`}
              className={`m-accordion__panel ${
                openIndex === index ? "block" : "hidden"
              }  bg-transparent mb-[40px]`}
              role="region"
              aria-labelledby={`panel-${index}`}
            >
              <div
                className="text-[18px] text-[#696969] tracking-0 leading-[120%] max-w-[90%]"
                dangerouslySetInnerHTML={{ __html: item.content }}
              />
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Accordion;
