import useUserAPI, { like } from "@/apis/user";
import { Button } from "@/components/ui/button";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import React from "react";
import toast from "react-hot-toast";

interface props extends like {
  children: React.ReactNode;
  itemId: string;
  itemType: "artist" | "object" | "event" | "business";
  action: "add" | "remove";
}

const successToastMessage = (
  itemType: "artist" | "object" | "event" | "business",
  action: "add" | "remove",
) => {
  const messages = {
    artist: {
      add: "Followed successfully",
      remove: "Unfollowed successfully",
      loading: "Following...",
    },
    business: {
      add: "Followed successfully",
      remove: "Unfollowed successfully",
    },
    object: {
      add: "Liked successfully",
      remove: "Unliked successfully",
      loading: "Liking...",
    },
    event: {
      add: "Liked successfully",
      remove: "Unliked successfully",
    },
  };

  return messages[itemType][action];
};

const Like = React.memo((props: props) => {
  const { addOrRemoveLike } = useUserAPI();
  const [isDebounced, setIsDebounced] = React.useState(false);
  const queryclient = useQueryClient();
  const handler = useMutation({
    mutationFn: addOrRemoveLike,
    mutationKey: ["like"],
    onSuccess: () => {
      toast.dismiss();
      queryclient.invalidateQueries({ queryKey: ["user-interactions"] });
      toast.success(successToastMessage(props.itemType, props.action));
      setIsDebounced(true);
      setTimeout(() => setIsDebounced(false), 1000);
    },
    onError: (error: any) => {
      toast.dismiss();
      toast.error(error.message);
    },
    onMutate: () => {
      toast.loading("Loading...");
    },
  });

  const buttonClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    handler.mutate({
      itemId: props.itemId,
      itemType: props.itemType,
      action: props.action,
    });
  };

  return (
    <button
      onClick={buttonClick}
      disabled={handler.isPending || isDebounced}
      type="button"
    >
      {props.children}
    </button>
  );
});

export default Like;
