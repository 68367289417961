import React, { FC, useEffect, useState } from "react";
import Loader from "./Global/Loader";

export interface LazyLoaderProps {
  delay?: number;
}

const LazyLoader: FC<LazyLoaderProps> = React.memo(
  ({ delay = 250, ...props }) => {
    const [show, setShow] = useState(false);

    useEffect(() => {
      const timeout = setTimeout(() => {
        setShow(true);
      }, delay);
      return () => {
        clearTimeout(timeout);
      };
    }, [delay]);

    return show ? <Loader /> : null;
  },
);

export { LazyLoader as default };
