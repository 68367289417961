import React, { useEffect, useState } from "react";
import { Dialog, DialogContent } from "@/components/ui/dialog";
import { useLongPress } from "use-long-press";
import Image from "@/components/Global/Image";
import { LikeObject } from "@/components/Templates/Objects/OBig";
import { Button } from "@/components/ui/button";
import { ArtObject } from "@/apis/objects";
import { formatDimensions, formatInRuppes, getId } from "@/lib/helper";
import { useNavigate } from "react-router-dom";
import AddItemDropDown from "@/components/Boards/AddItemDropDown";
import ShareModal from "@/components/ShareModal";
import {
  ObjectLike,
  OverLaly,
  PlayVideo,
} from "@/components/Preview/DiscoverPreview";
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import { format } from "date-fns";
import { FollowArtistButton } from "@/components/Templates/Artists/BigBanner";
import { FollowBussiness } from "@/components/Templates/Business/BBig";
import ReactPlayer from "react-player";

const LongPressPreivew = ({
  children,
  data,
  type,
}: {
  children: React.ReactNode;
  data: ArtObject;
  type: string;
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    // Check if the screen is mobile size
    const updateScreenSize = () => {
      setIsMobile(window.innerWidth <= 1024);
    };

    // Set initial value
    updateScreenSize();

    // Listen to window resize events
    window.addEventListener("resize", updateScreenSize);

    return () => window.removeEventListener("resize", updateScreenSize);
  }, []);

  const bind = useLongPress(
    () => {
      if (!isMobile) return; // Disable long press for desktop/laptop
      setIsOpen(true);
    },
    {
      threshold: 500, // Adjust the duration as needed (in milliseconds)
    }
  );

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <div {...bind()} className="w-full h-full">
        {children}
      </div>
      <DialogContent className="text-white bg-transparent w-full max-w-[1200px] h-screen overflow-y-scroll">
        <div className="flex flex-col items-center mt-[24px] w-full h-full px-12 font-normal font-sh5">
          <img
            src="/cross.svg"
            alt=""
            className="absolute cursor-pointer top-4 right-5"
            onClick={() => setIsOpen(false)}
          />
          <div className="flex items-center justify-between gap-12 mq825:flex-col mq825:gap-0">
            {type == "video" && <VideoPreview data={data} />}
            {type == "objects" && <ObjectPreview data={data} />}
            {type == "artist" && <ArtistPreview data={data} />}
            {type == "business" && <BusinessPreview data={data} />}
            {type == "events" && <EventPreview data={data} />}
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default LongPressPreivew;

const PressFooter = ({ id, type }: { id: string; type: string }) => {
  const navigate = useNavigate();
  // console.log(id);
  return (
    <div className="flex justify-between mt-12">
      <div className="flex gap-3">
        <div className="w-[44px] h-[44px] rounded-full cursor-pointer bg-bg-5 flex items-center justify-center">
          <AddItemDropDown itemID={id} boardID={""} itemType={type}>
            <img src="/save.svg" alt="" className="w-[18px] h-[26px]" />
          </AddItemDropDown>
        </div>
        <div className="w-[44px] h-[44px] rounded-full cursor-pointer bg-bg-5 flex items-center justify-center">
          <ShareModal url={`/objects/detail/${id}`}>
            <img src="/share.svg" alt="" className="w-[30px] h-[21px]" />
          </ShareModal>
        </div>
      </div>
      <Button
        className="text-gray-900 bg-white border-solid rounded-full hover:text-white hover:border-white"
        onClick={() => navigate(`/${type}/detail/${id}`)}
      >
        Know more
      </Button>
    </div>
  );
};

const ObjectPreview = ({ data }: { data: any }) => {
  // console.log(data);
  return (
    <div>
      {data.assignProtect && (
        <p className="flex items-center justify-center h-10 mb-5 text-sm truncate md:hidden">
          <img src="/icon-protect-1.svg" alt="asign protect" /> Asign Protect+
        </p>
      )}

      <Image
        src={data.image}
        alt={data.objectName}
        className="max-h-[400px] w-full h-full object-cover md:min-w-[360px] "
      />
      <div className="w-full mt-5 font-normal text-left font-sh5 pb-5">
        <div className="flex items-center justify-between w-full gap-20">
          <div>
            <p className="text-5xl capitalize mq750:text-base">
              {data.objectName}
            </p>
            <p className="text-[#CCCCCC]  mt-1 text-lg mq750:text-sm">
              {data?.artistName}
            </p>
            <p className="text-[#CCCCCC] text-lg mq750:text-sm">2201</p>
          </div>
          <div className="">
            <ObjectLike
              id={getId(data)}
              className="bg-transparent border border-white border-solid"
            />
          </div>
        </div>
        <div className="text-xs text-[#CCCCCC]">
          <p>{data?.metaData?.medium}</p>
          <p>{formatDimensions(data)}</p>
          <p className="mt-2 text-lg text-white mq750:text-sm">
            {" "}
            {formatInRuppes.format(data?.price ?? (2000 as number))}
          </p>
        </div>
        <PressFooter id={getId(data)} type={"object"} />
      </div>
    </div>
  );
};

const ArtistPreview = ({ data }: { data: any }) => {
  return (
    <>
      <Image
        src={data.profilePic}
        alt={data.name}
        className="max-h-[400px] w-full h-full object-cover md:min-w-[360px]"
      />
      <div>
        <div className="flex justify-between w-full mt-5 font-normal text-left font-sh5 ">
          <div className="flex items-center gap-[10px]">
            <div>
              <h1 className="mq750:text-[15px] font-thin font-eb tracking-wide truncate capitalize text-5xl">
                {data?.name}
              </h1>
              <p className="text-base mq750:text-xs font-sh5 text-[#CCCCCC]">
                b. {format(data?.dob ?? new Date(), "yyyy")}
              </p>
            </div>
          </div>
          <FollowArtistButton id={getId(data)} />
        </div>
        <PressFooter id={getId(data)} type={"artist"} />
      </div>
    </>
  );
};

const BusinessPreview = ({ data }: { data: any }) => {
  return (
    <>
      <Image
        src={data.image}
        alt={data.businessName}
        className="max-h-[400px] w-full h-full object-cover"
      />
      <div className="flex justify-between w-full mt-5 font-normal text-left font-sh5">
        <div className="flex items-center gap-[10px]">
          <Avatar className="cursor-pointer">
            <AvatarImage src={data?.image} alt="" />
            <AvatarFallback>{data?.businessName}</AvatarFallback>
          </Avatar>
          <div>
            <h1 className="mq750:text-[15px] font-thin font-eb tracking-wide truncate capitalize text-5xl">
              {data?.businessName}
            </h1>
            <p className="mq750:text-xs font-sh5 text-[#CCCCCC] capitalize text-base">
              {data?.businessLocation}
            </p>
          </div>
        </div>
        <FollowBussiness id={data?._id} />
        <PressFooter id={data?._id} type={"business"} />
      </div>
    </>
  );
};

const EventPreview = ({ data }: { data: any }) => {
  return (
    <>
      <Image
        src={data.image}
        alt={data.eventName}
        className="max-h-[400px] w-full h-full object-cover max-w-[500px]"
      />
      <div>
        <div className="flex justify-between w-full mt-5 font-normal text-left font-sh5">
          <div className="">
            <p className="text-5xl capitalize mq750:text-sm">
              {data?.eventName}
            </p>
            <p className="mq750:text-xs text-[#CCCCCC] capitalize mt-1 text-lg">
              {(data?.country, data?.state)}
            </p>
            <p className="mq750:text-xs text-[#CCCCCC] mt-1 text-base">
              Sep 14, 2023 - Oct 21, 2023{" "}
            </p>
            <p className="mq750:text-xs text-[#CCCCCC] mt-1 text-base">
              10:00 AM - 05:00 PM{" "}
            </p>
          </div>
          <Button
            variant="outline"
            className="text-white border-white rounded-[33px]"
          >
            ATTEND
          </Button>
        </div>
        <PressFooter id={getId(data)} type={"events"} />
      </div>
    </>
  );
};

const VideoPreview = ({ data }: { data: any }) => {
  const [isPlaying, setIsPlaying] = useState(false);

  const handleThumbnailClick = () => {
    setIsPlaying(true);
  };

  return (
    <div className="relative w-full h-full">
      {!isPlaying ? (
        <div
          className="relative w-full h-full cursor-pointer"
          onClick={handleThumbnailClick}
        >
          <Image
            src={data.content?.coverImage}
            alt={data.content?.title}
            className="object-cover object-center w-full h-full"
          />
          <div className="absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] bg-white rounded-full w-[50px] h-[50px] inline-flex items-center justify-center">
            <img src="/play.svg" alt="" />
          </div>
        </div>
      ) : (
        <div className="relative w-full h-full">
          <ReactPlayer
            url={data.content?.url}
            width="100%"
            height="100%"
            playing={true}
            muted={false}
            config={{
              file: {
                attributes: {
                  controlsList: "nodownload",
                  disablePictureInPicture: true,
                },
              },
            }}
            controls={true}
          />
        </div>
      )}
      <PressFooter id={getId(data)} type={"video"} />
    </div>
  );
};
