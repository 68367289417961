import React from "react";
import Header from "./Components/Header";
import Footer from "./Components/Footer";

export default function TermsAndConditions() {
  return (
    <div className="font-sh5">
      <Header />
      <div className="w-full pt-[203px] mb-20 mx-auto 2xl:container lg:max-w-[776px] max-md:px-[20px]">
        {/* Top Section */}
        <div className="w-full">
          <div>
            <p className="text-[48px] max-md:text-[28px] text-[#1D1D1D]">
              TERMS AND CONDITIONS
            </p>
            <div className="flex gap-2">
              <img src="/clock.svg" alt="clock" />
              <p className="text-sm text-[#1D1D1D] opacity-1 tracking-[0.15008px] text-opacity-[60%]">
                Last Updated: May 28, 2024
              </p>
            </div>
          </div>

          <p className="text-[24px] max-md:text-[18px] pt-[40px]">
            Introduction and basics
          </p>
          <div className="mt-[20px] pb-[40px] text-[#1d1d1d] tracking-[0.15008px] text-opacity-[60%] border-b-[1px] border-solid border-[#cccccc]">
            <p className="mt-[20px] text-[18px] max-md:text-[14px]">
              Welcome! We're Democrart Technologies Private Limited, also known
              as "ASIGN." We're thrilled to offer our services, and we want you
              to know the terms and conditions that come with them. So, let's
              get started!
            </p>

            <p className="mt-[20px] text-[18px] max-md:text-[14px]">
              In These "Terms and Conditions of Service" when we mention "user,"
              or “you” we mean anyone who uses our website or mobile application
              (including you), which we call the "Platform." The Terms and
              Conditions of Service mentioned here are all about using this
              Platform. They cover everything from the Services offered on or
              off the Platform, using those Services, and making payments for
              transactions on or off the Platform. These Terms and Conditions of
              Service is an electronic agreement recognised under law. They're
              formed following the Information Technology Act, of 2000, and the
              related rules. These rules have been updated over time to include
              electronic documents/records. But don't worry, you don't need any
              physical, electronic, or digital signature for these Terms.
            </p>

            <p className="mt-[20px] text-[18px] max-md:text-[14px]">
              This Platform is either owned by or licensed to ASIGN. And just so
              you know, copying it in any way is not allowed.
            </p>

            <p className="mt-[20px] text-[18px] max-md:text-[14px]">
              By using our Services or by simply accessing our Platform, you're
              agreeing to these terms and conditions. It's important to read
              them carefully and understand what you're agreeing to. These terms
              also provide you with access to our Privacy Policy, which explains
              how we handle your personal information. You acknowledge that
              you've read and understood our Privacy Policy as well.
            </p>
          </div>

          <p className="text-[24px] max-md:text-[18px] pt-[40px]">
            Who can use our platform?
          </p>
          <div className="mt-[20px] pb-[40px] text-[#1d1d1d] tracking-[0.15008px] text-opacity-[60%] border-b-[1px] border-solid border-[#cccccc]">
            <p className="mt-[20px] text-[18px] max-md:text-[14px]">
              To use ASIGN, you need to be able to enter into a legal contract
              according to the Indian Contract Act, of 1872. If you're under 18,
              it's a good idea to involve your parents or legal guardians. We
              may conduct routine due diligence to verify your eligibility to
              access the Platform in terms of the Indian Contract Act, of 1872,
              and under such due diligence, we may restrict access to the
              Platform and Services.
            </p>
          </div>

          <p className="text-[24px] max-md:text-[18px] pt-[40px]">
            Contact consent
          </p>
          <div className="mt-[20px] pb-[40px] text-[#1d1d1d] tracking-[0.15008px] text-opacity-[60%] border-b-[1px] border-solid border-[#cccccc]">
            <p className="mt-[20px] text-[18px] max-md:text-[14px]">
              When you sign up with ASIGN, you're agreeing to be contacted by
              ASIGN, its employees, and partners using various communication
              methods. These methods include phone calls, emails, SMS, or any
              other form of electronic communication. ASIGN may use these
              channels to connect with you regarding matters related to the
              information you provided during your registration on the ASIGN
              Platform.
            </p>
          </div>

          <p className="text-[24px] max-md:text-[18px] pt-[40px]">Overriding</p>
          <div className="mt-[20px] pb-[40px] text-[#1d1d1d] tracking-[0.15008px] text-opacity-[60%] border-b-[1px] border-solid border-[#cccccc]">
            <p className="mt-[20px] text-[18px] max-md:text-[14px]">
              DNC/NDNC Registration Your consent to be contacted by ASIGN
              through these communication channels overrides any previous
              registration you might have done with the Do Not Call (DNC) or
              National Do Not Call (NDNC) lists. This means that even if you had
              registered your number to avoid marketing calls, you're allowing
              ASIGN to contact you for the purposes mentioned.
            </p>
          </div>

          <p className="text-[24px] max-md:text-[18px] pt-[40px]">
            Responsibility for deregistration
          </p>
          <div className="mt-[20px] pb-[40px] text-[#1d1d1d] tracking-[0.15008px] text-opacity-[60%] border-b-[1px] border-solid border-[#cccccc]">
            <p className="mt-[20px] text-[18px] max-md:text-[14px]">
              ASIGN makes it clear that if your mobile number is on the DND list
              and you wish to receive SMS messages from them, it's your
              responsibility to take the necessary steps to deregister your
              number from the DND list. This means that you may need to follow
              the procedures provided by your mobile service provider or TRAI to
              allow ASIGN to send you SMS messages.
            </p>
          </div>

          <p className="text-[24px] max-md:text-[18px] pt-[40px]">
            Non-liability for non-receipt of SMS
          </p>
          <div className="mt-[20px] pb-[40px] text-[#1d1d1d] tracking-[0.15008px] text-opacity-[60%] border-b-[1px] border-solid border-[#cccccc]">
            <p className="mt-[20px] text-[18px] max-md:text-[14px]">
              If you don't receive SMS messages from ASIGN due to your number
              being on the DND list, ASIGN cannot be held liable for this. In
              other words, ASIGN won't be responsible for any issues arising
              from your non-receipt of SMS messages if your number is on the DND
              list.
            </p>
          </div>

          <p className="text-[24px] max-md:text-[18px] pt-[40px]">
            Opting out of communications
          </p>
          <div className="mt-[20px] pb-[40px] text-[#1d1d1d] tracking-[0.15008px] text-opacity-[60%] border-b-[1px] border-solid border-[#cccccc]">
            <p className="mt-[20px] text-[18px] max-md:text-[14px]">
              Know that you have the option to stop receiving any or all
              communications from ASIGN. If you decide you no longer want to
              receive communications from ASIGN, you can do so by reaching out
              to the provided email address in the notice clause below. This
              means that you retain control over your communication preferences
              and can choose to opt-out if desired.
            </p>
          </div>

          <p className="text-[24px] max-md:text-[18px] pt-[40px]">
            Account creation and security
          </p>
          <div className="mt-[20px] pb-[40px] text-[#1d1d1d] tracking-[0.15008px] text-opacity-[60%] border-b-[1px] border-solid border-[#cccccc]">
            <p className="mt-[20px] text-[18px] max-md:text-[14px]">
              To use our Services, you need to create an account. When you do,
              you'll need to provide certain details like your name, email
              address, and mobile number. You will also be required to share
              documents like an Aadhar Card, driver's licence, PAN Card, or
              company registration details, you might need to share those for
              verification purposes. The said documents may be stored by us
              purely for verification and we undertake best efforts to secure
              the same.
            </p>
            <p className="mt-[20px] text-[18px] max-md:text-[14px]">
              Remember, it's your responsibility to keep your account secure.
              Don't share your password with anyone else, and if you suspect any
              unauthorised use of your account, let us know immediately.
            </p>
          </div>

          <p className="text-[24px] max-md:text-[18px] pt-[40px]">
            Services offered
          </p>
          <div className="mt-[20px] pb-[40px] text-[#1d1d1d] tracking-[0.15008px] text-opacity-[60%] border-b-[1px] border-solid border-[#cccccc]">
            <p className="mt-[20px] text-[18px] max-md:text-[14px]">
              ASIGN offers a variety of Services through its Platform. These
              Services might change or get updated from time to time. It's
              always a good idea to check our Platform for the latest offerings.
            </p>
          </div>

          <p className="text-[24px] max-md:text-[18px] pt-[40px]">Pricing</p>
          <div className="mt-[20px] pb-[40px] text-[#1d1d1d] tracking-[0.15008px] text-opacity-[60%] border-b-[1px] border-solid border-[#cccccc]">
            <p className="mt-[20px] text-[18px] max-md:text-[14px]">
              For now, creating an account and using our basic Services
              (browsing through the Platform) is free. But we might change the
              pricing at any time. ASIGN will keep you informed on such changes
              and will endeavour to provide reasonable prior notice before
              charging you.
            </p>
          </div>

          <p className="text-[24px] max-md:text-[18px] pt-[40px]">Payment</p>
          <div className="mt-[20px] pb-[40px] text-[#1d1d1d] tracking-[0.15008px] text-opacity-[60%] border-b-[1px] border-solid border-[#cccccc]">
            <p className="mt-[20px] text-[18px] max-md:text-[14px]">
              If you need to pay for any services on our Platform, you can do so
              using various payment methods like credit/debit cards, online
              banking, and more. We tie up with payment gateway service
              providers to facilitate such payment. This may range from payment
              via UPI, RTGS, NEFT or any other acceptable mode of banking
              channels available under law.
            </p>
            <p className="mt-[20px] text-[18px] max-md:text-[14px]">
              Remember, ASIGN isn't responsible for any losses you might face
              while using our Services or third-party Services on our Platform.
              If there's an issue with a third-party service provider, we will
              not be held liable for the same.
            </p>
            <p className="mt-[20px] text-[18px] max-md:text-[14px]">
              Please bear in mind that we may store your payment
              information/debit card/credit card information to facilitate
              future payments with ease. However, the same will be stored
              securely as required under law. Further, disclosure of the same
              will be for the limited purposes of complying with the law or to
              facilitate payment for Services that are undertaken on the
              Platform.
            </p>
          </div>

          <p className="text-[24px] max-md:text-[18px] pt-[40px]">
            Termination of account
          </p>
          <div className="mt-[20px] pb-[40px] text-[#1d1d1d] tracking-[0.15008px] text-opacity-[60%] border-b-[1px] border-solid border-[#cccccc]">
            <p className="mt-[20px] text-[18px] max-md:text-[14px]">
              You have the freedom to terminate your ASIGN account whenever you
              wish by reaching out to us at{" "}
              <span className="text-[#1d1d1d] underline">care@asign.art.</span>
            </p>
            <p className="mt-[20px] text-[18px] max-md:text-[14px]">
              On our end, we also have the authority to end your ASIGN account
              at any time if you violate our Agreement, Privacy Policy, or for
              any other valid reason as determined by us and there can be no
              claim on us for the same. We can also stop giving you access to
              ASIGN if you break the rules, infringe on others' intellectual
              property, or for other reasons at our discretion.
            </p>
            <p className="mt-[20px] text-[18px] max-md:text-[14px]">
              If you decide to delete your account, the process starts as soon
              as you request it. It might take around 30 days to completely
              remove your content from the Platform. During this time, your
              profile may not be visible to others, but it will be visible to
              us. Rest assured, we won't share it with anyone.
            </p>
            <p className="mt-[20px] text-[18px] max-md:text-[14px]">
              If you delete your account or we disable it, this Agreement shall
              stand terminated.
            </p>
          </div>

          <p className="text-[24px] max-md:text-[18px] pt-[40px]">
            Limitation of liability
          </p>
          <div className="mt-[20px] pb-[40px] text-[#1d1d1d] tracking-[0.15008px] text-opacity-[60%] border-b-[1px] border-solid border-[#cccccc]">
            <p className="mt-[20px] text-[18px] max-md:text-[14px]">
              ASIGN cannot be held responsible for any losses or damages
              incurred by the User when using the Services, whether provided
              directly by ASIGN or through a third-party Service provider on the
              Platform
            </p>
            <p className="mt-[20px] text-[18px] max-md:text-[14px]">
              The User acknowledges that if there's any problem or shortcoming
              in the Services, including issues with third-party payment
              gateways on the Platform, it's the responsibility of the
              third-party service provider. ASIGN cannot be held accountable for
              such issues but will put in its best efforts to remedy the same.
            </p>
            <p className="mt-[20px] text-[18px] max-md:text-[14px]">
              Furthermore, any faults or shortcomings in the Services caused by
              the third-party Service provider won't be attributed to ASIGN in
              any way.
            </p>
            <p className="mt-[20px] text-[18px] max-md:text-[14px]">
              ASIGN won't be liable for any violation of terms and conditions if
              it's due to circumstances beyond our reasonable control.
            </p>
          </div>

          <p className="text-[24px] max-md:text-[18px] pt-[40px]">
            Force majure
          </p>
          <div className="mt-[20px] pb-[40px] text-[#1d1d1d] tracking-[0.15008px] text-opacity-[60%] border-b-[1px] border-solid border-[#cccccc]">
            <p className="mt-[20px] text-[18px] max-md:text-[14px]">
              ASIGN cannot be held accountable for any failure or delay in
              fulfilling its obligations, either in part or in full, if such
              issues arise due to factors beyond our control. These factors
              include acts of God, war, terrorism, extreme weather, fire,
              explosions, floods, epidemics, pandemics, strikes, labour
              disputes, government actions, server downtime, hacking,
              cyber-attacks that may compromise the database of ASIGN,
              electricity or telephone service interruptions, or any other
              unforeseeable events that are beyond ASIGN's reasonable control.
            </p>
          </div>

          <p className="text-[24px] max-md:text-[18px] pt-[40px]">Losses</p>
          <div className="mt-[20px] pb-[40px] text-[#1d1d1d] tracking-[0.15008px] text-opacity-[60%] border-b-[1px] border-solid border-[#cccccc]">
            <p className="mt-[20px] text-[18px] max-md:text-[14px]">
              We provide access to the Platform to facilitate your
              work/business. Therefore, your use of the Services provided by
              ASIGN could impact other stakeholders interacting with ASIGN on or
              via the Platform including ASIGN. Further, as the world of art is
              highly driven by intellectual property rights and goodwill; your
              representations, actions, or negligence while enjoying the
              Services or accessing the Platform could potentially be injurious
              to ASIGN as well.
            </p>
            <p className="mt-[20px] text-[18px] max-md:text-[14px]">
              Therefore, there could be a scenario where losses or damages
              (including reputational) are suffered by ASIGN on account of your
              breach of this Agreement. To that extent, you may be required as
              determined by ASIGN to make good our loss suffered by us on
              account of your direct or indirect actions. Further, we may ask
              you to make good our loss if such loss is borne by us on account
              of any claims made by persons or entities that you have hurt
              through our Platform.
            </p>
          </div>

          <p className="text-[24px] max-md:text-[18px] pt-[40px]">Warranty</p>
          <div className="mt-[20px] pb-[40px] text-[#1d1d1d] tracking-[0.15008px] text-opacity-[60%] border-b-[1px] border-solid border-[#cccccc]">
            <p className="mt-[20px] text-[18px] max-md:text-[14px]">
              The User hereby warrants that all the details and information
              provided to ASIGN or its directors, employees, associates and/or
              partners while using this Platform shall be correct, accurate and
              genuine. To the fullest extent permissible under applicable law,
              ASIGN and its directors, employees, associates and/or third-party
              partners disclaim all warranties or guarantees – whether
              statutory, express or implied – including, but not limited to,
              implied warranties of merchantability, fitness and
              non-infringement of proprietary rights. No advice or information,
              whether oral or written, obtained by you from ASIGN or through the
              Platform will create any warranty or guarantee other than those
              expressly stated herein.
            </p>
          </div>

          <p className="text-[24px] max-md:text-[18px] pt-[40px]">
            Disclaimers
          </p>
          <div className="mt-[20px] pb-[40px] text-[#1d1d1d] tracking-[0.15008px] text-opacity-[60%] border-b-[1px] border-solid border-[#cccccc]">
            <p className="mt-[20px] text-[18px] font-medium text-[#1d1d1d]">
              (i) As to the Platform
            </p>
            <p className="mt-[20px] text-[18px] max-md:text-[14px]">
              The Platform as we described in the earlier part of this Agreement
              is an entire ecosystem that is accessible to all our users in the
              form you see it. In essence, no part of the Platform is
              customisable or is customised exclusively for your specific needs
              whatever they may be. Therefore, the Platform should not be
              construed with a notion of fitness for a particular use. The
              functionalities of the Platform are self-explanatory in the form
              of use cases. The said use case is all the Platform will perform.
            </p>
            <p className="mt-[20px] text-[18px] max-md:text-[14px]">
              Further, as is the case with the online ecosystem and the inherent
              nature of software, you may expect viruses or other harmful
              components despite us making all efforts to prevent the same. If
              at all you face such issues we will not be held liable for the
              same but we will make our best efforts to remedy the same
            </p>

            <p className="mt-[20px] text-[18px] font-medium text-[#1d1d1d]">
              (ii) As to Third Party Service Providers
            </p>
            <p className="mt-[20px] text-[18px] max-md:text-[14px]">
              As discussed above, you may interact with various third-party
              service providers that we tie up with to facilitate various
              Services and functionalities on the Platform. This may range from
              payment gateway providers to third-party servers/entities that
              collect and store your data required for us to run the Platform.
              While we vet such third-party service providers; you must
              acknowledge that your dealings with such entities are a private
              transaction between you and them. Therefore, we shall not be held
              responsible for any losses or damages that you may suffer during
              your interaction with them on or outside the contours of our
              Platform However, we will undertake our best efforts to remedy the
              same.
            </p>

            <p className="mt-[20px] text-[18px] font-medium text-[#1d1d1d]">
              (iii) As to your operations
            </p>
            <p className="mt-[20px] text-[18px] max-md:text-[14px]">
              You are solely responsible for your conduct, and representations
              provided about your work to other Users on the Platform. ASIGN
              will not be held responsible for the same.
            </p>
          </div>

          <p className="text-[24px] max-md:text-[18px] pt-[40px] text-[#1d1d1d]">
            Non-solicitation
          </p>
          <div className="mt-[20px] pb-[40px] text-[#1d1d1d] tracking-[0.15008px] text-opacity-[60%] border-b-[1px] border-solid border-[#cccccc]">
            <p className="mt-[20px] text-[18px] max-md:text-[14px]">
              The User hereby acknowledges and agrees that it shall not avail of
              any of the Services provided by ASIGN from any of the third-party
              service providers engaged by ASIGN on an independent basis and/or
              without the involvement of ASIGN and or its Platform, which causes
              any damage or loss whatsoever to ASIGN during the subsistence of
              this Agreement and for a period of following the termination of
              this Agreement.
            </p>
          </div>

          <p className="text-[24px] max-md:text-[18px] pt-[40px] text-[#1d1d1d]">
            Waiver
          </p>
          <div className="mt-[20px] pb-[40px] text-[#1d1d1d] tracking-[0.15008px] text-opacity-[60%] border-b-[1px] border-solid border-[#cccccc]">
            <p className="mt-[20px] text-[18px] max-md:text-[14px]">
              If ASIGN fails to insist upon strict performance of your
              obligations under any of these Terms and Conditions, or if it
              fails to exercise any of the rights or remedies to which it is
              entitled under this Agreement, it will not constitute a waiver of
              such rights or remedies and will not relieve you from compliance
              with such obligations. No waiver by ASIGN of any default will
              constitute a waiver of any subsequent default, and no waiver by
              ASIGN of any of these Terms and Conditions will be effective
              unless it is expressly stated to be a waiver and is communicated
              in writing.
            </p>
          </div>

          <p className="text-[24px] max-md:text-[18px] pt-[40px] text-[#1d1d1d]">
            Assignment
          </p>
          <div className="mt-[20px] pb-[40px] text-[#1d1d1d] tracking-[0.15008px] text-opacity-[60%] border-b-[1px] border-solid border-[#cccccc]">
            <p className="mt-[20px] text-[18px] max-md:text-[14px]">
              The User hereby agrees that ASIGN may transfer, subcontract or
              otherwise deal with ASIGN’S rights and/or obligations under this
              Agreement at any time without any further notice to the User. The
              User hereby agrees that it cannot assign or otherwise transfer, or
              subcontract the terms or any rights granted hereunder to any third
              party in any manner whatsoever.
            </p>
          </div>

          <p className="text-[24px] max-md:text-[18px] pt-[40px] text-[#1d1d1d]">
            Entire agreement
          </p>
          <div className="mt-[20px] pb-[40px] text-[#1d1d1d] tracking-[0.15008px] text-opacity-[60%] border-b-[1px] border-solid border-[#cccccc]">
            <p className="mt-[20px] text-[18px] max-md:text-[14px]">
              This Agreement, together with the other guidelines, rules, terms,
              conditions and/or policies of the Platform, including the Privacy
              Policy and any other arrangement/agreement about the Services,
              constitute the entire agreement between the User and ASIGN and
              shall supersede all previous agreements, promises, proposals,
              representations, understandings and negotiations, whether written
              or oral, between the User and ASIGN about the subject matter
              hereof.
            </p>
          </div>

          <p className="text-[24px] max-md:text-[18px] pt-[40px] text-[#1d1d1d]">
            Survival
          </p>
          <div className="mt-[20px] pb-[40px] text-[#1d1d1d] tracking-[0.15008px] text-opacity-[60%] border-b-[1px] border-solid border-[#cccccc]">
            <p className="mt-[20px] text-[18px] max-md:text-[14px]">
              Notwithstanding the termination or rescission of this Agreement,
              the provisions of this Agreement shall continue to apply to those
              duties and obligations which are intended to survive any such
              cancellation, termination or rescission, including, without
              limitation clauses related to Confidentiality, Limitation of
              Liability, Indemnity, Warranty, Intellectual Property, Dispute
              Resolution, Governing Law and Jurisdiction. Further, any
              provisions of this Agreement which by implication are to survive
              the termination of this Agreement shall survive such termination.
              Termination of the Agreement shall not abate the causes of action
              that have accrued to the Parties before such termination.
            </p>
          </div>

          <p className="text-[24px] max-md:text-[18px] pt-[40px] text-[#1d1d1d]">
            Confidentiality
          </p>
          <div className="mt-[20px] pb-[40px] text-[#1d1d1d] tracking-[0.15008px] text-opacity-[60%] border-b-[1px] border-solid border-[#cccccc]">
            <p className="mt-[20px] text-[18px] max-md:text-[14px]">
              The Parties hereby agree to hold each other’s proprietary or
              confidential information (whether in oral/physical/digital or any
              other form) in the strictest confidence. “Proprietary or
              Confidential Information” shall include, but is not limited to,
              written or oral Agreements, trade secrets, know-how, business
              methods, business policies, memoranda, reports, records,
              computer-retained information, notes, or financial information.
            </p>
          </div>

          <p className="text-[24px] max-md:text-[18px] pt-[40px] text-[#1d1d1d]">
            Intellectual property rights
          </p>
          <div className="mt-[20px] pb-[40px] text-[#1d1d1d] tracking-[0.15008px] text-opacity-[60%] border-b-[1px] border-solid border-[#cccccc]">
            <p className="mt-[20px] text-[18px] max-md:text-[14px]">
              All rights and title to all the intellectual property contained
              within the Platform including without limitation all its
              constituents, content, text, images, artistic work, computer
              programs, specifications, instructions, abstracts, summaries, copy
              sketches, drawings, artwork, software, source code, object code,
              comments on the source code and object code, domain names,
              application names, designs, database, tools, icons, layout,
              programs, titles, names, manuals, graphics, animation, games,
              applications, user interface instructions, photographs, artist
              profiles, illustrations, contests, and all other elements, data,
              information and materials (“Intellectual Property'') shall belong
              solely and absolutely to ASIGN. The ASIGN Platform is protected by
              all prevailing copyrights, trademarks, patents, trade secrets
              and/or other relevant intellectual property laws of the land.
            </p>
            <p className="mt-[20px] text-[18px] max-md:text-[14px]">
              No information, content or material from the Platform may be
              copied, reproduced, republished, uploaded, posted, transmitted or
              distributed in any way without ASIGN’s express written permission.
              The User is hereby given a limited licence to use the Platform for
              the User’s personal and non-commercial use, subject to the User’s
              acceptance of these Terms. You agree not to sell, license,
              distribute, copy, modify, publicly perform display, transmit,
              publish, edit, adapt, create derivative works from, or otherwise
              make unauthorised use of the ASIGN Platform.
            </p>
            <p className="mt-[20px] text-[18px] max-md:text-[14px]">
              Nothing on this Platform or your use of the Services shall be
              construed as conferring on the User or any party, any licence or
              other rights under the Intellectual Property or other proprietary
              rights of ASIGN, its affiliates or any third party, whether
              implied or otherwise. The User shall not use any of the Marks as
              own marks or as part of own marks or in any manner which is likely
              to cause confusion or to identify your own products or Services or
              to imply endorsement or otherwise of products or Services to which
              they do not relate or in any manner which may cause damage to the
              reputation of ASIGN and/or its affiliates and/or associates. The
              User shall not create or use any other mark that is deceptively
              similar to the Marks, be it for Your own use or the use of any
              third party. Any commercial use of any elements or information on
              ASIGN’s Platform, directly or otherwise, is strictly prohibited
              unless express written permission is obtained in advance. ASIGN
              shall not be responsible for claims relating to infringement of
              any intellectual property rights about the Service(s) and/or their
              Intellectual Property.
            </p>
          </div>

          <p className="text-[24px] max-md:text-[18px] pt-[40px] text-[#1d1d1d]">
            User content
          </p>
          <div className="mt-[20px] pb-[40px] text-[#1d1d1d] tracking-[0.15008px] text-opacity-[60%] border-b-[1px] border-solid border-[#cccccc]">
            <p className="mt-[20px] text-[18px] max-md:text-[14px]">
              The Platform may allow the User to interact with other Users by
              posting and publishing comments, reviews, etc. on the Platform
              ("User Content"). Such User Content does not reflect the views of
              ASIGN and ASIGN does not in any manner endorse or recommend any
              User Content. In no event shall ASIGN be held responsible for any
              User Content and/or be liable for any loss or damages resulting
              from publishing of the User Content on the Platform whatsoever.
            </p>
            <p className="mt-[20px] text-[18px] max-md:text-[14px]">
              ASIGN reserves the right in its sole discretion to delete, block,
              restrict, disable or suspend the User’s account or part thereof.
              Further, ASIGN shall have the sole and absolute right to take
              down, delete or remove any User Content from the Platform at its
              sole discretion. If the User is found engaging in any
              fraudulent/illegal activities including but not limited to the
              following activities i.e. abusing any of the representatives of
              the organisation, indulging in fraudulent activities on the
              Platform, using mass media and/or bots to engage with the
              platform, using mass media and/or bots to malign the
              organisation’s reputation these activities may be referred to
              appropriate legal authority for legal recourse. ASIGN shall not be
              responsible and/or liable in any manner whatsoever for the User
              Content published on the Platform by the User. Additionally, in
              relation to the posting of User Content and by continuing using
              the Platform or Services of Platform, the User shall not in any
              manner whatsoever:{" "}
              <span className="font-medium">(“Community Guidelines”)</span>
            </p>
            <ul className="list-square pl-6 space-y-2 text-[#696969] font-sh5 text-[18px] pt-[10px] leading-[120%] font-normal">
              <li className="marker:text-[#1d1d1d] pt-[10px]">
                Defame, abuse, harass, stalk, threaten, contact or otherwise
                violate the legal rights of other Users;
              </li>
              <li className="marker:text-[#1d1d1d] pt-[10px]">
                Publish, post, upload, distribute or disseminate any
                inappropriate, profane, defamatory, infringing, obscene,
                indecent or unlawful topic, name, material or information,
                especially that which may be harmful to any minor using the
                Platform;
              </li>
              <li className="marker:text-[#1d1d1d] pt-[10px]">
                Copy, republish, post, display, translate, transmit, reproduce
                or distribute any content on the Platform through any medium;
              </li>
              <li className="marker:text-[#1d1d1d] pt-[10px]">
                Manipulate, amend, morph, alter or exploit any other User’s
                Content;
              </li>
              <li className="marker:text-[#1d1d1d] pt-[10px]">
                Conduct or forward surveys, contests, pyramid schemes or chain
                letters;
              </li>
              <li className="marker:text-[#1d1d1d] pt-[10px]">
                Upload or distribute files that contain software or other
                material protected by applicable intellectual property laws
                unless You own or control the rights thereto or have received
                all necessary consents;
              </li>
              <li className="marker:text-[#1d1d1d] pt-[10px]">
                Upload or distribute files that contain viruses, corrupted
                files, or any other similar software or programs that may damage
                the operation of the Platform or another’s computer;
              </li>
              <li className="marker:text-[#1d1d1d] pt-[10px]">
                Engage in any activity that interferes with or disrupts access
                to the Platform (or the servers and networks that are connected
                to the Platform);
              </li>
              <li className="marker:text-[#1d1d1d] pt-[10px]">
                Attempt to gain unauthorised access to any portion or feature of
                the Platform, any other systems or networks connected to the
                Platform, to any of the ASIGN’s servers, or through the
                Platform, by hacking, password mining or any other illegitimate
                means;
              </li>
              <li className="marker:text-[#1d1d1d] pt-[10px]">
                Probe, scan or test the vulnerability of the Platform or any
                network connected to the Platform, nor breach the security or
                authentication measures on the Platform or any network connected
                to the Platform. You may not reverse look-up, trace, or seek to
                trace any information on any other User, of or visitor to, the
                Platform, to its source, or exploit the Platform or information
                made available or offered by or through the Platform, in any way
                whether or not the purpose is to reveal any information,
                including but not limited to personal identification
                information, other than Your own information, as provided on the
                Platform;
              </li>
              <li className="marker:text-[#1d1d1d] pt-[10px]">
                Disrupt or interfere with the security of, or otherwise cause
                harm to, the Platform, systems resources, accounts, passwords,
                servers or networks connected to or accessible through the
                Platform or any affiliated or linked sites;
              </li>
              <li className="marker:text-[#1d1d1d] pt-[10px]">
                Collect or store data about other Users in connection with the
                prohibited conduct and activities;
              </li>
              <li className="marker:text-[#1d1d1d] pt-[10px]">
                Use any device or software to interfere or attempt to interfere
                with the proper working of the Platform or any transaction being
                conducted on the Platform, or with any other person’s use of the
                Platform;
              </li>
              <li className="marker:text-[#1d1d1d] pt-[10px]">
                Use the Platform or any material or content for any purpose that
                is unlawful or prohibited by these Terms of Use, or to solicit
                the performance of any illegal activity or other activity which
                infringes the rights of the Company or other third parties;
              </li>
              <li className="marker:text-[#1d1d1d] pt-[10px]">
                Falsify or delete any author attributions, legal or other proper
                notices proprietary designations or labels of the origin or
                source of software or other material contained in a file that is
                uploaded;
              </li>
              <li className="marker:text-[#1d1d1d] pt-[10px]">
                Violate any applicable laws or regulations for the time being in
                force within or outside India or anyone’s right to privacy or
                personality;
              </li>
              <li className="marker:text-[#1d1d1d] pt-[10px]">
                Violate the Terms of Use contained herein or elsewhere; and
                reverse engineer, modify, copy, distribute, transmit, display,
                perform, reproduce, publish, licence, create derivative works
                from, transfer, or sell any information or software obtained
                from the Platform.
              </li>
            </ul>
            <p className="mt-[20px] text-[18px] max-md:text-[14px]">
              The User shall, at all times in the publishing of the User Content
              and/or accessing the Platform and availing the Services on the
              Platform remain in strict compliance with the Community Guidelines
              herein. ASIGN hereby reserves the right to block, suspend,
              terminate and/or restrict the access of the User to the Platform
              in any manner whatsoever for the violation of the Community
              Guidelines, in ASIGN’s sole discretion and such decision of ASIGN
              shall be final in this regard.
            </p>
            <p className="mt-[20px] text-[18px] max-md:text-[14px]">
              By submitting User Content, the User hereby grants ASIGN a
              perpetual, worldwide, royalty-free, irrevocable, non-exclusive
              licence to use, and authorise others to use the User Content in
              whole or in part, including rights to use the User Content in
              isolation or combination with any other material. The User hereby
              agrees that in such circumstances, the User is not entitled to any
              intimation or compensation from ASIGN. Intellectual property
              rights in any and all improvements made to the User Content by
              ASIGN shall vest in ASIGN upon its creation without any charge or
              fee payable to the User in this regard whatsoever.
            </p>
          </div>

          <p className="text-[24px] max-md:text-[18px] pt-[40px] text-[#1d1d1d]">
            Amendment and severability
          </p>
          <div className="mt-[20px] pb-[40px] text-[#1d1d1d] tracking-[0.15008px] text-opacity-[60%] border-b-[1px] border-solid border-[#cccccc]">
            <p className="mt-[20px] text-[18px] max-md:text-[14px]">
              ASIGN reserves its rights to amend these Terms and Conditions from
              time to time, without any requirement for notice to the Users. By
              continuing to access or use the Service after we have posted or
              emailed any such modifications, the User thereby agrees to be
              bound by the modified Terms of Use, but solely on a going-forward
              basis. All the amendments made to this Agreement in future shall
              be read as part of this Agreement unless otherwise intended.
            </p>
            <p className="mt-[20px] text-[18px] max-md:text-[14px]">
              If any clause of this Agreement is held by a court of competent
              jurisdiction to be contrary to law, such provision shall be
              changed and interpreted to best accomplish the objectives of the
              original provision to the fullest extent allowed by law and the
              remaining provisions of this Policy will remain in full force and
              effect.
            </p>
          </div>

          <p className="text-[24px] max-md:text-[18px] pt-[40px] text-[#1d1d1d]">
            Governing law and dispute resolution
          </p>
          <div className="mt-[20px] pb-[40px] text-[#1d1d1d] tracking-[0.15008px] text-opacity-[60%] border-b-[1px] border-solid border-[#cccccc]">
            <p className="mt-[20px] text-[18px] max-md:text-[14px]">
              This Agreement shall be governed, interpreted, and construed by
              the laws of India, without regard to the conflict of law
              provisions and for resolution of any dispute arising out of your
              use of the Services or about these Terms.
            </p>
            <p className="mt-[20px] text-[18px] max-md:text-[14px]">
              Notwithstanding the foregoing, you agree that (i) ASIGN has the
              right to bring any proceedings before any court/forum of competent
              jurisdiction and you irrevocably submit to the jurisdiction of
              such courts or forum; and (ii) any proceeding brought by you shall
              be exclusively before the courts in Chennai, India.
            </p>
            <p className="mt-[20px] text-[18px] max-md:text-[14px]">
              All disputes arising between the Parties as to the interpretation,
              operation, or effect of any clause in this agreement or any other
              differences between the Parties that cannot be mutually resolved,
              shall be referred to the arbitration. The arbitration panel shall
              consist of a single arbitrator appointed mutually by the Parties
              and the venue of arbitration shall be at Chennai, India. The
              language of arbitration shall be in the English language. The
              decision of the arbitrator shall be binding on the parties. The
              arbitration shall be governed by the provisions of the Arbitration
              and Conciliation Act, 1996 and all amendments enacted therein.
            </p>
          </div>

          <p className="text-[24px] max-md:text-[18px] pt-[40px] text-[#1d1d1d]">
            Support
          </p>
          <div className="mt-[20px] pb-[40px] text-[#1d1d1d] tracking-[0.15008px] text-opacity-[60%] border-b-[1px] border-solid border-[#cccccc]">
            <p className="mt-[20px] text-[18px] max-md:text-[14px]">
              If the User has any queries concerning the Services and/or the
              Platform, then it may address an email with such queries to{" "}
              <span className="underline text-[#1d1d1d]">care@asign.art</span>.
            </p>
          </div>

          <p className="text-[24px] max-md:text-[18px] pt-[40px] text-[#1d1d1d]">
            Grievance officer
          </p>
          <div className="mt-[20px] pb-[40px] text-[#1d1d1d] tracking-[0.15008px] text-opacity-[60%]">
            <p className="mt-[20px] text-[18px] max-md:text-[14px]">
              If the User has any grievances regarding the use of the personal
              information provided by the User under this Agreement concerning
              the processing of such information, or any other complaints about
              the Services offered on the Platform, please address your
              grievance to{" "}
              <span className="underline text-[#1d1d1d]">care@asign.art</span>.
            </p>
            <p className="mt-[20px] text-[18px] max-md:text-[14px]">
              The Grievance Officer is identified above under the provisions of
              applicable laws, including but not limited to the Information
              Technology Act, 2000 and the Consumer Protection Act, 2019, and
              the rules enacted under those laws.
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
