import React, { useEffect, useRef, useState, useMemo } from "react";
import Image from "@/components/Global/Image";
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import { Button } from "@/components/ui/button";
import Slider from "react-slick";
import { BusinessVaritantOne } from "@/types/business.type";
import { useNavigate } from "react-router-dom";
import { useUserContext } from "@/context/user";
import { findById, getId } from "@/lib/helper";
import Like from "@/components/Global/intreactions/Like";
import { cn } from "@/lib/utils";
import { format } from "date-fns";
import AddBoardDropDown from "@/components/Boards/AddItemDropDown";
interface Props {
  data: BusinessVaritantOne[];
  title: string;
  type?: "4x" | "big";
  sectionId?: string;
}
const BBig = ({ data, title, type = "big", sectionId }: Props) => {
  const sliderRef = useRef<Slider | null>(null);
  const navigate = useNavigate();
  const next = () => {
    sliderRef.current?.slickNext();
  };

  const previous = () => {
    sliderRef.current?.slickPrev();
  };

  const containerRef = useRef<HTMLDivElement>(null);
  const [slidesToShow, setSlidesToShow] = useState(2);

  const updateSlidesToShow = (width: number) => {
    // changed responsive
    if (width >= 800) {
      setSlidesToShow(3);
    } else if (width >= 450) {
      setSlidesToShow(2);
    } else {
      setSlidesToShow(1);
    }
  };

  useEffect(() => {
    const container = containerRef.current;
    if (!container) return;

    const resizeObserver = new ResizeObserver((entries) => {
      for (let entry of entries) {
        updateSlidesToShow(entry.contentRect.width);
      }
    });

    resizeObserver.observe(container);
    return () => resizeObserver.disconnect();
  }, [type]);
  let dragging = false;
  const settings = useMemo(
    () => ({
      infinite: true,
      speed: 500,
      slidesToShow,
      slidesToScroll: slidesToShow,
      arrows: false,
      centerMode: true,
      centerPadding: "10%",
      beforeChange: () => (dragging = true),
      afterChange: () => (dragging = false),
    }),
    [slidesToShow],
  );
  const renderArtistCard = (
    item: BusinessVaritantOne | number,
    index: number,
  ) => {
    const isPlaceholder = typeof item === "number";
    const object = isPlaceholder ? null : item;

    return (
      <div
        key={isPlaceholder ? index : object?._id}
        className="relative pr-[20px] flex flex-col"
      >
        <div className="relative group">
          <div className="relative overflow-hidden aspect-square">
            {isPlaceholder || !object?.profilePic ? (
              <div className="w-full h-full bg-gradient-to-b from-gray-200 to-black" />
            ) : (
              <Image
                src={object?.profilePic}
                alt="profile pic"
                className="object-cover w-full h-full brightness-[50%] cursor-pointer"
                loading="lazy"
                onClick={() => {
                  if (dragging) return;
                  navigate(`/business/detail/${object._id}`);
                }}
              />
            )}
            {/* avatart image with title */}
            <div className="absolute left-0 flex items-center w-full gap-x-3 px-5 text-white justify bottom-5 mq750:px-5">
              <Avatar className="w-[64px] h-[64px] mq750:w-[56px] mq750:h-[56px]">
                <AvatarImage
                  src={object?.profilePic}
                  className="object-cover"
                />
                <AvatarFallback>{object?.displayName}</AvatarFallback>
              </Avatar>
              <p className="flex flex-col text-xs">
                <span className="text-sm capitalize mq750:text-base ">
                  {object?.displayName}
                </span>
                <span className="text-[#CCCCCC] mt-2">
                  {object?.dob && "b. " + format(object.dob, "yyyy")}
                </span>
              </p>
            </div>
            <AddBoardDropDown
              itemID={getId(object)}
              boardID={""}
              itemType={"business"}
            >
              <span className="absolute top-4 bg-white text-other-cta rounded-full right-4 p-[5.5px_12px] text-sm flex gap-x-1 opacity-0 scale-95 group-hover:opacity-100 group-hover:scale-100 invisible group-hover:visible cursor-pointer transition-all duration-300 ease-in-out items-center">
                <img src="/label.svg" alt="label" /> Board
              </span>
            </AddBoardDropDown>
          </div>
        </div>
        {/* movile padding changed */}
        <div className="bg-[#EEEEEA] mq450:p-5 py-4 px-3 flex flex-col flex-grow">
          <div className="flex items-center justify-between ">
            <div>
              <p className="my-1 text-xs text-gray-60 leading-[14.4px]">
                Representing
              </p>
              <div className="flex items-center ml-5 gap-x-1">
                {object?.represents?.length ?? 0 ? (
                  object?.represents?.slice(0, 3).map((item) => (
                    <Avatar className="w-9 h-9 ml-[-20px]" key={item._id}>
                      <AvatarImage src={item.image} className="object-cover" />
                      <AvatarFallback>{item.name}</AvatarFallback>
                    </Avatar>
                  ))
                ) : (
                  <span className="text-xs text-gray-400">
                    <div className="w-9 h-9 ml-[-20px]"></div>
                  </span>
                )}
                {(object?.represents?.length ?? 0) > 3 && (
                  <Avatar className="w-9 h-9 ml-[-18px]">
                    <AvatarImage src="" className="object-cover" />
                    <AvatarFallback className="text-xs">
                      {object?.represents?.length ?? 0}+
                    </AvatarFallback>
                  </Avatar>
                )}
              </div>
            </div>
            <FollowBussiness
              id={object?._id}
              className="mt-3 text-sm text-black border border-solid rounded-full border-gray-20 p-[8px_20px] uppercase"
            />
          </div>
          {/* <p className="mt-1 text-xs capitalize text-gray-60">
            {object?.businessDate && isToday(object.businessDate) && "Live Now"}
          </p> */}
          <p className="mt-5 text-xs text-gray-60">Live now</p>
          {/* show 2 line from 1 */}
          <p className="mt-4 text-sm line-clamp-2">
            {object?.about} Lorem ipsum dolor sit amet, consectetur adipisicing
            elit. Voluptatem nemo vitae ex. Ducimus, quisquam molestias quis
            numquam iure placeat odio debitis explicabo nisi minus voluptatem,
            ratione, ipsa vitae eius assumenda.
          </p>
        </div>
      </div>
    );
  };

  return (
    <div className="font-sh5" ref={containerRef}>
      <div className="flex items-center justify-between gap-2 mb-8 mq750:mb-6">
        <h3 className="mq450:text-[24px] mq450:leading-[28.8px] text-[28px] leading-[33.6px]  font-normal capitalize truncate">
          {title || "Section Title"}
        </h3>
        <div className="flex flex-row items-center">
          <Button
            className="text-sm font-medium underline bg-transparent mq750:text-xs mq1000:px-0"
            variant="link"
            size="sm"
            onClick={() => navigate(`/business/view/${sectionId}`)}
          >
            VIEW ALL
          </Button>
          <div className="lg:flex flex-row items-center gap-[5px] hidden">
            {[true, false].map((flip, index) => (
              <img
                key={index}
                className="p-[3px] bg-[#EEEEEA] rounded-[100%] h-[26px] w-[26px] cursor-pointer"
                style={flip ? { transform: "scaleX(-1)" } : {}}
                src="/arrow-right.svg"
                alt={flip ? "Previous" : "Next"}
                onClick={flip ? previous : next}
              />
            ))}
          </div>
        </div>
      </div>
      <Slider {...settings} ref={sliderRef}>
        {(data.length <= 1 ? [1, 2, 3, 4, 5] : data).map((item, index) =>
          renderArtistCard(item, index),
        )}
      </Slider>
    </div>
  );
};

export default BBig;

export const FollowBussiness = ({
  id,
  className,
}: {
  id?: string;
  className?: string;
}) => {
  const { interactions } = useUserContext();
  return (
    <Like
      itemId={id ?? " "}
      action={
        findById(interactions?.businessFollowed, id ?? "") ? "remove" : "add"
      }
      itemType="business"
    >
      <Button
        variant="outline"
        size="sm"
        className={cn("text-white border-white rounded-[33px] ", className)}
      >
        {findById(interactions?.businessFollowed, id ?? "")
          ? "Following"
          : "Follow"}
      </Button>
    </Like>
  );
};
