import React from "react";
import { Link, useLocation } from "react-router-dom";
import Sidebar from "@/pages/profile/Sidebar";

const BottomNavBar = React.memo(() => {
  const location = useLocation();

  const getActiveItem = () => {
    const pathname = location.pathname;

    // Check for exact matches first
    if (pathname === "/") return "discover";
    if (pathname === "/boards") return "boards";
    if (pathname === "/catalog/detail") return "catalog";
    if (pathname === "/profile") return "profile";

    // Check for routes with additional segments
    if (pathname.startsWith("/boards/")) return "boards";
    if (pathname.startsWith("/catalog/")) return "catalog";

    // Default case
    return "discover";
  };

  const menuActiveItem = getActiveItem();

  return (
    <div className="fixed bottom-0 left-0 z-[50] hidden w-full h-20 py-4 mq450:block  mq1000:block bg-other-cta">
      <div className="flex flex-row items-center justify-between mq450:px-[24px] px-[48px]">
        <div>
          <Link to="/discover/feed">
            <div className="flex flex-col items-center justify-center gap-2">
              <img
                className="w-6 h-6"
                src={
                  menuActiveItem === "discover"
                    ? "/discoverSelected.svg"
                    : "/DIscoverlogo.png"
                }
                alt="Discover"
              />
              <p
                style={{
                  color: menuActiveItem === "discover" ? "#FFFFFF" : "#CCCCCC",
                }}
                className="text-xs font-normal  font-sh5"
              >
                Discover
              </p>
            </div>
          </Link>
        </div>
        <div>
          <Link to="/boards">
            <div className="flex flex-col items-center justify-center gap-2">
              <img
                className="w-6 h-6"
                src={
                  menuActiveItem === "boards"
                    ? "/boardsSelected.svg"
                    : "/boardUnselected.svg"
                }
                alt="Boards"
              />
              <p
                style={{
                  color: menuActiveItem === "boards" ? "#FFFFFF" : "#CCCCCC",
                }}
                className="text-xs font-normal  font-sh5"
              >
                Boards
              </p>
            </div>
          </Link>
        </div>
        <div>
          <Link to="/catalog/detail">
            <div className="flex flex-col items-center justify-center gap-2">
              <img
                className="w-6 h-6"
                src={
                  menuActiveItem === "catalog"
                    ? "/catalagoueSelected.svg"
                    : "/catalogueUnselected.svg"
                }
                alt="Catalogue"
              />
              <p
                style={{
                  color: menuActiveItem === "catalog" ? "#FFFFFF" : "#CCCCCC",
                }}
                className="text-xs font-normal  font-sh5"
              >
                Catalogue
              </p>
            </div>
          </Link>
        </div>
        <div>
          {/* <Link to="/profile">
            <div className="flex flex-col items-center justify-center gap-2">
              <img
                className="w-6 h-6"
                src={
                  menuActiveItem === "profile"
                    ? "/personSelected.svg"
                    : "/person.svg"
                }
                alt="Catalogue"
              />
              <p className="text-xs font-plus-jakarta-sans font-normal text-[#CCCCCC]">
                You
              </p>
            </div>
          </Link> */}
          <Sidebar>
            <div className="flex flex-col items-center justify-center gap-2">
              <img
                className="relative w-6 h-6 overflow-hidden shrink-0"
                alt="Profile"
                src={
                  menuActiveItem === "profile"
                    ? "/personSelected.svg"
                    : "/person.svg"
                }
              />
              <p
                style={{
                  color: menuActiveItem === "profile" ? "#FFFFFF" : "#CCCCCC",
                }}
                className="text-xs font-normal font-plus-jakarta-sans font-sh5"
              >
                You
              </p>
            </div>
          </Sidebar>
        </div>
      </div>
    </div>
  );
});

export default BottomNavBar;
