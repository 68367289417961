import { apis } from ".";
import { QueryData } from "@/hooks/useFilter";
import { Section } from "@/types/atrists.type";

export interface sectionParams {
  searchQuery?: string;
  filters?: {
    [key: string]: string;
  };
  sort?: {
    key: string;
    direction: "asc" | "desc";
  };
  options?: {
    pagination: boolean;
  };
  query?: {
    type: string;
  };
}
export interface SectionById {
  id: string;
  params?: sectionParams;
  type?: "artist" | "business" | "objects" | "events" | "discover";
}
const useArtist = () => {
  /**
   * Fetches a list of sections (feed) from the server.
   * @param {QueryData} params - Parameters for the query, including searchQuery, filters, sort, options, and query.
   * @returns {Promise<{data: Section[]}>} - A promise that resolves to an object containing the sections data.
   */
  const getFeed = async (
    params: QueryData,
  ): Promise<{
    data: Section[];
  }> => {
    try {
      const { data } = await apis.post("/client/api/v1/artist_section/list", {
        data: params,
      });
      return data.data;
    } catch (error) {
      console.error("Error occurred:", error); // Added logging
      throw error; // Rethrow the error
    }
  };

  /**
   * Fetches a section (artist, business, object, event, or discover) by ID from the server.
   * @param {SectionById} params - Parameters for the query, including the section ID and optional parameters.
   * @returns {Promise<Section>} - A promise that resolves to the section data.
   */
  const getSectionById = async ({
    id,
    params,
  }: SectionById): Promise<Section> => {
    try {
      const { data } = await apis.post(`/client/api/v1/artist_section/${id}`, {
        data: params ?? {},
      });
      return data.data;
    } catch (error) {
      console.error("Error occurred:", error); // Added logging
      throw error; // Rethrow the error
    }
  };

  /**
   * Fetches a random artist from the server.
   * @returns {Promise<Artist>} - A promise that resolves to the random artist data.
   */
  const supriseMe = async () => {
    try {
      const { data } = await apis.post(
        "client/api/v1/artist_section/randomartist",
        {},
      );
      return data;
    } catch (error) {
      console.error("Error occurred:", error); // Added logging
      throw error; // Rethrow the error
    }
  };

  return { getFeed, getSectionById, supriseMe };
};
  
export default useArtist;
