import { BusinessVaritantOne } from "@/types/business.type";
import { EventVaritantOne } from "@/types/event.type";
import { Content } from "@/types/global.type";
import { ObjectVaritantOne } from "@/types/objects.type";
import { User } from "@sentry/react";

export type Item =
  | User
  | Content
  | ObjectVaritantOne
  | EventVaritantOne
  | BusinessVaritantOne;

export const isUser = (item: Item): item is User => {
  return (item as User)?.profilePic !== undefined;
};

export const isContent = (item: Item): item is Content => {
  return (item as Content)?.content !== undefined;
};

export const isObjectVaritantOne = (item: Item): item is ObjectVaritantOne => {
  return (item as ObjectVaritantOne)?.objectName !== undefined;
};

export const isEventVaritantOne = (item: Item): item is EventVaritantOne => {
  return (item as EventVaritantOne)?.eventName !== undefined;
};

export const isBusinessVaritantOne = (
  item: Item,
): item is BusinessVaritantOne => {
  return (item as BusinessVaritantOne)?.profilePic !== undefined;
};

export const getContentImageAndTitle = (
  item: Item,
): {
  title: string;
  image: string;
} => {
  if (isUser(item)) {
    return {
      title: item.displayName,
      image: item.coverImage ?? item.profilePic,
    };
  } else if (isContent(item)) {
    return {
      title: item.content.title ?? "Unknown Content",
      image: item.content?.coverImage || "",
    };
  } else if (isObjectVaritantOne(item)) {
    return {
      title: item.objectName || "Unknown Object",
      image: item.image || "",
    };
  } else if (isEventVaritantOne(item)) {
    return {
      title: item.eventName || "Unknown Event",
      image: item.image || "",
    };
  } else if (isBusinessVaritantOne(item)) {
    return {
      title: item.displayName || "Unknown Business",
      image: item.coverImage ?? item.profilePic,
    };
  } else {
    return {
      title: "Unknown",
      image: "",
    };
  }
};
