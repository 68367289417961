import React from "react";

const Loader = () => {
  return (
    <div className="loading-screen left-0">
      <img
        className="animate-spin absolute  top-[30%] left-[50%] -translate-y-1/2 text-black"
        src="/loading.svg"
        alt=""
      />
    </div>
  );
};

export default Loader;
