import { apis } from ".";
import { QueryData } from "@/hooks/useFilter";

const useAsignProtect = () => {
  const checkEligiblity = async ({ ids }: { ids: string[] }) => {
    try {
      const { data } = await apis.post("/client/api/v1/ap/eligibility", {
        data: {
          objectIds: ids,
        },
      });
      return data;
    } catch (error) {
      throw error;
    }
  };

  const applyAsignProtect = async ({ ids }: { ids: string[] }) => {
    try {
      const { data } = await apis.post("/client/api/v1/ap/request", {
        data: {
          objects: ids,
        },
      });
      return data;
    } catch (error) {
      throw error;
    }
  };

  const getAsignRequestByObjectId = async (id: string) => {
    try {
      const { data } = await apis.get(`/client/api/v1/ap/byObject/${id}`, {});

      return data?.data;
    } catch (error) {
      throw error;
    }
  };

  const getAsignRequestByObjectIds = async (ids: string[]) => {
    try {
      const { data } = await apis.post("/client/api/v1/ap/byObjectIds", {
        data: {
          objectIds: ids,
        },
      });
      return data?.data;
    } catch (error) {
      throw error;
    }
  };

  const requestAgain = async (id: string) => {
    try {
      const { data } = await apis.put(
        `/client/api/v1/ap/requestAgain/${id}`,
        {},
      );
      return data;
    } catch (error) {
      throw error;
    }
  };

  return {
    checkEligiblity,
    applyAsignProtect,
    getAsignRequestByObjectId,
    requestAgain,
    getAsignRequestByObjectIds,
  };
};

export default useAsignProtect;
