import useCountryState from "@/apis/countryState";

// Create our number formatter.
export const formatInRuppes = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "Inr",
});

// this helps to get the if the user if already or follow the particular object

export const findById = (targetArray?: string[], target?: string): boolean => {
  if (!targetArray || !target) {
    return false;
  }
  return targetArray.includes(target);
};
export const getId = (item: any): string => item?.id || item?._id;

export const capitalizeWords = (str: string) => {
  return str
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(" ");
};

// Constants for conversion
// Constants for conversion
const CM_TO_INCH = 0.393701;
const INCH_TO_CM = 2.54;

export const formatDimensions = (object: any): string => {
  const { metaData } = object || {};
  const {
    height,
    width,
    depth,
    diameter,
    dimensionsType = "cm",
  } = metaData || {};

  if (!height || !width) {
    return "Dimensions not available";
  }

  // Convert values based on dimensionsType
  let cmValues: { height: number; width: number };
  let inchValues: { height: number; width: number };

  if (dimensionsType === "cm") {
    cmValues = { height, width };
    inchValues = {
      height: height * CM_TO_INCH,
      width: width * CM_TO_INCH,
    };
  } else {
    inchValues = { height, width };
    cmValues = {
      height: height * INCH_TO_CM,
      width: width * INCH_TO_CM,
    };
  }

  // Format the dimensions string
  return `${formatNumber(cmValues.height)} cm x ${formatNumber(
    cmValues.width,
  )} cm (${formatNumber(inchValues.height)} in x ${formatNumber(
    inchValues.width,
  )} in)`;
};

// Helper function to format numbers to 2 decimal places and remove trailing zeros
const formatNumber = (num: number): string => {
  return Number(num.toFixed(2)).toString();
};

export function formatArtMaterials(medium: string[], surface: string[]) {
  // Join mediums with commas and 'and' before the last item if multiple items exist
  const mediumString =
    medium.length > 1
      ? medium.slice(0, -1).join(", ") + " and " + medium.slice(-1)
      : medium.join(", ");

  // Join surfaces with commas and 'and' before the last item if multiple items exist
  const surfaceString =
    surface.length > 1
      ? surface.slice(0, -1).join(", ") + " and " + surface.slice(-1)
      : surface.join(", ");

  // Combine medium and surface with 'on'
  return `${mediumString} on ${surfaceString}`;
}

export const fetchCountries = async () => {
  try {
    let { getCountries, getStates } = useCountryState();
    let countries = await getCountries();
    let states = await getStates("IN");
    return { countries, states };
  } catch (error) {
    console.log(error);
  }
};
