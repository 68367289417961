import React from "react";
import { Button } from "../ui/button";
import { formatInRuppes } from "@/lib/helper";
import { ToolTipText } from "../Templates/Artists/Big";
import { Avatar, AvatarFallback, AvatarImage } from "../ui/avatar";
import { format } from "date-fns";
import { useNavigate } from "react-router-dom";
import { FollowArtistButton } from "../Templates/Artists/BigBanner";
import { cn } from "@/lib/utils";
import useFilter from "@/hooks/useFilter";
import { ArtFilters } from "./modal/GlobalFilter";
// import { formatDateAndTime, UserUpdateActionDropDown } from "@/pages/profile";

export const ObjectCards = ({ data, type }: { data: any; type?: string }) => {
  return (
    <div className="">
      <div
        className={cn(
          "relative mb-2 bg-[#EEEEEA] p-3 aspect-[9/10] mq1000:aspect-[96/103] mq450:aspect-[7/10]",
          type === "artists" &&
            "mq450:min-w-[300px] mq1000:min-w-[217px] mq450:aspect-[7/9] mq1000:aspect-[14/15]",
        )}
      >
        <img
          src={data?.image ?? "/noimage.png"}
          alt={data?.objectName}
          className="object-contain w-full max-h-[98%]"
        />
      </div>
      <div className="space-y-1">
        {data?.assignProtect && (
          <p className="text-[12px] mq450:text-[10px] mq450:leading-[12px] mq1000:text-sm leading-[12px] mq1000:leading-3 font-sh5 flex items-center">
            <div className="">
              <svg
                className="h-4 w-4 mq450:h-3 mq450:w-3 items-center flex"
                viewBox="0 0 16 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_6505_38488)">
                  <path
                    d="M8.0013 1.99219L2.66797 4.84552V8.58552C2.6697 9.36805 2.86325 10.1382 3.23169 10.8286C3.60012 11.519 4.13219 12.1085 4.7813 12.5455L8.02797 14.5455L11.2746 12.5455C11.9147 12.1033 12.437 11.5113 12.796 10.8212C13.1551 10.131 13.34 9.36349 13.3346 8.58552V4.84552L8.0013 1.99219Z"
                    fill="#1D1D1D"
                  />
                  <path
                    d="M8 5.9375V10.6042"
                    stroke="white"
                    stroke-miterlimit="10"
                  />
                  <path
                    d="M5.66797 8.27344H10.3346"
                    stroke="white"
                    stroke-miterlimit="10"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_6505_38488">
                    <rect
                      width="16"
                      height="16"
                      fill="white"
                      transform="translate(0 0.273438)"
                    />
                  </clipPath>
                </defs>
              </svg>
            </div>
            Asign Protect+
          </p>
        )}
        <p className="mq450:leading-[14.4px] mq450:text-xs mq1000:leading-[25.2px] mq1000:text-lg leading-[16.8px] text-[14px] font-sh5 truncate">
          {data?.objectName}
        </p>
        <p className="text-[#696969] mq1000:text-base mq1000:leading-[19.2px] mq450:text-[10px] mq450:leading-[12px] leading-[16.8px] text-[14px] font-sh5">{`${(data?.metaData?.circa && "c.") || ""}  ${data?.metaData?.compeletionDate?.year || ""} ${data?.metaData?.compeletionDate?.era || ""}  ${data?.metaData?.creationDate?.year || ""} ${data?.metaData?.creationDate?.era || ""} `}</p>
        <p className="font-sh5 mq450:text-xs mq450:leading-[14.4px] mq1000:text-base mq1000:leading-4 text-lg leading-[18px] font-medium pt-2">
          {formatInRuppes.format(20000 as number)}
        </p>
      </div>
    </div>
  );
};

export const BusinessCard = ({ data }: { data: any }) => {
  // console.log(data);
  const navigate = useNavigate();
  return (
    <div className="p-5 mq1000:p-4 border border-solid border-[#e5e5e5]">
      <div className="space-y-1 pb-5">
        <div className="flex items-center gap-[20px] w-full justify-between mq1125:gap-[0px]">
          <div className="flex items-center gap-[10px]">
            <Avatar
              onClick={() => navigate(`/artist/detail/${data._id}`)}
              className="cursor-pointer"
            >
              <AvatarImage src={data.profilePic} alt="" />
              <AvatarFallback>{data.name[0]}</AvatarFallback>
            </Avatar>
            <div>
              <p className="max-w-[10vw] text-[16px] font-normal capitalize truncate">
                {data.name}
              </p>
              <p className="text-sm text-gray-60">
                b. {format(data?.dob ?? new Date(), "yyyy")}
              </p>
            </div>
          </div>
          <FollowArtistButton
            id={data._id}
            className="rounded-[33px] w-[100px] bg-transparent border border-solid border-[#cfcfcf] text-[#1d1d1d]"
          />
        </div>
      </div>
      <img
        src={data?.coverImage ?? "/noimage.png"}
        alt={data?.name[0]}
        className="object-cover w-full h-[214px]"
      />
    </div>
  );
};

const DisplayObjects = ({
  data,
  handleCategory,
  type,
  filterOption,
}: {
  data: any;
  handleCategory: (category: string) => void;
  type: string;
  filterOption?: any;
}) => {
  const navigate = useNavigate();
  if (!data || data.length === 0) return null;
  return (
    <div className="">
      <div
        className={cn(
          "flex justify-between items-center mq450:mb-[30px] mq1000:mb-10 mb-[60px]",
          filterOption?.query?.category !== "" && " mb-[0px]",
        )}
      >
        <ToolTipText title={type} />
        {/* <h1 className='text-[32px] leading-[32px] font-normal'>Objects</h1> */}
        {filterOption?.query?.category === "" ? (
          <Button
            className="text-sm mq450:text-xs font-medium underline bg-transparent mq750:text-xs mq1000:px-0"
            variant="link"
            size="sm"
            onClick={() => handleCategory(type)}
          >
            VIEW ALL
          </Button>
        ) : (
          <div className="mq1000:block hidden">
            <ArtFilters />
          </div>
        )}
      </div>
      <div className="mq1000:hidden block ">
        {filterOption?.query?.category !== "" && <ArtFilters />}
      </div>
      <div>
        {type === "objects" && (
          <div className="grid grid-cols-5 mq1000:grid-cols-3 mq675:grid-cols-2 mq1125:grid-cols-4 mq1000:gap-8 mq450:gap-4 gap-6">
            {data?.map((item: any) => (
              <ObjectCards key={item.id} data={item} />
            ))}
          </div>
        )}

        {type === "artists" && (
          <div className="">
            {data?.map((item: any, i: number) => (
              <div className="w-full relative mq450:mb-10 mq1000:mb-20 mb-[60px]">
                <div className="relative">
                  <div className="mq1000:flex mq1000:w-full mq1000:justify-between">
                    <div className="flex items-center mq1000:gap-6 w-full mq1000:justify-start mq450:justify-between justify-between">
                      <div
                        className="flex items-center gap-[10px]"
                        onClick={() => navigate(`/artist/detail/${item._id}`)}
                      >
                        <Avatar
                          onClick={() => navigate(`/artist/detail/${item._id}`)}
                          className="cursor-pointer"
                        >
                          <AvatarImage src={item.profilePic} alt="" />
                          <AvatarFallback>{item.name[0]}</AvatarFallback>
                        </Avatar>
                        <div className="">
                          <p className="max-w-[30vw] text-[16px] font-normal capitalize truncate">
                            {item.name}
                          </p>
                          <p className="text-sm text-gray-60">
                            b. {format(item?.dob ?? new Date(), "yyyy")}
                          </p>
                        </div>
                      </div>
                      <FollowArtistButton
                        id={item._id}
                        className="rounded-[33px] w-[100px] bg-transparent border border-solid border-[#cfcfcf] text-[#1d1d1d]"
                      />
                    </div>
                    <Button
                      className="text-sm mq450:hidden mq1000:block hidden mq450:text-xs font-medium underline bg-transparent mq750:text-xs mq1000:px-0"
                      variant="link"
                      size="sm"
                      onClick={() => navigate(`/artist/detail/${item._id}`)} // redirect to artist detail page
                    >
                      VIEW ALL
                    </Button>
                  </div>
                  {item.artworks && item.artworks.length > 0 && (
                    <div className="flex w-[calc(100vw-50px)] pr-5 mt-10 mq450:mt-6 overflow-x-scroll gap-[24px]">
                      {item.artworks.map((artwork: any) => (
                        <ObjectCards
                          key={artwork.id}
                          data={artwork}
                          type={type}
                        />
                      ))}
                    </div>
                  )}
                  <Button
                    className="text-sm mq450:block mt-3 hidden mq450:text-xs font-medium underline bg-transparent mq750:text-xs mq1000:px-0"
                    variant="link"
                    size="sm"
                    onClick={() => navigate(`/artist/detail/${item._id}`)} // redirect to artist detail page
                  >
                    VIEW ALL
                  </Button>
                </div>
                {i !== data.length - 1 && (
                  <div className="relative">
                    <div className="h-px w-screen mq450:hidden mq1000:block hidden border-b border-solid border-[#e5e5e5] absolute -left-12 top-10"></div>
                  </div>
                )}
              </div>
            ))}
          </div>
        )}

        {type === "businesses" && (
          <div className="grid mq1000:grid-cols-2 mq675:grid-cols-1 grid-cols-3 gap-[24px]">
            {data?.map((item: any) => (
              <BusinessCard key={item.id} data={item} />
            ))}
            {data?.map((item: any) => (
              <BusinessCard key={item.id} data={item} />
            ))}
          </div>
        )}

        {type === "events" && (
          <div className="grid mq1000:grid-cols-2 mq675:grid-cols-1 grid-cols-3 gap-[24px]">
            {data.map((item: any) => (
              <div key={item._id} className="h-[354px] relative">
                <div className="font-sh5 absolute z-10 p-1.5 px-3 bg-[#eeeeea] text-[#696969] rounded-[15px] flex justify-center items-center text-dimgray text-[12px] font-normal leading-[120%] right-[10px] top-[215px] ">
                  {item.category}
                </div>
                <img
                  src={item.image}
                  alt={item.eventName}
                  className="w-full h-full max-h-[254px] object-cover bg-[#EEEEEA] relative"
                />

                <div className="grid gap-1 mt-3 max-sm:mt-0">
                  <h4 className="font-sh5 flex items-center justify-between text-base leading-[22.4px] font-normal capitalize">
                    {item.eventName}
                  </h4>
                  <p className="font-sh5 text-sm text-gray-60 leading-[16.8px]">
                    {item.country}
                  </p>
                  <p className="font-sh5 text-sm text-gray-60 leading-[16.8px]">
                    {" "}
                    {format(new Date(item.startDate), "MMM dd, yyyy")} -{" "}
                    {format(new Date(item.endDate), "MMM dd, yyyy")}
                  </p>
                  <p className="font-sh5 text-sm text-gray-60 leading-[16.8px]">
                    {" "}
                    {format(new Date(item.startDate), "hh:mm a")} -{" "}
                    {format(new Date(item.endDate), "hh:mm a")}
                  </p>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
      <div className="mq450:block hidden mq450:mt-5">
        <Button
          className="text-xs leading-[14.4px] w-full font-sh5 font-medium bg-transparent rounded-full text-center"
          variant="outline"
          size="sm"
          onClick={() => handleCategory(type)}
        >
          VIEW ALL
        </Button>
      </div>
    </div>
  );
};

export default DisplayObjects;
