import AddBoardDropDown from "@/components/Boards/AddItemDropDown";
import Image from "@/components/Global/Image";

import { Button } from "@/components/ui/button";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { Artist } from "@/types/atrists.type";
import { format } from "date-fns";
import React, { useEffect, useRef, useState, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import Slider from "react-slick";
import { FollowArtistButton } from "./BigBanner";
import { getId } from "@/lib/helper";
import LongPressPreivew from "@/modal/LongPressPreivew";
import { cn } from "@/lib/utils";

interface Props {
  data: Artist[];
  title: string;
  type?: "3x" | "big";
  sectionId?: string;
  heading?: string;
  templateId: number;
}

const Big = ({
  data,
  title,
  type = "big",
  sectionId,
  heading,
  templateId,
}: Props) => {
  const sliderRef = useRef<Slider | null>(null);

  const next = () => {
    sliderRef.current?.slickNext();
  };

  const previous = () => {
    sliderRef.current?.slickPrev();
  };

  const containerRef = useRef<HTMLDivElement>(null);
  const [slidesToShow, setSlidesToShow] = useState(2);
  const navigate = useNavigate();
  const updateSlidesToShow = (width: number) => {
    if (width >= 1024) {
      setSlidesToShow(type === "3x" ? 3 : 4);
    } else if (width >= 400) {
      setSlidesToShow(3);
    } else {
      setSlidesToShow(1);
    }
  };

  useEffect(() => {
    const container = containerRef.current;
    if (!container) return;

    const resizeObserver = new ResizeObserver((entries) => {
      for (let entry of entries) {
        updateSlidesToShow(entry.contentRect.width);
      }
    });

    resizeObserver.observe(container);
    return () => resizeObserver.disconnect();
  }, [type]);
  let dragging = false;
  const settings = useMemo(
    () => ({
      infinite: type === "big",
      speed: 500,
      slidesToShow,
      slidesToScroll: 1,
      arrows: false,
      centerMode: type === "big" || window.innerWidth < 1024, //should be big or the device width is small
      centerPadding: "10%",
      beforeChange: () => (dragging = true),
      afterChange: () => (dragging = false),
    }),
    [slidesToShow],
  );

  const renderArtistCard = (item: Artist | number, index: number) => {
    const isPlaceholder = typeof item === "number";
    const artist = isPlaceholder ? null : item;

    return (
      <div
        key={isPlaceholder ? index : artist?._id}
        className="relative pr-[20px] group"
        // onClick={(e) => }
      >
        <div className="relative">
          <div className="relative overflow-hidden aspect-square">
            {isPlaceholder || !artist?.profilePic ? (
              <div className="w-full h-full bg-gradient-to-b from-gray-200 to-black" />
            ) : (
              <>
                <LongPressPreivew data={artist as any} type="artist">
                  <Image
                    src={artist.profilePic}
                    alt="profile pic"
                    className="object-cover w-full h-full cursor-pointer"
                    loading="lazy"
                  />
                  <div
                    className="absolute top-0 left-0 w-full h-full bg-gradient-to-t from-gray-100 to-70% cursor-pointer"
                    onClick={() => {
                      if (dragging) return;
                      navigate(`/artist/detail/${artist._id}`);
                    }}
                  ></div>
                </LongPressPreivew>
              </>
            )}
          </div>
          <AddBoardDropDown
            itemID={getId(artist)}
            boardID={""}
            itemType={"artist"}
          >
            <span className="absolute top-4 bg-white text-other-cta rounded-full right-4 p-[5.5px_12px] text-sm flex gap-x-1 opacity-0 scale-95 group-hover:opacity-100 group-hover:scale-100 invisible group-hover:visible cursor-pointer transition-all duration-300 ease-in-out items-center">
              <img src="/label.svg" alt="label" /> Board
            </span>
          </AddBoardDropDown>
          <div className="absolute left-0 flex items-center justify-between w-full px-[12px] text-white bottom-[5%] md:bottom-[7.5%] lg:bottom-4">
            <div>
              <h1 className="text-5xl font-normal tracking-wide capitalize font-eb leading-[28px]">
                {isPlaceholder ? "Artist Name" : artist?.name}
              </h1>
              <p className="text-xs leading-[14px] mt-2 text-[#cccccc]">
                {isPlaceholder
                  ? "b. date"
                  : artist?.dob && "b. " + format(artist.dob, "yyyy")}
              </p>
            </div>
            <FollowArtistButton id={artist?._id ?? ""} />
          </div>
        </div>
        <div className="bg-bg-5 p-[16px_12px]">
          <p className="text-sm text-gray-60 line-clamp-3 leading-[16.8px]">
            {/* {isPlaceholder ? "Artist Description" : artist} */}
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Nisi
            aliquam quas facilis a. Expedita, quod quam! Excepturi, dolor iure
            temporibus, ex atque exercitationem ipsum possimus sit ullam, ea
            provident necessitatibus.
          </p>
          <div className="flex mt-[24px] gap-1 justify-between">
            {(isPlaceholder
              ? [1, 2, 3]
              : artist?.gallery?.slice(0, 3) || []
            ).map((galleryItem, galleryIndex) => (
              <div
                key={
                  isPlaceholder
                    ? galleryIndex
                    : typeof galleryItem !== "number"
                      ? galleryItem._id
                      : undefined
                }
                className="relative overflow-hidden bg-white aspect-square"
              >
                {!isPlaceholder && typeof galleryItem !== "number" && (
                  <Image
                    src={galleryItem.image}
                    alt={galleryItem.objectName}
                    className="object-cover w-full h-full max-h-[276px] cursor-pointer max-w-[276px]"
                    onClick={() =>
                      navigate(`/objects/detail/${getId(galleryItem)}`)
                    }
                  />
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="font-sh5" ref={containerRef}>
      <div className="mb-8 mq750:mb-6">
        <div className="flex items-center justify-between">
          <ToolTipText title={title} heading={heading} />
          {templateId !== 3 && (
            <div className="flex flex-row items-center">
              <Button
                className="text-sm font-medium underline bg-transparent mq750:text-xs mq1000:px-0"
                variant="link"
                size="sm"
                onClick={() => navigate(`/artist/view/${sectionId}`)}
              >
                VIEW ALL
              </Button>
              <div className="lg:flex flex-row items-center gap-[5px] hidden">
                {[true, false].map((flip, index) => (
                  <img
                    key={index}
                    className="p-[3px] bg-[#EEEEEA] rounded-[100%] h-[26px] w-[26px] cursor-pointer"
                    style={flip ? { transform: "scaleX(-1)" } : {}}
                    src="/arrow-right.svg"
                    alt={flip ? "Previous" : "Next"}
                    onClick={flip ? previous : next}
                  />
                ))}
              </div>
            </div>
          )}
        </div>
        <p className="text-[#696969] text-[14px] mq450:text-[12px] leading-[16.8px] mq450:leading-[14.4px] pt-2 mq450:pt-1">
          Follow more artists to improve your recommendations{" "}
        </p>
      </div>

      <Slider {...settings} ref={sliderRef}>
        {(data.length <= 1 ? [1, 2, 3, 4, 5] : data).map((item, index) =>
          renderArtistCard(item, index),
        )}
      </Slider>
    </div>
  );
};

export default Big;

export const ToolTipText = ({
  title,
  heading,
  className,
}: {
  title: string;
  heading?: string;
  className?: string;
}) => {
  return (
    <h3
      className={cn(
        "text-[28px] mq450:text-[24px] font-normal capitalize leading-[33.6px] truncate max-w-[60%] tracking-tight opacity-[87%] text-[#1D1D1D]",
        className,
      )}
    >
      {title || "Section Title"}
      {heading && (
        <p className="mt-1 text-sm truncate text-[#696969] leading-[16.8px] max-w-[70%]">
          {heading}
        </p>
      )}
    </h3>
  );
};
