import React, { useState, useEffect } from "react";
import { Toaster } from "react-hot-toast";

interface IconProps {
  size?: string;
}

const ErrorIcon = ({ size = "16px" }: IconProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 512 512"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
  >
    <style>
      {`
        .cls-1 {
          fill: none;
          stroke: #FF0000;
          stroke-linecap: round;
          stroke-linejoin: round;
          stroke-width: 20px;
        }
      `}
    </style>
    <g data-name="Layer 2">
      <g data-name="E410, Error, Media, media player, multimedia">
        <circle className="cls-1" cx="256" cy="256" r="246" />
        <line
          className="cls-1"
          x1="371.47"
          y1="140.53"
          x2="140.53"
          y2="371.47"
        />
        <line
          className="cls-1"
          x1="371.47"
          y1="371.47"
          x2="140.53"
          y2="140.53"
        />
      </g>
    </g>
  </svg>
);

const SuccessIcon = ({ size = "16px" }: IconProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9 16.125C12.935 16.125 16.125 12.935 16.125 9C16.125 5.06497 12.935 1.875 9 1.875C5.06497 1.875 1.875 5.06497 1.875 9C1.875 12.935 5.06497 16.125 9 16.125Z"
      stroke="#7AC51C"
      strokeMiterlimit="10"
    />
    <path
      d="M5.625 8.8726L7.9575 11.2126L12.375 6.7876"
      stroke="#7AC51C"
      strokeMiterlimit="10"
    />
  </svg>
);

const ResponsiveToaster = () => {
  const [toastStyles, setToastStyles] = useState<{
    success: React.CSSProperties;
    error: React.CSSProperties;
  }>({
    success: {
      backgroundColor: "#E8FAF0",
      color: "#696969",
      padding: "13.4px 16px",
      width: "100%",
      minWidth: "449px",
      fontSize: "14px",
      wordBreak: "break-all",
      marginBottom: "1rem",
      borderRadius: "0",
      textAlign: "left",
      fontFamily: "Neue Montreal",
      fontWeight: 400,
    },
    error: {
      backgroundColor: "#FAE8E8",
      color: "#696969",
      padding: "13.4px 16px",
      width: "100%",
      minWidth: "449px",
      fontSize: "14px",
      wordBreak: "break-all",
      marginBottom: "1rem",
      borderRadius: "0",
      textAlign: "left",
      fontFamily: "Neue Montreal",
      fontWeight: 400,
    },
  });

  useEffect(() => {
    const updateStyles = () => {
      if (window.innerWidth < 600) {
        setToastStyles({
          success: {
            backgroundColor: "#E8FAF0",
            color: "#1d1d1d",
            padding: "10px",
            width: "90%",
            minWidth: "auto",
            fontSize: "12px",
            marginBottom: "0.5rem",
            wordWrap: "break-word", // Use this instead of wordBreak
            overflowWrap: "break-word", // Additional safety for word wrapping
            borderRadius: "0px",
            textAlign: "center",
          },
          error: {
            backgroundColor: "#FAE8E8",
            color: "#1d1d1d",
            padding: "10px",
            width: "90%",
            minWidth: "auto",
            fontSize: "12px",
            wordWrap: "break-word", // Use this instead of wordBreak
            overflowWrap: "break-word", // Additional safety for word wrapping
            marginBottom: "0.5rem",
            borderRadius: "0px",
            textAlign: "center",
          },
        });
      } else {
        setToastStyles({
          success: {
            backgroundColor: "#E8FAF0",
            color: "#1d1d1d",
            padding: "13.4px 16px",
            width: "100%",
            minWidth: "449px",
            fontSize: "14px",
            marginBottom: "1rem",
            borderRadius: "0",
            textAlign: "left",
          },
          error: {
            backgroundColor: "#FAE8E8",
            color: "#1d1d1d",
            padding: "13.4px 16px",
            width: "100%",
            minWidth: "449px",
            fontSize: "14px",
            marginBottom: "1rem",
            borderRadius: "0",
            textAlign: "left",
          },
        });
      }
    };

    window.addEventListener("resize", updateStyles);
    updateStyles(); // Initial setup

    return () => window.removeEventListener("resize", updateStyles);
  }, []);

  return (
    <Toaster
      position="bottom-center"
      toastOptions={{
        success: {
          duration: 3000,
          icon: <SuccessIcon size={"14px"} />,
          style: {
            ...toastStyles.success,
            fontFamily: "Neue Montreal",
            fontWeight: 400,
          },
        },
        error: {
          duration: 3000,
          icon: <ErrorIcon size={"14px"} />,
          style: {
            ...toastStyles.error,
            fontFamily: "Neue Montreal",
            fontWeight: 400,
          },
        },
        loading: {
          style: {
            fontSize: "14px",
            borderRadius: "0px",
            fontFamily: "Neue Montreal",
            fontWeight: 400,
          },
        },
      }}
    />
  );
};

export default ResponsiveToaster;
