import React, { useEffect, useMemo, Suspense, lazy } from "react";
import {
  Routes,
  Route,
  useNavigationType,
  useLocation,
  useNavigate,
} from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "react-toastify/dist/ReactToastify.css";
import "slick-carousel/slick/slick-theme.css";
import toast, { Toaster } from "react-hot-toast";
import ProtectedLayout from "./layout/ProtectedLayout";
import Discover from "./pages/Discover";
import Objects from "./pages/Objects";
import Artists from "./pages/Artists";
import Businesses from "./pages/Businesses";
import Events from "./pages/Events";
import ObjectContextProvider from "./context/objects";
import { BoardsProvider } from "./context/boardsContext";
import PreloginLandingPage from "./pages/Pre-login/PreloginLandingPage";
import { ViewPageContextProvider } from "./context/useViiewPageContext";
import useToasterPosition from "./hooks/toastPosition";
import Loader from "./components/Global/Loader";
import TermsAndConditions from "./pages/Pre-login/TermsAndConditions";
import PrivacyPolicy from "./pages/Pre-login/PrivacyPolicy";
import SearchPage from "./components/Search";
import Artist from "./pages/Pre-login/Artist";
import Business from "./pages/Pre-login/Business";
import Collectors from "./pages/Pre-login/Collectors";
import Everyone from "./pages/Pre-login/Everyone";
import Contact from "./pages/Pre-login/Contact";
import { useAuth } from "./hooks/useAuth";
import ResponsiveToaster from "./ResponsiveToaster";

const View = lazy(() => import("./pages/view"));
const ArtistDetail = lazy(() => import("./pages/detail/ArtistDetail"));
const BusinessDetail = lazy(() => import("./pages/detail/BusinessDetail"));
const EventDetail = lazy(() => import("./pages/detail/EventDetail"));
const WritingDetail = lazy(() => import("./pages/detail/WritingDetail"));
const ObjectDetail = lazy(() => import("./pages/detail/ObjectDetail"));
const Locationdetail = lazy(() => import("./pages/view/Locationdetail"));
const Boards = lazy(() => import("./pages/Boards"));
const SingleBoardPage = lazy(() => import("./pages/SingleBoardPage"));
const Detail = lazy(() => import("./pages/catalog/Detail"));
const ArtworkDisplay = lazy(() => import("./pages/catalog/ArtworkDisplay"));
const RequestSucces = lazy(() => import("./pages/catalog/RequestSucces"));
const MultiStepForm = lazy(
  () => import("./pages/RegistrationForm/MultiStepForm"),
);
const Profile = lazy(() => import("./pages/profile"));
const AddObjectForm = lazy(() => import("./pages/add-object"));
const AsignRequest = lazy(() => import("./pages/catalog/AsignRequest"));
const AsignTimePage = lazy(() => import("./pages/catalog/AsignTimePage"));
const LoginForm = lazy(() => import("./pages/LoginForm/LoginForm"));
const ForgotPassword = lazy(() => import("./pages/LoginForm/ForgetPassword"));
const AccountProfile = lazy(() => import("./pages/account-profile"));
const AddImageGuidelines = lazy(() => import("./pages/AddImageGuidelines"));

function App() {
  const toasterPosition = useToasterPosition();
  const action = useNavigationType();
  const location = useLocation();
  const pathname = location.pathname;
  const navigate = useNavigate();

  useEffect(() => {
    if (action !== "POP") {
      window.scrollTo(0, 0);
    }
  }, [action, pathname]);

  useEffect(() => {
    // Define titles and meta descriptions for each route
    const routesMeta: {
      [key: string]: { title: string; description: string };
    } = {
      "/discover/feed": {
        title: "Discover | Feed",
        description: "Welcome to our home page",
      },
      "/objects/feed": {
        title: "Objects | Feed",
        description: "Explore our objects",
      },
      "/artist/feed": {
        title: "Artists | Feed",
        description: "Discover artists",
      },
      "/businesses": {
        title: "Businesses | Feed",
        description: "Find businesses",
      },
      "/events/feed": {
        title: "Events | Feed",
        description: "Upcoming events",
      },
      "/boards": {
        title: "Boards",
        description: "Explore Boards",
      },
    };

    let title = "Asign.art";
    let metaDescription = "Asign.art";

    // Handle dynamic routes like "/boards/:boardId" or "/artist/detail/:id"
    if (/^\/boards\/\w+$/.test(pathname)) {
      title = "Board | Explore";
      metaDescription = "Explore this board";
    } else if (/^\/artist\/detail\/\w+$/.test(pathname)) {
      title = "Artist | Detail";
      metaDescription = "Learn more about this artist";
    } else if (/^\/business\/\w+$/.test(pathname)) {
      title = "Business | Detail";
      metaDescription = "Learn more about this business";
    } else if (/^\/events\/detail\/\w+$/.test(pathname)) {
      title = "Event | Detail";
      metaDescription = "Learn more about this event";
    } else if (/^\/objects\/detail\/\w+$/.test(pathname)) {
      title = "Objects | Detail";
      metaDescription = "Learn more about this object";
    } else if (routesMeta[pathname]) {
      // Check if the path is an exact match in the static map
      title = routesMeta[pathname].title;
      metaDescription = routesMeta[pathname].description;
    }

    // Update the document's title and meta description
    document.title = title;

    const metaDescriptionTag = document.querySelector<HTMLMetaElement>(
      'head > meta[name="description"]',
    );
    if (metaDescriptionTag) {
      metaDescriptionTag.content = metaDescription;
    } else {
      // Create meta description tag if it doesn't exist
      const metaTag = document.createElement("meta");
      metaTag.name = "description";
      metaTag.content = metaDescription;
      document.head.appendChild(metaTag);
    }
  }, [pathname]);

  useEffect(() => {
    if (pathname === "/") {
      navigate("/discover/feed");
      // navigate("/landing-home"); after prelogin pages completed it will be enabled.
    }
  }, [pathname, navigate]);

  //prevent the user from opening the right click menu
  // useEffect(() => {
  //   const handleContextmenu = (e: any) => {
  //     e.preventDefault();
  //   };
  //   document.addEventListener("contextmenu", handleContextmenu);
  //   return function cleanup() {
  //     document.removeEventListener("contextmenu", handleContextmenu);
  //   };
  // }, []);

  return (
    <>
      <Suspense fallback={<Loader />}>
        <Routes>
          <Route
            path="/discover/feed"
            element={
              <ProtectedLayout>
                {useMemo(
                  () => (
                    <Discover />
                  ),
                  [],
                )}
              </ProtectedLayout>
            }
          />
          <Route path="/register" element={<MultiStepForm />} />
          <Route path="/login" element={<LoginForm />} />
          <Route path="/reset" element={<ForgotPassword />} />
          <Route
            path="/profile"
            element={
              <ProtectedLayout>
                <Profile />
              </ProtectedLayout>
            }
          />
          <Route
            path="/boards"
            element={
              <ProtectedLayout>
                <BoardsProvider>
                  <Boards />
                </BoardsProvider>
              </ProtectedLayout>
            }
          />
          <Route
            path="/business/feed"
            element={
              <ProtectedLayout>
                <Businesses />
              </ProtectedLayout>
            }
          />
          <Route
            path="/events/feed"
            element={
              <ProtectedLayout>
                <Events />
              </ProtectedLayout>
            }
          />
          <Route
            path="/boards/:boardId"
            element={
              <ProtectedLayout>
                <BoardsProvider>
                  <SingleBoardPage />
                </BoardsProvider>
              </ProtectedLayout>
            }
          />
          <Route
            path="/:type/view/:id"
            element={
              <ProtectedLayout>
                <ViewPageContextProvider>
                  <View />
                </ViewPageContextProvider>
              </ProtectedLayout>
            }
          />
          <Route
            path="/location/view"
            element={
              <ProtectedLayout>
                <Locationdetail />
              </ProtectedLayout>
            }
          />
          <Route
            path="/artist/detail/:id"
            element={
              <ProtectedLayout>
                <ArtistDetail />
              </ProtectedLayout>
            }
          />
          <Route
            path="/objects/detail/:id"
            element={
              <ProtectedLayout>
                <ObjectDetail />
              </ProtectedLayout>
            }
          />
          <Route
            path="/business/detail/:id"
            element={
              <ProtectedLayout>
                <BusinessDetail />
              </ProtectedLayout>
            }
          />
          <Route
            path="/events/detail/:id"
            element={
              <ProtectedLayout>
                <EventDetail />
              </ProtectedLayout>
            }
          />
          <Route
            path="/writing/detail/:id"
            element={
              <ProtectedLayout>
                <WritingDetail />
              </ProtectedLayout>
            }
          />
          <Route
            path="/objects/feed"
            element={
              <ProtectedLayout>
                <Objects />
              </ProtectedLayout>
            }
          />
          <Route
            path="/artist/feed"
            element={
              <ProtectedLayout>
                <Artists />
              </ProtectedLayout>
            }
          />
          <Route
            path="/catalog/detail"
            element={
              <ProtectedLayout>
                <ObjectContextProvider>
                  <Detail />
                </ObjectContextProvider>
              </ProtectedLayout>
            }
          />
          <Route
            path="/catalog/detail/:id"
            element={
              <ProtectedLayout>
                <div>Hello</div>
              </ProtectedLayout>
            }
          />
          <Route
            path="/catalog/detail/artwork"
            element={
              <ObjectContextProvider>
                <ArtworkDisplay />
              </ObjectContextProvider>
            }
          />
          <Route
            path="/catalog/add/object"
            element={
              <ProtectedLayout>
                <ObjectContextProvider>
                  <AddObjectForm />
                </ObjectContextProvider>
              </ProtectedLayout>
            }
          />
          <Route
            path="/catalog/asign/request"
            element={
              <ProtectedLayout>
                <ObjectContextProvider>
                  <AsignRequest />
                </ObjectContextProvider>
              </ProtectedLayout>
            }
          />
          <Route
            path="/catalog/asign/timeline"
            element={
              <ProtectedLayout>
                <ObjectContextProvider>
                  <AsignTimePage />
                </ObjectContextProvider>
              </ProtectedLayout>
            }
          />
          <Route
            path="/catalog/request/recived"
            element={
              <ProtectedLayout>
                <ObjectContextProvider>
                  <RequestSucces />
                </ObjectContextProvider>
              </ProtectedLayout>
            }
          />
          <Route
            path="/user/profile"
            element={
              <ProtectedLayout>
                <Profile />
              </ProtectedLayout>
            }
          />
          <Route
            path="/account/details"
            element={
              <ProtectedLayout>
                <AccountProfile />
              </ProtectedLayout>
            }
          />

          <Route
            path="/add-image-guidelines"
            element={
              <ProtectedLayout>
                <AddImageGuidelines />
              </ProtectedLayout>
            }
          />
          <Route
            path="/search"
            element={
              <ProtectedLayout>
                <SearchPage />
              </ProtectedLayout>
            }
          />
          <Route path="/landing-home" element={<PreloginLandingPage />} />
          <Route path="/artist" element={<Artist />} />
          <Route path="/business" element={<Business />} />
          <Route path="/collectors" element={<Collectors />} />
          <Route path="/everyone" element={<Everyone />} />
          <Route path="/contact" element={<Contact />} />
          <Route
            path="/terms-and-conditions"
            element={<TermsAndConditions />}
          />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        </Routes>
      </Suspense>
      <div id="toast-container" onClick={() => toast.dismiss()}>
        <ResponsiveToaster />
      </div>
    </>
  );
}

export default App;
