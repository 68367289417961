import { createRoot } from "react-dom/client";
import App from "./App";
import { BrowserRouter, useNavigate } from "react-router-dom";
import {
  CssBaseline,
  ThemeProvider,
  createTheme,
  StyledEngineProvider,
} from "@mui/material";
import "./global.css";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import * as Sentry from "@sentry/react";
import markerSDK from "@marker.io/browser";
import UserContextProvider from "./context/user";
import { AuthProvider } from "./hooks/useAuth";
import React from "react";
import { RegistrationProvider } from "./context/registrationContext";
import { MasterProvider } from "./context/useMaster";

const muiTheme = createTheme();

Sentry.init({
  dsn: "https://72570d4391a0e516443129e523da719d@o4507011191078912.ingest.us.sentry.io/4507696627843072",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.browserProfilingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [
    "https://asign-discover.pages.dev",
    "https://dev.asign-cms.pages.dev",
  ],
  // Profiling
  profilesSampleRate: 1.0, // Profile 100% of the transactions. This value is relative to tracesSampleRate
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0,
});

const container = document.getElementById("root");
const root = createRoot(container!);

async function loadMarkerWidget() {
  const widget = await markerSDK.loadWidget({
    project: "6700c1f031ac2448c7d982f6",
  });
}

loadMarkerWidget();

const queryClient = new QueryClient({
  defaultOptions: {
    // queries: {
    //   retry: (failureCount, error: any) => {
    //     // Don't retry on 401 Unauthorized errors
    //     if (error.response?.status === 401) {
    //       window.location.href =
    //         "https://keycloak-production-928d.up.railway.app/realms/asign/protocol/openid-connect/auth?client_id=asign_client&redirect_uri=http%3A%2F%2Flocalhost%3A5173%2Fdiscover%2Ffeed&state=dc3b559c-3815-4ad0-8004-ca58f93fafba&response_mode=fragment&response_type=code&scope=openid&nonce=13ce2bb6-e239-4a41-8758-8ad1665cbb88&code_challenge=0L79hBzrkvchJ67ehHoPMvkaaIZHKQy09YZzMkvvF4A&code_challenge_method=S256";
    //       return false;
    //     }
    //     // Retry up to 3 times for other errors
    //     return failureCount < 3;
    //   },
    // },
    // mutations: {
    //   onError: (error: any) => {
    //     if (error.response?.status === 401) {
    //       // Redirect to Keycloak login page
    //       window.location.href =
    //         "https://keycloak-production-928d.up.railway.app/realms/asign/protocol/openid-connect/auth?client_id=asign_client&redirect_uri=http%3A%2F%2Flocalhost%3A5173%2Fdiscover%2Ffeed&state=dc3b559c-3815-4ad0-8004-ca58f93fafba&response_mode=fragment&response_type=code&scope=openid&nonce=13ce2bb6-e239-4a41-8758-8ad1665cbb88&code_challenge=0L79hBzrkvchJ67ehHoPMvkaaIZHKQy09YZzMkvvF4A&code_challenge_method=S256";
    //     }
    //   },
    // },
  },
});

root.render(
  // <QueryClientProvider client={queryClient}>
  //   <BrowserRouter>
  //     <AuthProvider>
  //       <UserContextProvider>
  //         <StyledEngineProvider injectFirst>
  //           <ThemeProvider theme={muiTheme}>
  //             <CssBaseline />
  //             <App />
  //           </ThemeProvider>
  //         </StyledEngineProvider>
  //       </UserContextProvider>
  //     </AuthProvider>
  //   </BrowserRouter>
  // </QueryClientProvider>
  <React.StrictMode>
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={muiTheme}>
            <CssBaseline />
            <RegistrationProvider>
              <AuthProvider>
                <UserContextProvider>
                  <MasterProvider>
                    <App />
                  </MasterProvider>
                </UserContextProvider>
              </AuthProvider>
            </RegistrationProvider>
          </ThemeProvider>
        </StyledEngineProvider>
      </QueryClientProvider>
    </BrowserRouter>
  </React.StrictMode>,
);
