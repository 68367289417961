// import React, { useState } from "react";
// import { Skeleton } from "../ui/skeleton";

// const Image = (props: React.ImgHTMLAttributes<HTMLImageElement>) => {
//   const [loading, setLoading] = useState(true);

//   if (!props.src) {
//     return <Skeleton className={props.className} />;
//   }
//   return (
//     <>
//       {loading && <Skeleton className={props.className} />}
//       <img
//         {...props}
//         onLoad={() => setLoading(false)}
//         className={`${loading ? "invisible" : "visible"} ${props.className}`}
//         onContextMenu={(e) => e.preventDefault()}
//         loading="lazy"
//       />
//     </>
//   );
// };

// export default Image;

import React, { useState } from "react";
import { Skeleton } from "../ui/skeleton";

const Image = (props: React.ImgHTMLAttributes<HTMLImageElement>) => {
  const [loading, setLoading] = useState(true);

  return (
    <div className={`relative ${props.className}`}>
      {loading && (
        <img
          {...props}
          loading="eager"
          src="https://www.asign.art/assets/images/pages/my-catalogue/noimage.png"
        />
      )}
      <img
        {...props}
        loading="eager"
        src={props.src ?? "/noimage.png"}
        className={`${props.className} ${
          loading ? "opacity-0" : "opacity-100"
        } transition-opacity`}
        onContextMenu={(e) => e.preventDefault()}
        onLoad={() => setLoading(false)}
        alt={props.alt ?? "Image"}
      />
    </div>
  );
};

export default Image;
