import React, { useEffect } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import Header from "../Global/header/Header";
import FigureSearch, { categories } from "./modal/fingureSearch";
import { Search } from "lucide-react";
import { useMediaQuery } from "@mui/material";
import { Button } from "../ui/button";
import useSearch from "@/apis/search";
import { useQuery } from "@tanstack/react-query";
import useFilter from "@/hooks/useFilter";
import { useDebouncedCallback } from "use-debounce";
import DisplayObjects from "./DisplayObjects";
import Footer2 from "../Global/Footer2";
import { ArtFilters } from "./modal/GlobalFilter";
import Loader from "../Global/Loader";
import { cn } from "@/lib/utils";

const SearchPage = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const search = searchParams.get("q");
  const searchCategroy = searchParams?.get("category");
  const isDesktop = useMediaQuery("(min-width: 1000px)");
  const [searchQuery, setSearchQuery] = React.useState(search || "");

  const { searchData } = useSearch();
  const { filterOption, setFilterOption } = useFilter({
    query: {
      searchText: search,
      category: searchCategroy === "all" ? "" : searchCategroy,
    },
    options: {
      limit: 10,
      page: 1,
    },
  });
  const { isLoading, data } = useQuery({
    queryKey: ["searchData", filterOption],
    queryFn: () => searchData(filterOption),
    enabled: !!filterOption,
  });

  const debounceSearch = useDebouncedCallback((query) => {
    setFilterOption({
      query: {
        searchText: query,
        category: searchCategroy === "all" ? "" : searchCategroy,
      },
      options: {
        limit: 10,
        page: 1,
      },
    });
  }, 500);

  const handleCategory = (category: string) => {
    setFilterOption({
      query: {
        searchText: searchQuery,
        category: category === "all" ? "" : category,
      },
      options: {
        limit: 10,
        page: 1,
      },
    });
  };

  function extractData(data: any) {
    const result: { event?: any[]; [key: string]: any } = { event: [] };

    data?.forEach((item: any) => {
      if (item.eventName) {
        result?.event?.push(item);
      } else if (item.userRole) {
        if (!result[item.userRole]) {
          result[item.userRole] = [];
        }
        result[item.userRole].push(item);
      } else if (item.contentType == "video") {
        if (!result.video) {
          result.video = [];
        }
        result.video.push(item);
      } else {
        if (!result[item.category]) {
          result[item.category] = [];
        }
        result[item.category].push(item);
      }
    });
    return result;
  }
  const [displayData, setDisplayData] = React.useState(extractData(data));
  useEffect(() => {
    setDisplayData(extractData(data));
  }, [data]);
  const [inputFocus, setInputFocus] = React.useState(false);
  return (
    <div>
      <div className="sticky top-0 z-50 bg-white mq1000:hidden">
        <Header />
      </div>
      {isLoading && <Loader />}
      <div
        className={cn(
          "absolute top-0 left-0 h-screen w-screen z-[51] bg-black/50 opacity-0 pointer-events-none transition-opacity duration-100",
          inputFocus && "opacity-100 pointer-events-auto",
        )}
        onClick={() => setInputFocus(false)}
      />
      {isDesktop ? (
        <div
          className={cn(
            "w-[754px] mx-auto space-y-[2px] rounded-[20px] bg-bg-5 mt-10",
            inputFocus && "z-[53] relative",
          )}
        >
          <div className="bg-white rounded-full shadow-sm -translate-y-1 border border-solid border-[#cfcfcf] relative">
            <div className="flex items-center h-12 px-4">
              <Search className="w-5 h-5 stroke-[1px]" />
              <input
                type="text"
                placeholder="Search"
                className="flex-1 ml-3 text-base text-center outline-none"
                defaultValue={search ?? ""}
                onFocus={() => setInputFocus(true)}
                // onBlur={() => setInputFocus(false)}
                // onChange={(e) => debounceSearch(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    debounceSearch((e.target as HTMLInputElement).value);
                    setInputFocus(false);
                    setSearchParams((prev) => {
                      const params = new URLSearchParams(prev);
                      params.set("q", (e.target as HTMLInputElement).value);
                      return params;
                    });
                  }
                }}
              />
            </div>
          </div>
          <div className="flex gap-1 overflow-x-auto pb-[6px] px-10 justify-center mx-2 mt-0">
            {categories.map((item) => (
              <button
                key={item.id}
                className={`px-5 py-[9.5px] rounded-full border border-solid text-sm whitespace-nowrap  ${
                  item.id === (filterOption?.query?.category || "all")
                    ? "bg-black text-white"
                    : "bg-white text-[#696969] border border-gray-200"
                }`}
                onClick={() => handleCategory(item.id)}
              >
                {item.name}
              </button>
            ))}
          </div>
        </div>
      ) : (
        <div className="bg-[#EEEEEA] p-5">
          <div className="flex items-center gap-4">
            <div className="bg-transparent w-full border border-solid border-[#cfcfcf] rounded-full shadow-sm">
              <div className="flex items-center h-12 px-4">
                <Search className="w-5 h-5 stroke-[1px]" />
                <input
                  type="text"
                  placeholder="Search for"
                  className="flex-1 ml-3 text-base bg-transparent outline-none"
                  value={searchQuery}
                  onChange={(e) => {
                    setSearchQuery(e.target.value);
                    debounceSearch(e.target.value);
                  }}
                  id="search-input"
                />
              </div>
            </div>
            <img
              src="/close1.svg"
              alt="Close"
              onClick={() => {
                setSearchQuery("");
                debounceSearch("");
              }}
              style={{ cursor: "pointer" }}
            />
          </div>
          <div className="flex gap-1 pt-4 pb-1 overflow-x-scroll w-[calc(100vw-44px)] px-0.5">
            {categories.map((item) => (
              <button
                key={item.id}
                className={`px-4 py-2 rounded-full text-sm whitespace-nowrap ${
                  item.id === (filterOption?.query?.category || "all")
                    ? "bg-black text-white"
                    : "bg-white text-gray-900 border border-gray-200"
                }`}
                onClick={() => handleCategory(item.id)}
              >
                {item.name}
              </button>
            ))}
          </div>
        </div>
      )}

      <div className="m-12 mq450:m-5 mq450:mt-[39px] mq1000:space-y-[60px] space-y-20 mb-28">
        <DisplayObjects
          data={displayData.objects}
          handleCategory={handleCategory}
          type="objects"
          filterOption={filterOption}
        />
        <DisplayObjects
          data={displayData.artist}
          handleCategory={handleCategory}
          type="artists"
          filterOption={filterOption}
        />
        <DisplayObjects
          data={displayData.business}
          handleCategory={handleCategory}
          type="businesses"
          filterOption={filterOption}
        />
        <DisplayObjects
          data={displayData.event}
          handleCategory={handleCategory}
          type="events"
          filterOption={filterOption}
        />
      </div>
      <Footer2 />
    </div>
  );
};

// const SearchPage = () => {
//   const [searchParams] = useSearchParams();
//   const search = searchParams.get("q");
//   const searchCategroy = searchParams?.get("category");
//   const isDesktop = useMediaQuery("(min-width: 1000px)");
//   const [searchQuery, setSearchQuery] = React.useState(search || "");

//   const { searchData } = useSearch();
//   const { filterOption, setFilterOption } = useFilter({
//     query: {
//       searchText: search,
//       category: searchCategroy === "all" ? "" : searchCategroy,
//     },
//     options: {
//       limit: 10,
//       page: 1,
//     },
//   });

//   const { isLoading, data } = useQuery({
//     queryKey: ["searchData", filterOption],
//     queryFn: () => searchData(filterOption),
//     enabled: !!filterOption,
//   });

//   const debounceSearch = useDebouncedCallback((query) => {
//     setFilterOption({
//       query: {
//         searchText: query,
//         category: searchCategroy === "all" ? "" : searchCategroy,
//       },
//       options: {
//         limit: 10,
//         page: 1,
//       },
//     });
//   }, 500);

//   useEffect(() => {
//     setFilterOption({
//       query: {
//         searchText: search,
//         category: searchCategroy === "all" ? "" : searchCategroy,
//       },
//       options: {
//         limit: 10,
//         page: 1,
//       },
//     });
//   }, [search, searchCategroy])

//   const handleCategory = (category: string) => {
//     setFilterOption({
//       query: {
//         searchText: searchQuery,
//         category: category === "all" ? "" : category,
//       },
//       options: {
//         limit: 10,
//         page: 1,
//       },
//     });
//   };

//   function extractData(data: any) {
//     const result: { event?: any[];[key: string]: any } = { event: [] };

//     data?.forEach((item: any) => {
//       if (item.eventName) {
//         result?.event?.push(item);
//       } else if (item.userRole) {
//         if (!result[item.userRole]) {
//           result[item.userRole] = [];
//         }
//         result[item.userRole].push(item);
//       } else if (item.contentType == "video") {
//         if (!result.video) {
//           result.video = [];
//         }
//         result.video.push(item);
//       } else {
//         if (!result[item.category]) {
//           result[item.category] = [];
//         }
//         result[item.category].push(item);
//       }
//     });
//     return result;
//   }

//   const [displayData, setDisplayData] = React.useState(extractData(data));
//   useEffect(() => {
//     setDisplayData(extractData(data));
//   }, [data]);

//   return (
//     <div>
//       <div className="sticky top-0 z-50 bg-white mq1000:hidden">
//         <Header />
//       </div>
//       {isLoading && <Loader />}
//       {isDesktop ? (
//         <div className="w-[754px] mx-auto space-y-[2px] rounded-[20px] bg-bg-5 mt-10 ">
//           <FigureSearch classname="max-w-[754px] w-[754px] top-5 mx-auto space-y-[2px] rounded-[20px] data-[state=closed]:slide-out-to-top-0 data-[state=open]:slide-in-from-top-0 data-[state=open]:animate-none data-[state=closed]:animate-none data-[state=closed]:zoom-out-100 data-[state=open]:zoom-in-100">
//             <div className="bg-white rounded-full shadow-sm -translate-y-1 border border-solid border-[#cfcfcf]">
//               <div className="flex items-center h-12 px-4">
//                 <Search className="w-5 h-5 stroke-[1px]" />
//                 <input
//                   type="text"
//                   placeholder="Search"
//                   className="flex-1 ml-3 text-base text-center outline-none"
//                   defaultValue={search ?? ""}
//                   onChange={(e) => debounceSearch(e.target.value)}
//                 />
//               </div>
//             </div>
//           </FigureSearch>
//           <div className="flex gap-1 overflow-x-auto pb-[6px] px-10 justify-center mx-2 mt-0">
//             {categories.map((item) => (
//               <button
//                 key={item.id}
//                 className={`px-5 py-[9.5px] rounded-full border border-solid text-sm whitespace-nowrap  ${item.id === (filterOption?.query?.category || "all")
//                   ? "bg-black text-white"
//                   : "bg-white text-[#696969] border border-gray-200"
//                   }`}
//                 onClick={() => handleCategory(item.id)}
//               >
//                 {item.name}
//               </button>
//             ))}
//           </div>
//         </div>
//       ) : (
//         <div className="bg-[#EEEEEA] p-5">
//           <div className="flex items-center gap-4">
//             <div className="bg-transparent w-full border border-solid border-[#cfcfcf] rounded-full shadow-sm">
//               <div className="flex items-center h-12 px-4">
//                 <Search className="w-5 h-5 stroke-[1px]" />
//                 <input
//                   type="text"
//                   placeholder="Search for"
//                   className="flex-1 ml-3 text-base bg-transparent outline-none"
//                   value={searchQuery}
//                   onChange={(e) => {
//                     setSearchQuery(e.target.value);
//                     debounceSearch(e.target.value);
//                   }}
//                   id="search-input"
//                 />
//               </div>
//             </div>
//             <img
//               src="/close1.svg"
//               alt="Close"
//               onClick={() => {
//                 setSearchQuery("");
//                 debounceSearch("");
//               }}
//               style={{ cursor: "pointer" }}
//             />
//           </div>
//           <div className="flex gap-1 pt-4 pb-1 overflow-x-scroll w-[calc(100vw-44px)] px-0.5">
//             {categories.map((item) => (
//               <button
//                 key={item.id}
//                 className={`px-4 py-2 rounded-full text-sm whitespace-nowrap ${item.id === (filterOption?.query?.category || "all")
//                   ? "bg-black text-white"
//                   : "bg-white text-gray-900 border border-gray-200"
//                   }`}
//                 onClick={() => handleCategory(item.id)}
//               >
//                 {item.name}
//               </button>
//             ))}
//           </div>
//         </div>
//       )}

//       <div className="m-12 mq450:m-5 mq450:mt-[39px] mq1000:space-y-[60px] space-y-20 mb-28">
//         <DisplayObjects
//           data={displayData.objects}
//           handleCategory={handleCategory}
//           type="objects"
//           filterOption={filterOption}
//         />
//         <DisplayObjects
//           data={displayData.artist}
//           handleCategory={handleCategory}
//           type="artists"
//           filterOption={filterOption}
//         />
//         <DisplayObjects
//           data={displayData.business}
//           handleCategory={handleCategory}
//           type="businesses"
//           filterOption={filterOption}
//         />
//         <DisplayObjects
//           data={displayData.event}
//           handleCategory={handleCategory}
//           type="events"
//           filterOption={filterOption}
//         />
//       </div>
//       <Footer2 />
//     </div>
//   );
// };

export default SearchPage;
