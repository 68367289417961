import useGlobalApis from "@/apis/global";
import useFilter from "@/hooks/useFilter";
import { useQuery } from "@tanstack/react-query";
import React, { createContext, useContext, useMemo } from "react";
interface MasterContextType {
  getSchema: (
    schemaName: string,
  ) => Promise<Array<{ value: string; label: string }>>;
}
const MasterContext = createContext<MasterContextType>({} as MasterContextType);
export const MasterProvider = ({ children }: { children: React.ReactNode }) => {
  const { getOptions } = useGlobalApis();
  const { filterOption } = useFilter({
    options: {
      populate: "",
      select: ["name"],
    },
  });

  const [fetchedSchemas, setFetchedSchemas] = React.useState<
    Record<string, any>
  >({});

  const fetchSchema = async (schemaName: string) => {
    if (fetchedSchemas[schemaName]) return fetchedSchemas[schemaName];

    const response = await getOptions({
      type: schemaName,
      query: filterOption,
    });

    const transformedData = response?.data?.map((item: any) => ({
      value: item.name,
      label: item.name,
    }));

    setFetchedSchemas((prev) => ({
      ...prev,
      [schemaName]: transformedData,
    }));

    return transformedData;
  };

  const contextValue = useMemo(
    () => ({
      getSchema: fetchSchema,
    }),
    [fetchedSchemas],
  );

  return (
    <MasterContext.Provider value={contextValue}>
      {children}
    </MasterContext.Provider>
  );
};

export const useMaster = () => {
  const context = useContext(MasterContext);

  const useSchema = (schemaName: string) => {
    const [data, setData] = React.useState<any[]>([]);

    React.useEffect(() => {
      const fetchData = async () => {
        const schemaData = await context.getSchema(schemaName);
        setData(schemaData);
      };

      fetchData();
    }, [schemaName]);

    return data;
  };

  return {
    useSchema,
  };
};

// function getModel(schemaName) {
//   const models = {
//     auctionHouse: AuctionHouse,
//     measurementType: MeasurementType,
//     medium: Medium,
//     movement: Movement,
//     objectType: ObjectType,
//     style: Style,
//     surface: Surface,
//     technique: Technique,
//     valuationStatus: ValuationStatus,
//     MAArtsit: MAArtsit,
//     ActionHouse: ActionHouse,
//     Fairs: Fairs,
//     Gallery: Gallery,
//     Transporter: Transporter,
//     VoidReason: VoidReason,
//     AuthenticatorReason: AuthenticatorReason,
//     CoverageType: CoverageType,
//     Inscription: Inscription,
//     ObjectCondition: ObjectCondition,
//     MaGst: MaGst,
//     MaProduct: MaProduct,
//     RejectedReasons: RejectedReasons,
//     RepresentReasons: RepresentReasons,
//     SiteCondition: SiteCondition,
//     SurfaceType: SurfaceType,
//     TimeZones: TimeZones,
//     Period: Period,
//     ExhibitionTypes: ExhibitionTypes,
//     AsignLocation: AsignLocation,
//     City: City,
//     Country: Country,
//     State: State,
//     Price: Price,
//     Shape: Shape,
//     Size: Size,
//     Subject: Subject,
//     AcquisitionType: AcquisitionType,
//     InsuranceType: InsuranceType,
//     ValuationApproach: ValuationApproach,
//     ValuationBasis: ValuationBasis,
//     ValuationType: ValuationType,
//     LabelDamage: LabelDamage,
//     ManufacturerName: ManufacturerName,
//     StockCheckType: StockCheckType,
//     StockTransferReasons: StockTransferReasons,
//     Years: Years,
//     Currency: Currency,
//     AdvisoryServiceList: AdvisoryServiceList,
//     MACategory: MACategory,
//     ConditionObservation: ConditionObservation,
//     DocumentType: DocumentType,
//     Era: Era,
//   };
//   return models[schemaName];
// }
