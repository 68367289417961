import React from "react";
import { useState } from "react";

const Featured = () => {
  const [activeTab, setActiveTab] = useState("artists");

  const tabs = ["artists", "artworks", "galleries"];
  const content: { [key: string]: { name: string; image: string }[] } = {
    artists: [
      {
        name: "Zarina Hashmi",
        image:
          "https://www.asign.art/assets/images/pages/home/featured/artists/Zarina_Hashmi.jpg",
      },
      {
        name: "Sayed Haider Raza",
        image:
          "https://www.asign.art/assets/images/pages/home/featured/artists/Sayed_Haider_Raza.jpg",
      },
      {
        name: "Madhvi Parekh",
        image:
          "https://www.asign.art/assets/images/pages/home/featured/artists/Madhvi_Parekh.jpg",
      },
      {
        name: "Ram Kumar",
        image:
          "https://www.asign.art/assets/images/pages/home/featured/artists/Ram_Kumar.jpg",
      },
      {
        name: "Natvar Bhavsar",
        image:
          "https://www.asign.art/assets/images/pages/home/featured/artists/Natvar_bhasar.jpg",
      },
      {
        name: "Manohar Mhatre",
        image:
          "https://www.asign.art/assets/images/pages/home/featured/artists/Manahor_Mhatre.jpg",
      },
      {
        name: "Anjolie Ela Menon",
        image:
          "https://www.asign.art/assets/images/pages/home/featured/artists/Anjolie_Ela_Menon.jpg",
      },
      {
        name: "Krishen Khanna",
        image:
          "https://www.asign.art/assets/images/pages/home/featured/artists/krishen_khanna.jpg",
      },
      {
        name: "Asma Menon",
        image:
          "https://www.asign.art/assets/images/pages/home/featured/artists/Asma_Menon.jpg",
      },
      {
        name: "Jogen Chowdhury",
        image:
          "https://www.asign.art/assets/images/pages/home/featured/artists/Jogen_Chowdhury.jpg",
      },
      {
        name: "Shanthi Kasi",
        image:
          "https://www.asign.art/assets/images/pages/home/featured/artists/Shanthi_Kasi.jpg",
      },
      {
        name: "C Krishnaswamy",
        image:
          "https://www.asign.art/assets/images/pages/home/featured/artists/C_Krishnaswamy.jpg",
      },
    ],
    artworks: [
      {
        name: "Untitled",
        image:
          "https://www.asign.art/assets/images/pages/home/featured/artworks/1.webp",
      },
      {
        name: "Untitled (Farmer Series)",
        image:
          "https://www.asign.art/assets/images/pages/home/featured/artworks/2.webp",
      },
      {
        name: "Untitled",
        image:
          "https://www.asign.art/assets/images/pages/home/featured/artworks/3.webp",
      },
      {
        name: "Untitled",
        image:
          "https://www.asign.art/assets/images/pages/home/featured/artworks/4.webp",
      },
      {
        name: "Untitled",
        image:
          "https://www.asign.art/assets/images/pages/home/featured/artworks/5.webp",
      },
      {
        name: "Untitled",
        image:
          "https://www.asign.art/assets/images/pages/home/featured/artworks/6.webp",
      },
      {
        name: "Untitled",
        image:
          "https://www.asign.art/assets/images/pages/home/featured/artworks/7.webp",
      },
      {
        name: "Untitled",
        image:
          "https://www.asign.art/assets/images/pages/home/featured/artworks/8.webp",
      },
      {
        name: "Untitled",
        image:
          "https://www.asign.art/assets/images/pages/home/featured/artworks/9.webp",
      },
      {
        name: "Leader's Perch",
        image:
          "https://www.asign.art/assets/images/pages/home/featured/artworks/10.webp",
      },
      {
        name: "Untitled",
        image:
          "https://www.asign.art/assets/images/pages/home/featured/artworks/11.webp",
      },
      {
        name: "Untitled (Two Sisters)",
        image:
          "https://www.asign.art/assets/images/pages/home/featured/artworks/12.webp",
      },
    ],
    galleries: [
      {
        name: "AICON",
        image:
          "https://www.asign.art/assets/images/pages/home/featured/gallery/1.png",
      },
      {
        name: "Artworld",
        image:
          "https://www.asign.art/assets/images/pages/home/featured/gallery/2.png",
      },
      {
        name: "Ashvita’s",
        image:
          "https://www.asign.art/assets/images/pages/home/featured/gallery/3.png",
      },
      {
        name: "Crayon Art Gallery",
        image:
          "https://www.asign.art/assets/images/pages/home/featured/gallery/4.png",
      },
      {
        name: "Chatterjee & Lal",
        image:
          "https://www.asign.art/assets/images/pages/home/featured/gallery/5.png",
      },
      {
        name: "DAG",
        image:
          "https://www.asign.art/assets/images/pages/home/featured/gallery/6.png",
      },
    ],
  };

  return (
    <section className="2xl:container pt-[100px] max-md:pt-[60px] max-2xl:px-[48px] max-md:px-[20px] ">
      <h2 className="text-center text-[24px] max-md:text-[14px] max-md:leading-[120%] max-md:mb-[12px] leading-[100%] tracking-0 text-[#1D1D1D] font-normal mb-[28px]">
        Featured
      </h2>

      <ul className="flex justify-center gap-[40px] list-none space-x-4 mb-[80px] max-md:mb-[40px] max-md:px-[20px]">
        {tabs.map((tab) => (
          <li
            key={tab}
            className={`flex flex-col items-center w-[calc(100%/3-20px)] max-md:w-[calc(100%+20px)]  ${
              activeTab === tab ? "bdr-bottom" : ""
            }`}
          >
            <button
              onClick={() => setActiveTab(tab)}
              className={`py-[32px] max-md:py-[16px] text-[48px] max-md:text-[20px] max-md:leading-[120%] tracking-0 leading-[100%] text-[#1D1D1D]  ${
                activeTab === tab ? "" : ""
              }`}
            >
              {tab.charAt(0).toUpperCase() + tab.slice(1)}
            </button>
          </li>
        ))}
      </ul>

      <div className="grid grid-cols-[repeat(6,120px)]    pr-5 max-md:gap-y-8 max-md:gap-x-5 lg:grid-cols-6 overflow-hidden overflow-x-auto   gap-y-[60px] gap-x-[40px]">
        {content[activeTab].map((item, index) => (
          <div key={index} className="text-center">
            <div className="  ">
              <img
                src={item.image}
                alt={item.name}
                className={`object-cover  max-md:max-[120px] max-md:max-h-[135px] 2xl:max-w-[233px] max-2xl:max-w-[183px] lg:max-w-[164px] max-lg:max-w-[160px] max-2xl:max-h:[209px] max-h-[262px]
 ${activeTab === "artists" ? "grayscale" : ""}`}
              />
            </div>
            <p className="mt-2 text-[16px] text-start  font-normal leading-[120%] tracking-0 text-[#696969]">
              {item.name}
            </p>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Featured;
