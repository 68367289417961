import { cn } from "@/lib/utils";
import React from "react";

const Skeleton = React.memo(function Skeleton({
  className,
  ...props
}: React.HTMLAttributes<HTMLDivElement>) {
  return (
    <div
      className={cn(
        "animate-pulse rounded-md bg-gray-20 dark:bg-gray-800",
        className,
      )}
      {...props}
    />
  );
});

export { Skeleton };
