import { apis } from ".";

const useCountryState = () => {
  const getCountries = async (): Promise<any> => {
    try {
      const { data } = await apis.get(
        `https://api.countrystatecity.in/v1/countries`,
        {
          headers: {
            "X-CSCAPI-KEY":
              "SEhmeUZIT0x0QzJ0V3c3ZXZjQWxOdDUwcWNyNmo3bGhVR1FvNVlkTg==",
          },
        }
      );
      let sortedData = data.sort((a: any, b: any) =>
        a.name.localeCompare(b.name)
      );
      return sortedData;
    } catch (error) {
      throw error;
    }
  };

  const getStates = async (country: string): Promise<any> => {
    try {
      const { data } = await apis.get(
        `https://api.countrystatecity.in/v1/countries/${country}/states`,
        {
          headers: {
            "X-CSCAPI-KEY":
              "SEhmeUZIT0x0QzJ0V3c3ZXZjQWxOdDUwcWNyNmo3bGhVR1FvNVlkTg==",
          },
        }
      );
      let sortedData = data.sort((a: any, b: any) =>
        a.name.localeCompare(b.name)
      );
      return sortedData;
    } catch (error) {
      throw error;
    }
  };

  return { getCountries, getStates };
};

export default useCountryState;
