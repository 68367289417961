import React from "react";
import { FunctionComponent } from "react";

const Footer2: FunctionComponent = () => {
  return (
    <footer className="mq1000:pt-[25px] pl-5 mq1000:pb-[10px] py-10 mq450:pl-1 font-sh5">
      <div className="container mx-auto">
        <div className="flex flex-wrap items-center justify-center text-sm border-solid divide-x mq450:justify-start gap-y-3 text-gray-60 divide-gray-60">
          <div className="flex items-center justify-center text-sm border-solid divide-x mq450:justify-start gap-y-3 text-gray-60 divide-gray-60">
            <a
              href="/terms-and-conditions/"
              target="_blank"
              className="hover:text-gray-700 leading-[14px]  border-solid mr-2 whitespace-nowrap"
            >
              Terms & Conditions
            </a>
            <a
              href="/privacy-policy"
              target="_blank"
              className="hover:text-gray-700 leading-[14px]  border-solid  px-2 [@media(max-width:353px)]:pr-5 whitespace-nowrap"
            >
              Privacy Policy
            </a>
            <a
              href="/contact"
              target="_blank"
              className="hover:text-gray-700 leading-[14px]  border-solid  px-2 [@media(max-width:716px)]:pr-4 [@media(max-width:352px)]:border-hidden [@media(max-width:352px)]:pl-0 whitespace-nowrap"
            >
              Contact Us
            </a>
          </div>
          <p className="hover:text-gray-700 leading-[14px] mq450:text-left border-solid  [@media(max-width:716px)]:pl-0 [@media(max-width:716px)]:border-hidden [@media(min-width:1000px)]:border-hidden [@media(min-width:1046px)]:border-solid px-2 text-gray-60">
            © 2024 Democrart Technologies Private Limited
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer2;
