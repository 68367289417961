import Header from "./Components/Header";
import React from "react";
import Footer from "./Components/Footer";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { Button } from "@/components/ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";

const formSchema = z.object({
  fullName: z.string().min(2, "Full name must be at least 2 characters"),
  email: z.string().email("Invalid email address"),
  phone: z.string().length(10, "Phone number must be exactly 10 digits"),
  comments: z.string().min(2, "Comments must be at least 2 characters"),
});

export default function Contact() {
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      phone: "",
      fullName: "",
      email: "",
      comments: "",
    },
  });

  const onSubmit = (data: z.infer<typeof formSchema>) => {
    console.log(data);
  };

  return (
    <div className="font-sh5">
      <Header className="md:!mt-[-65px] max-md:!mt-[-40px]" />

      <div className="flex max-md:flex-col max-md:px-[20px] md:pt-[120px] max-md:pt-[120px] container mt-16 mb-16 max-md:mb-[40px] max-md:mt-[40px] justify-center">
        <div className="lg:w-1/4 pr-[40px]">
          <h1 className="text-[24px] max-md:text-[20px] font-normal leading-[120%] tracking-normal">
            Request a callback
          </h1>
          <p className="text-[18px] max-md:text-[16px] leading-[120%] tracking-normal mt-[12px] text-[#696969]">
            Stuck somewhere or need clarity?
            <br />
            Help us get in touch with you.
          </p>
        </div>
        <div className="lg:w-[384px]">
          <Form {...form}>
            <form className="space-y-9" onSubmit={form.handleSubmit(onSubmit)}>
              {/* Full Name */}
              <FormField
                control={form.control}
                name="fullName"
                render={({ field }) => (
                  <FormItem className="space-y-2 max-md:mt-[40px]">
                    <FormLabel className="text-[16px] leading-[120%] tracking-normal text-[#1d1d1d] mb-[12px] font-normal">
                      Full Name
                    </FormLabel>
                    <FormControl>
                      <Input
                        type="text"
                        className="h-12 px-4 border border-[#e5e5e5] p-[25.5px]  focus:border-[#1d1d1d]"
                        placeholder=""
                        aria-label="Full Name"
                        {...field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              {/* Mobile Number */}
              <FormField
                control={form.control}
                name="phone"
                render={({ field }) => (
                  <FormItem className="space-y-2">
                    <FormLabel className="text-[14px] leading-[120%] tracking-normal text-[#1d1d1d] mb-[12px] font-normal">
                      Mobile Number
                    </FormLabel>
                    <FormControl>
                      <div className="relative">
                        <span className="absolute left-4 top-1/2 transform -translate-y-1/2 text-[#1d1d1d]">
                          +91
                        </span>
                        <Input
                          type="tel"
                          className="h-12 pl-12 pr-4 border p-[25.5px] border-[#e5e5e5]  focus:border-[#1d1d1d]"
                          placeholder=""
                          aria-label="Mobile Number"
                          {...field}
                        />
                      </div>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              {/* Email */}
              <FormField
                control={form.control}
                name="email"
                render={({ field }) => (
                  <FormItem className="space-y-2">
                    <FormLabel className="text-[14px] leading-[120%] tracking-normal text-[#1d1d1d] mb-[12px] font-normal">
                      Email
                    </FormLabel>
                    <FormControl>
                      <Input
                        type="email"
                        className="h-12 px-4 p-[25.5px] border border-[#e5e5e5] focus:border-[#1d1d1d]"
                        placeholder=""
                        aria-label="Email"
                        {...field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              {/* Comments */}
              <FormField
                control={form.control}
                name="comments"
                render={({ field }) => (
                  <FormItem className="space-y-2">
                    <FormLabel className="text-[14px] leading-[120%] tracking-normal text-[#1d1d1d] mb-[12px] font-normal">
                      Add your comments
                    </FormLabel>
                    <FormControl>
                      <textarea
                        className="h-28 px-4 py-2 p-[25.5px] border border-[#e5e5e5]  focus:border-[#1d1d1d] w-full"
                        placeholder=""
                        aria-label="Comments"
                        {...field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <Button
                disabled
                className="w-[384px] max-md:w-full font-medium tracking-normal leading-[120%] rounded-full py-[12px] text-[14px] bg-[#303030] hover:bg-[#262626] disabled:text-[#b5b5b5] disabled:bg-[#e8e8e8] disabled:opacity-100"
              >
                SUBMIT
              </Button>
            </form>
          </Form>
        </div>
      </div>
      <Footer />
    </div>
  );
}
