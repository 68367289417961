import React, {
  createContext,
  useContext,
  useState,
  useMemo,
  Dispatch,
  SetStateAction,
  ReactNode,
} from "react";

// Define the shape of the context
interface RegistrationContextType {
  step: number;
  setStep: Dispatch<SetStateAction<number>>;
  type: string;
  setType: Dispatch<SetStateAction<string>>;
  selectedOption: string;
  setSelectedOption: Dispatch<SetStateAction<string>>;
  selectedAccountType: string;
  setSelectedAccountType: Dispatch<SetStateAction<string>>;
  userId: string | undefined;
  setUserId: Dispatch<SetStateAction<string | undefined>>;
  aadharAddressFetched: string;
  setAadharAddressFetched: Dispatch<SetStateAction<string>>;
}

// Create Context with default values
const RegistrationContext = createContext<RegistrationContextType | undefined>(
  undefined,
);

// Provide Context to the App
export const RegistrationProvider = ({ children }: { children: ReactNode }) => {
  const [step, setStep] = useState<number>(0);
  const [type, setType] = useState<string>("detail");
  const [selectedOption, setSelectedOption] = useState<string>("");
  const [selectedAccountType, setSelectedAccountType] = useState<string>("");
  const [userId, setUserId] = useState<string | undefined>(undefined);
  const [aadharAddressFetched, setAadharAddressFetched] = useState<string>("");
  // Memoize the context value to avoid re-creation on every render
  const value = useMemo(
    () => ({
      step,
      setStep,
      type,
      setType,
      selectedOption,
      setSelectedOption,
      selectedAccountType,
      setSelectedAccountType,
      userId,
      setUserId,
      aadharAddressFetched,
      setAadharAddressFetched,
    }),
    [
      step,
      type,
      selectedOption,
      selectedAccountType,
      userId,
      aadharAddressFetched,
    ],
  );

  return (
    <RegistrationContext.Provider value={value}>
      {children}
    </RegistrationContext.Provider>
  );
};

// Hook for easier access
export const useRegistrationContext = (): RegistrationContextType => {
  const context = useContext(RegistrationContext);
  if (!context) {
    throw new Error(
      "useRegistrationContext must be used within a RegistrationProvider",
    );
  }
  return context;
};
