import React, { useState } from "react";
import { Link } from "react-router-dom";

const Header = ({ className = "" }: { className?: string }) => {
  const [activeUrl, setActiveUrl] = useState(window.location.pathname);
  const handleNavClick = (url: string) => {
    setActiveUrl(url);
    // window.location.href = url; // Navigate to the new page
  };
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const handleMobileMenuToggle = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);

    let bodyTag = document.querySelector("body");
    if (bodyTag) {
      bodyTag.classList.toggle("overflow-hidden");
    }

    // Add or remove text-[18px] class from all <a> tags
    const links = document.querySelectorAll("nav a");
    links.forEach((link) => {
      if (isMobileMenuOpen) {
        link.classList.remove("text-[18px]", "py-[20px]", "bdr-bottom-gry");
      } else {
        link.classList.add("text-[18px]", "py-[20px]", "bdr-bottom-gry");
      }
    });
  };

  let lastScrollY = window.scrollY;

  window.addEventListener("scroll", () => {
    const notificationBar = document.querySelector(".m-notificationBar");
    const headerremoval = document.querySelector("header");

    if (!notificationBar || !headerremoval) return;

    if (window.scrollY > lastScrollY) {
      // Scrolling down
      notificationBar.classList.add("hidden"); // Hide the notification bar
      headerremoval.classList.remove("mt-[45px]");
      headerremoval.classList.remove("max-sm:mt-[62px]");
      headerremoval.classList.remove("max-md:mt-[45px]");
    } else {
      // Scrolling up
      notificationBar.classList.remove("hidden"); // Show the notification bar
      headerremoval.classList.add("mt-[45px]");
      headerremoval.classList.add("max-sm:mt-[62px]");
      headerremoval.classList.add("max-md:mt-[45px]");
    }

    lastScrollY = window.scrollY;
  });

  const navStyle = {
    transition: "all 0.25s ease-in-out",
  };

  const hamburgerIconSpanStyle = {
    transition: "transform 0.25s ease-in-out",
  };

  return (
    <header
      style={{ borderBottom: "1px solid #e5e5e5" }}
      className={
        "header fixed 2xl:container inset-x-0 flex justify-between items-center max-md:px-[20px] md:px-[48px] 2xl:px-0 py-4 z-10 w-full bg-white z-21 border-0 min-h-[78px] activeHeader transition-none transform-none " +
        className
      }
    >
      <div className="c-preloginHeader__logo">
        <a className="block overflow-visible object-contain" href="/#">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="w-[61px] h-[15px]"
            width="45"
            height="11"
            viewBox="0 0 45 11"
            fill="none"
          >
            <path
              d="M11.4771 7.61276H13.1189C13.3924 8.93931 14.2506 9.7456 15.9747 9.7456C17.395 9.7456 18.4304 9.2126 18.4304 8.09512V8.08097C18.4304 6.88484 17.4685 6.53856 16.1889 6.24264L14.7242 5.90456C12.9046 5.48558 11.7433 4.63572 11.7433 2.87604V2.85482C11.7433 1.13163 13.3779 0 15.6344 0C17.8909 0 19.3478 1.11748 19.6364 3.02739H18.0018C17.6839 1.76676 16.8622 1.25498 15.5534 1.25498C14.2446 1.25498 13.3561 1.80325 13.3561 2.80446V2.81861C13.3561 3.72702 13.9626 4.23201 15.0871 4.49851L16.5591 4.84479C18.6969 5.3495 20.0432 5.93993 20.0432 7.88633V7.90868C20.0432 9.94052 18.2904 11.0006 15.9154 11.0006C13.1935 11.0006 11.7659 9.54502 11.4773 7.61276H11.4771Z"
              fill="#1D1D1D"
            />
            <path
              d="M21.2817 0.202148H22.8496V10.7984H21.2817V0.202148Z"
              fill="#1D1D1D"
            />
            <path
              d="M24.1548 5.50764V5.4935C24.1548 2.29947 26.3004 0 29.5919 0C32.144 0 33.9787 1.26177 34.5408 3.34538H32.8989C32.4477 2.00355 31.1159 1.33334 29.607 1.33334C27.2248 1.33334 25.8196 3.05653 25.8196 5.4935V5.50764C25.8196 7.95875 27.2248 9.68053 29.607 9.68053C31.5375 9.68053 33.1283 8.5489 33.1283 6.59571V6.51593H29.3481V5.23294H34.622V10.798H33.335L33.3132 9.1911C32.6916 10.1643 31.3676 11 29.5554 11C26.3076 11 24.1548 8.7144 24.1548 5.50764Z"
              fill="#1D1D1D"
            />
            <path
              d="M36.3226 0.202148H38.3791L43.4832 8.90269H43.4978L43.4832 6.63943V0.202148H45V10.7984H42.9284L37.8388 2.09791H37.8243L37.8388 4.36117V10.7984H36.3226V0.202148Z"
              fill="#1D1D1D"
            />
            <path
              d="M0 6.39433V4.6652L10.8746 0.199219V1.87205L7.31739 3.31064C7.31739 3.31064 7.78715 4.2513 7.78715 5.61605C7.78715 6.9808 7.3139 7.90986 7.3139 7.90986L10.8746 9.33911V10.8603L0 6.39433ZM6.05325 3.82496L1.64331 5.61718V5.63133L6.05964 7.40629C6.05964 7.40629 6.42837 6.66847 6.42837 5.61605C6.42837 4.56363 6.05325 3.82496 6.05325 3.82496Z"
              fill="#1D1D1D"
            />
          </svg>
        </a>
      </div>

      <div className="flex gap-5 items-center justify-end w-full">
        <div className="headerOverlay hidden" aria-hidden="true"></div>
        <nav
          style={navStyle}
          className={`absolute inset-x-0  max-md:justify-between w-max mx-auto flex space-x-8 max-sm:right-0 max-md:right-[12%] max-md:transition-none max-md:transform max-md:translate-y-0 max-sm:top-[78px] max-sm:w-full max-md:p-[16px] max-md:px-[20px] z-[9] max-md:bg-white max-md:border-t max-md:border-b max-md:border-[#e8e8e8] ${
            isMobileMenuOpen
              ? "flex-col h-screen w-full max-md:!justify-start !space-x-0  bg-white " // Open state
              : "" // Closed state
          }`}
          role="navigation"
        >
          {/* Menu Links */}
          <Link
            to="/artist"
            onClick={(e) => {
              // e.preventDefault();
              handleNavClick("/artist");
            }}
            className={`no-underline text-[14px] font-normal leading-[120%] tracking-[0] text-[#4f4f4f] relative px-0 py-2 overflow-x-hidden ${
              activeUrl === "/artist" ? "m-button__tertiary" : ""
            }`}
          >
            Artists
          </Link>
          <Link
            to="/business"
            onClick={(e) => {
              // e.preventDefault();
              handleNavClick("/business");
            }}
            className={`no-underline text-[14px] font-normal leading-[120%] tracking-[0] text-[#4f4f4f] relative px-0 py-2 overflow-x-hidden ${
              activeUrl === "/business" ? "m-button__tertiary" : ""
            }`}
          >
            Business
          </Link>
          <Link
            to="/collectors"
            onClick={(e) => {
              // e.preventDefault();
              handleNavClick("/collectors");
            }}
            className={`no-underline text-[14px] font-normal leading-[120%] tracking-[0] text-[#4f4f4f] relative px-0 py-2 overflow-x-hidden ${
              activeUrl === "/collectors" ? "m-button__tertiary" : ""
            }`}
          >
            Collectors
          </Link>
          <Link
            to="/everyone"
            onClick={(e) => {
              // e.preventDefault();
              handleNavClick("/everyone");
            }}
            className={`no-underline text-[14px] font-normal leading-[120%] tracking-[0] text-[#4f4f4f] relative px-0 py-2 overflow-x-hidden ${
              activeUrl === "/everyone" ? "m-button__tertiary" : ""
            }`}
          >
            Everyone
          </Link>
        </nav>
        <div className="flex gap-5 items-center max-sm:hidden">
          <button
            onClick={() => (window.location.href = "login")}
            className=" relative inline-flex items-center justify-center text-[14px] max-md:text-[12px] font-medium leading-[120%] h-[46px]  gap-1  login-btn px-[28px] py-[14px] text-[#303030] hover:border-[#696969]"
            aria-label="Login"
          >
            <span className="m-button__text">LOG IN</span>
          </button>
          <a
            href="/register"
            target="_blank"
            rel="noopener noreferrer"
            className="relative inline-flex items-center justify-center text-[14px]  max-md:text-[12px] font-medium leading-[120%]  h-[46px]  gap-1  login-btn px-[28px] py-[14px] bg-[#1D1D1D] hover:bg-[#353535]  text-white "
            aria-label="Get Asign"
          >
            <span className="m-button__text">GET ASIGN</span>
          </a>
        </div>
      </div>
      <div className="c-preloginHeader__mobileHeader block md:hidden">
        <button
          className="c-preloginHeader__mobileMenu"
          aria-label="Toggle Mobile Menu"
          aria-expanded={isMobileMenuOpen}
          onClick={handleMobileMenuToggle}
        >
          <div className="c-preloginHeader__hamburgerIcon flex flex-col justify-center items-center">
            {isMobileMenuOpen ? (
              <>
                <svg
                  style={hamburgerIconSpanStyle}
                  viewBox="0 0 12 12"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                >
                  <line
                    x1="1"
                    y1="11"
                    x2="11"
                    y2="1"
                    stroke="black"
                    strokeWidth="1"
                  />
                  <line
                    x1="1"
                    y1="1"
                    x2="11"
                    y2="11"
                    stroke="black"
                    strokeWidth="1"
                  />
                </svg>
              </>
            ) : (
              <>
                <span style={hamburgerIconSpanStyle}></span>
              </>
            )}
          </div>
        </button>
      </div>

      {/* <div className="c-preloginHeader__mobileHeader block md:hidden">
        <button
          className="c-preloginHeader__mobileMenu"
          aria-label="Toggle Mobile Menu"
          aria-expanded={isMobileMenuOpen}
          onClick={handleMobileMenuToggle}
        >
          <div className="c-preloginHeader__hamburgerIcon flex flex-col justify-center items-center">
          {isMobileMenuOpen ? (
      <>
        <span  style={hamburgerIconSpanStyle} className="block w-6 h-0.5 bg-gray-800 rotate-[134deg] translate-y-[0.2rem]"></span>
        <span  style={hamburgerIconSpanStyle} className="block w-6 h-0.5 bg-gray-800 rotate-[45deg] translate-y-[0.1rem]"></span>
      </>
    ) : (
      <>
        <span  style={hamburgerIconSpanStyle} className="block w-6 h-0.5 bg-gray-800 mb-[0.35rem]"></span>
        <span  style={hamburgerIconSpanStyle} className="block w-6 h-0.5 bg-gray-800 mb-[0.35rem]"></span>
        <span  style={hamburgerIconSpanStyle} className="block w-6 h-0.5 bg-gray-800"></span>
      </>
    )}
          </div>
        </button>
      </div> */}
      {/* {
        <div className="fixed m-notificationBar bg-[#f2f2f2] max-md:px-[20px]  max-md:items-start max-md:justify-start max-md:py-[14px] py-3.5 flex flex-col justify-center items-center top-0 left-0 right-0 z-21 mx-auto">
          <div className="2xl:container flex flex-col justify-center max-md:justify-start items-center max-md:items-start">
            <button
              type="button"
              className="absolute 2xl:right-[200px] w-6 h-6 max-md:w-5 max-md:h-5 right-0 max-md:mr-[20px] max-2xl:mr-[48px] cls-signup"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <g stroke="#1D1D1D" stroke-miterlimit="10" clip-path="url(#a)">
                  <path d="M12 21.5c5.2467 0 9.5-4.2533 9.5-9.5 0-5.24671-4.2533-9.5-9.5-9.5-5.24671 0-9.5 4.25329-9.5 9.5 0 5.2467 4.25329 9.5 9.5 9.5Zm-3.5-13 7 7m0-7-7 7"></path>
                </g>
                <defs>
                  <clipPath id="a">
                    <path fill="#fff" d="M0 0h24v24H0z"></path>
                  </clipPath>
                </defs>
              </svg>
            </button>
          </div>
        </div>
      } */}
    </header>
  );
};

export default Header;
