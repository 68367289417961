import React, { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useAuth } from "@/hooks/useAuth";
import Cookies from "universal-cookie";
import BottomNavBar from "@/components/Global/BottomBarNav";
const COOKIE_OPTIONS = {
  path: "/",
  secure: true, // Ensures cookies are only sent over HTTPS
  sameSite: "strict" as const, // Protects against CSRF
  domain: window.location.hostname, // Explicitly set the domain
};

export const clearAllCookies = () => {
  const cookies = new Cookies();
  // Get all cookies
  const allCookies = cookies.getAll();

  // Clear each cookie individually with proper options
  Object.keys(allCookies).forEach((cookieName) => {
    cookies.remove(cookieName, COOKIE_OPTIONS);

    // Also try clearing with different variations of the domain
    const domain = window.location.hostname;
    cookies.remove(cookieName, { ...COOKIE_OPTIONS, domain: domain });
    cookies.remove(cookieName, { ...COOKIE_OPTIONS, domain: `.${domain}` });

    // Clear from root path
    cookies.remove(cookieName, { ...COOKIE_OPTIONS, path: "/" });

    // For development environments, also try clearing without secure flag
    if (process.env.NODE_ENV === "development") {
      cookies.remove(cookieName, { ...COOKIE_OPTIONS, secure: false });
    }
  });

  // Additionally clear using native document.cookie for redundancy
  document.cookie.split(";").forEach((cookie) => {
    const name = cookie.split("=")[0].trim();
    document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/;`;
    document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/; domain=${window.location.hostname};`;
  });
};
const ProtectedLayout = ({ children }: { children: React.ReactNode }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { isLogin, isLoading } = useAuth();

  useEffect(() => {
    if (!isLoading && !isLogin) {
      clearAllCookies();
      // Preserve the attempted URL to redirect back after login
      window.location.href = "/login";
    }
  }, [isLogin, isLoading, navigate, location]);

  // Show nothing while checking authentication
  if (isLoading) {
    return null; // Or return a loading spinner component
  }

  // If not authenticated, render nothing (redirect happens in useEffect)
  if (!isLogin) {
    return null;
  }

  // If authenticated, render children
  return (
    <>
      <div className="">{children}</div> <BottomNavBar />
    </>
  );
};

export default ProtectedLayout;
