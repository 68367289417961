import React, { createContext, useContext, useState } from "react";
import { useDebouncedCallback } from "use-debounce";

type ViewPageContextType = {
  filterOption: any;
  setFilterOption: (option: any) => void;
  setQuery: (query: object) => void;
  removeAllQuery: () => void;
  search: (value: string) => void;
};

const ViewPageContext = createContext<ViewPageContextType>(
  {} as ViewPageContextType,
);

export const ViewPageContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [filterOption, setFilterOption] = useState<any>(null);
  const setQuery = (query: object) => {
    setFilterOption((prev: any) => ({
      ...prev,
      filters: {
        ...prev.filters,
        ...query,
      },
    }));
  };
  const removeAllQuery = () => {
    setFilterOption(null);
  };

  const search = useDebouncedCallback((value: string) => {
    setFilterOption({
      ...filterOption,
      searchQuery: value,
    });
  }, 700);
  return (
    <ViewPageContext.Provider
      value={{
        filterOption,
        setFilterOption,
        setQuery,
        removeAllQuery,
        search,
      }}
    >
      {children}
    </ViewPageContext.Provider>
  );
};

export const useViewPageContext = () => useContext(ViewPageContext);
