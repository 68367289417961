import React, { useEffect, useRef, useState } from "react";
import { Skeleton } from "./ui/skeleton";

interface LazyLoadProps {
  children: React.ReactNode;
  threshold?: number;
  rootMargin?: string;
  forcedLoad?: boolean; // New prop to force loading
  [key: string]: any;
}

const LazyLoad: React.FC<LazyLoadProps> = ({
  children,
  threshold = 0, // Changed from 0.1 to 0 to trigger on any visibility
  rootMargin = "0px", // Changed from "50px" to "0px"
  forcedLoad = false, // New prop with default false
  ...props
}) => {
  const [isVisible, setIsVisible] = useState(forcedLoad); // Initial state depends on forcedLoad
  const [hasLoaded, setHasLoaded] = useState(forcedLoad);
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    // If forcedLoad is true, we don't need to use Intersection Observer
    if (forcedLoad) {
      setIsVisible(true);
      setHasLoaded(true);
      return;
    }

    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting && !hasLoaded) {
          setIsVisible(true);
          setTimeout(() => setHasLoaded(true), 300);
          observer.disconnect();
        }
      },
      {
        threshold,
        rootMargin,
      },
    );

    const currentRef = containerRef.current;
    if (currentRef) observer.observe(currentRef);

    return () => {
      if (currentRef) observer.unobserve(currentRef);
    };
  }, [hasLoaded, threshold, rootMargin, forcedLoad]);

  return (
    <div
      ref={containerRef}
      {...props}
      style={{
        position: "relative",
        transition: "opacity 0.3s ease-in-out",
        opacity: isVisible ? 1 : 0,
      }}
    >
      {isVisible ? children : <Skeleton />}
    </div>
  );
};

export default LazyLoad;
