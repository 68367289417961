import useGlobalApis from "@/apis/global";
import { Badge } from "@/components/ui/badge";
import { getContentImageAndTitle } from "@/helper";
import { cn } from "@/lib/utils";
import { Content, sectionType } from "@/types/global.type";
import React, { useEffect, useRef, useState } from "react";
import Slider from "react-slick";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import toast from "react-hot-toast";
import { useUserContext } from "@/context/user";
import Image from "@/components/Global/Image";
import { getId } from "@/lib/helper";
import { useDebouncedCallback } from "use-debounce";
import { Check } from "lucide-react";
import { useMediaQuery } from "@mui/material";

interface SurveyProps {
  title: string;
  data: any[];
  isImage?: boolean;
  type: sectionType;
  sectionId?: string;
  isDiscover?: boolean;
}

const Survey = ({
  title,
  data,
  isImage,
  type,
  sectionId,
  isDiscover,
}: SurveyProps) => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [containerWidth, setContainerWidth] = useState(0);
  const sliderRef = useRef<Slider | null>(null);
  const isMobile = useMediaQuery("(max-width: 696px)");
  const { submitSurvey } = useGlobalApis();
  const next = () => {
    sliderRef.current?.slickNext();
  };

  const previous = () => {
    sliderRef.current?.slickPrev();
  };

  const renderData = () => {
    if (!isImage) {
      return data[0];
    }
    if (isImage && isDiscover) {
      return data[0];
    }
    if (!isDiscover) {
      return {
        title: title,
        tag: "heading",
        items: data,
      };
    }
    return {}; // Default return value to avoid undefined
  };

  useEffect(() => {
    const container = containerRef.current;

    if (!container) return;

    const updateWidth = () => setContainerWidth(container.offsetWidth);
    const resizeObserver = new ResizeObserver(updateWidth);
    resizeObserver.observe(container);
    updateWidth();

    return () => resizeObserver.disconnect();
  }, []);

  const shouldShowSingleSlide = () => {
    if (containerWidth < 375) {
      return {
        toShow: 3,
        toScroll: 3,
        rows: 2,
      };
    } else if (containerWidth >= 375 && containerWidth < 600) {
      return {
        toShow: 3,
        toScroll: 3,
        rows: 2,
      };
    } else if (containerWidth >= 600 && containerWidth < 1024) {
      return {
        toShow: 4,
        toScroll: 4,
        rows: 1,
      };
    } else {
      // For widths 1024px and above
      return {
        toShow: 6,
        toScroll: 6,
        rows: 1,
      };
    }
  };

  const slideSettings = shouldShowSingleSlide();

  const sliderSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: slideSettings.toShow,
    slidesToScroll: slideSettings.toScroll,
    arrows: false,
    rows: slideSettings.rows,
  };

  const queryClient = useQueryClient();
  const { interactions } = useUserContext();
  const isSurveyAlreadySubmitted = interactions?.surveyResponses.find(
    (response) => response.sectionId === sectionId,
  );
  const submitSurveyMutation = useMutation({
    mutationFn: (response: string) => {
      //check if the response is already submitted then deselect it by removing the response from the array
      if (isSurveyAlreadySubmitted?.response?.ans?.includes(response)) {
        const updatedResponse = (
          isSurveyAlreadySubmitted?.response?.ans || []
        ).filter((ans: string) => ans !== response);
        return submitSurvey(sectionId ?? "", type, updatedResponse);
      }
      return submitSurvey(sectionId ?? "", type, [
        ...(isSurveyAlreadySubmitted?.response?.ans || []),
        response,
      ]);
    },
    onSuccess: () => {
      toast.dismiss();
      toast.success("Thanks for your response!");
      queryClient.invalidateQueries({ queryKey: ["user-interactions"] });
    },
    onError: () => {
      toast.dismiss();
      toast.error("Error submitting survey. Please try again.");
    },
    onMutate: () => {
      toast.loading("Submitting your response...");
    },
  });

  const debouncedSubmitSurveyMutation = useDebouncedCallback(
    submitSurveyMutation.mutate,
    300,
  );
  return (
    <div
      className={cn(
        "w-full px-10 text-center font-sh5 bg-bg-5 h-full",
        isImage ? "pt-[40px] pb-[24px]" : "pt-[68px] pb-[76px]",
        isImage && isMobile && "px-[32px] pt-[28px] pb-[23px]",
      )}
      ref={containerRef}
      id="survey-container"
    >
      <div className="flex flex-col items-center justify-center h-full">
        <h2
          className={`w-full font-normal capitalize font-eb text-[24px] text-[#1D1D1D] ${
            isImage ? " leading-[120%] " : " leading-[38.4px]"
          } leading-[36.52px] mq750:text-5xl`}
        >
          {renderData().title}
        </h2>
        <p className="pt-2 text-xs font-normal capitalize text-gray-60">
          {renderData().tag}
        </p>

        {/* Render options or images based on isImage prop */}
        {!isImage ? (
          <div className="flex flex-wrap justify-center gap-2 mt-10">
            <SubmitOptions
              options={renderData().options || []}
              sectionId={sectionId || ""}
              type={type}
              submitSurveyMutation={debouncedSubmitSurveyMutation}
            />
          </div>
        ) : (
          <div className="relative w-full mt-10 group">
            <div className="flex flex-row items-center gap-[5px] mq1000:hidden">
              {[true, false].map((flip, index) => (
                <img
                  key={index}
                  className={cn(
                    "p-[3px] bg-[#EEEEEA] rounded-[100%] h-[20px] w-[20px] absolute top-1/2  cursor-pointer group-hover:visible invisible z-10",
                    flip && " left-2 rotate-180 ",
                    !flip && "right-2  ",
                  )}
                  src="/arrow-right.svg"
                  alt={flip ? "Previous" : "Next"}
                  onClick={flip ? previous : next}
                />
              ))}
            </div>
            <Slider {...sliderSettings} ref={sliderRef}>
              {renderData()?.items?.map((item: Content) => (
                <div
                  className={cn(
                    "relative w-full h-full overflow-hidden aspect-square  [@media(max-width:696px)]:my-[3px] -my-3 cursor-pointer ",
                  )}
                  key={getId(item)}
                  onClick={() => debouncedSubmitSurveyMutation(getId(item))}
                >
                  <Image
                    src={getContentImageAndTitle(item).image}
                    className="object-cover w-full h-full"
                    alt={getContentImageAndTitle(item).title}
                  />
                  {isSurveyAlreadySubmitted?.response?.ans?.includes(
                    getId(item),
                  ) && (
                    <div className="absolute inset-0 flex items-center justify-center bg-black/30 w-full">
                      <img
                        src="/white-tickmark.svg"
                        alt="tickmark"
                        className="w-[30px] h-[30px]"
                      />
                    </div>
                  )}
                </div>
              ))}
            </Slider>
          </div>
        )}
      </div>
    </div>
  );
};

export default Survey;

const SubmitOptions = ({
  options,
  sectionId,
  type,
  submitSurveyMutation,
}: {
  options: string[];
  sectionId: string;
  type: sectionType;
  submitSurveyMutation: any;
}) => {
  const { interactions } = useUserContext();

  const isSurveyAlreadySubmitted = interactions?.surveyResponses.find(
    (response) => response.sectionId === sectionId,
  );
  const handleSubmit = (option: string) => {
    submitSurveyMutation(option);
  };
  // console.log(isSurveyAlreadySubmitted?.response?.ans);

  return options.map((option, index) => (
    <Badge
      variant={
        !isSurveyAlreadySubmitted?.response?.ans?.includes(option)
          ? "outline"
          : "secondary"
      }
      className={cn(
        "p-[10px_20px]  cursor-pointer capitalize text-xs border border-gray-[#696969]",
        !isSurveyAlreadySubmitted?.response?.ans?.includes(option)
          ? "text-gray-60"
          : "text-white",
      )}
      key={option + crypto.randomUUID() + index}
      onClick={() => handleSubmit(option)}
    >
      {option}
    </Badge>
  ));
};
