import { apis } from ".";
import { QueryData } from "@/hooks/useFilter";
import { Artist, Section } from "@/types/atrists.type";
import { User } from "@/types/user.types";

export interface ArtObject {
  id: string;
  objectName: string;
  descriptiveTitle?: string;
  type?: string;
  image?: string;
  frontImage?: string;
  backImage?: string;
  desktopImage?: string;
  mobileImage?: string;
  aboutStatus?: "verified" | "unverified";
  objectInpossession?: boolean;
  currentLocation?: Location;
  pastLocations?: Location[];
  description?: string;
  objectId: string;
  publishedYear?: string;
  metaData: MetaData;
  price?: number;
  assignProtect?: boolean;
  soldBy?: string;
  exhibitions?: Record<string, unknown>;
  publications?: Publication[];
  provenance?: Provenance;
  components?: Component[];
  document?: Documentation;
  artistName?: string;
  artist?: User; // ObjectId reference
  forSale?: boolean;
  isDeleted?: boolean;
  isActive?: boolean;
  createdAt?: Date;
  updatedAt?: Date;
  addedBy?: User; // ObjectId reference
  updatedBy?: string; // ObjectId reference
  isEligibleForAp?: boolean;
  parentLabel?: Label;
  childLabel?: Label[];
  status?:
    | "pending"
    | "approved"
    | "rejected"
    | "ineligible"
    | "eligible"
    | "request";
}

// Sub-interfaces for nested schemas
export interface Location {
  lineOne?: string;
  lineTwo?: string;
  country?: string;
  state?: string;
  city?: string;
  pinCode?: string;
  addressType?: string;
  subAddressType?: string;
  locationId?: string;
  status?: "verified" | "unverified";
}

export interface MetaData {
  medium: string[];
  movement: string;
  surface: string[];
  measurementType?: string;
  dimensionsType?: "cm" | "inch" | "mm";
  height?: number;
  width?: number;
  depth?: number;
  diameter?: number;
  weightType?: "kg" | "lbs";
  weight?: number;
  year?: number;
  shape?: string;
  signature?: string;
  inscriptions?: Inscriptions;
  technique?: string[];
  style?: string;
  subject?: string[];
  objectTypeOther?: string;
  creationDate?: DateDetail;
  compeletionDate?: DateDetail;
  mediumMeasurementStatus?: "verified" | "unverified";
  characteristicsStauts?: "verified" | "unverified";
  signatureInscriptionsStatus?: "verified" | "unverified";
}

export interface Inscriptions {
  verso?: string;
  recto?: string;
  base?: string;
}

export interface DateDetail {
  year?: number;
  era?: string;
}

export interface Provenance {
  overview?: Overview[];
  auctionHistory?: AuctionHistory[];
  exhibitionHistory?: ExhibitionHistory[];
  publicationHistory?: PublicationHistory[];
}

export interface Overview {
  title?: string;
  status?: "verified" | "unverified";
}

export interface AuctionHistory {
  auctionHouse?: string;
  name?: string;
  type?: string[];
  location?: string;
  saleNumber?: string;
  lotNumber?: string;
  price?: number;
  startDate?: Date;
  endDate?: Date;
  status?: "verified" | "unverified";
}

export interface ExhibitionHistory {
  image?: string;
  name?: string;
  hostedBy?: string;
  venue?: string;
  location?: Location;
  startDate?: Date;
  endDate?: Date;
  status?: "verified" | "unverified";
}

export interface PublicationHistory {
  image?: string;
  authorName?: string;
  name?: string;
  location?: string;
  date?: Date;
  publishedBy?: string;
  pageNumber?: number;
  status?: "verified" | "unverified";
}

export interface Documentation {
  objectIdentification?: ObjectIdentification;
  secondaryMeasurements?: Measurement[];
  valuation?: Valuation;
  records?: Records;
  media?: Media;
}

export interface ObjectIdentification {
  code?: string;
  accessionNumber?: string;
  inventoryNumber?: string;
  notes?: string;
  status?: "verified" | "unverified";
}

export interface Measurement {
  measurementType?: string;
  dimensionsType?: "cm" | "inch" | "mm";
  height?: number;
  width?: number;
  depth?: number;
  diameter?: number;
  weightType?: "kg" | "lbs";
  weight?: number;
  shape?: string;
}

export interface Valuation {
  acquisitionDetails?: AcquisitionDetails;
  valuationDetail?: ValuationDetail[];
}

export interface AcquisitionDetails {
  type?: string;
  currency?: string;
  price?: number;
  discount?: number;
  tax?: number;
  paidInFullDate?: Date;
  notes?: string;
}

export interface ValuationDetail {
  date?: Date;
  doneBy?: string;
  status?: string;
  type?: string;
  basis?: string;
  approach?: string;
  currency?: string;
  lowValue?: number;
  highValue?: number;
  notes?: string;
}

export interface Records {
  document?: Document[];
  insurance?: Insurance;
  loans?: Loan;
  conditionReport?: ConditionReport[];
}

export interface Document {
  type?: string;
  name?: string;
  file?: string;
}

export interface Insurance {
  policyId?: string;
  insuredValue?: number;
  marketValue?: number;
  replacementValue?: number;
}

export interface Loan {
  type?: "borrowed" | "loan" | "no";
  file?: string;
  name?: string;
  startDate?: Date;
  endDate?: Date;
  insuredBy?: "me" | "borrower" | "uninsured";
  shippedBy?: "me" | "borrower";
}

export interface ConditionReport {
  date?: Date;
  currentCondtion?: string;
  file?: string;
}

export interface Media {
  addtionImages?: MediaItem[];
  videos?: MediaItem[];
  archivedImage?: MediaItem[];
  archivedVideos?: MediaItem[];
  status?: "verified" | "unverified";
}

export interface MediaItem {
  title?: string;
  image?: string;
  video?: string;
}

export interface Label {
  inventoryLabel?: LabelDetail;
  authenticityLabel?: LabelDetail;
}

export interface LabelDetail {
  envelopeCode?: string;
  labelCode?: string;
  image?: string[];
}

export interface Component {
  code?: string;
  accessionNumber?: string;
  image?: string;
  shape?: string;
  medium?: string[];
  surface?: string[];
  technique?: string[];
  signature?: string;
  inscriptions?: Inscriptions;
  location?: Location;
  measurementType?: string;
  dimensionsType?: "cm" | "inch" | "mm";
  height?: number;
  width?: number;
  depth?: number;
  diameter?: number;
  weightType?: "kg" | "lbs";
  weight?: number;
  status?: "verified" | "unverified";
}

export interface Publication {
  publicationName?: string;
  publicationBusiness?: string;
  publicationDate?: Date;
}

const useObjects = () => {
  const getFeed = async (
    params: QueryData,
  ): Promise<{
    data: Section[];
  }> => {
    try {
      const { data } = await apis.post("/client/api/v1/objects_section/list", {
        data: params,
      });
      return data.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  const objectList = async (params: QueryData) => {
    try {
      const { data } = await apis.post("/client/api/v1/objects/list", {
        data: params,
      });
      return data?.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  const getObjectById = async ({ id }: { id: string }): Promise<ArtObject> => {
    try {
      const { data } = await apis.get(`client/api/v1/objects/${id}`, {});
      return data.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  const addObjects = async (object: any) => {
    try {
      const { data } = await apis.post("/client/api/v1/objects/create", {
        data: object,
      });
      return data.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  const updateObject = async ({ id, data }: { id: string; data: any }) => {
    try {
      const response = await apis.put(`/client/api/v1/objects/update/${id}`, {
        data,
      });
      return response.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  const deleteObject = async ({ id }: { id: string }) => {
    try {
      const response = await apis.delete(
        `/client/api/v1/objects/delete/${id}`,
        {},
      );
      return response.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  const getObjectCount = async () => {
    try {
      const { data } = await apis.get("/client/api/v1/objects/count", {});
      return data.data.totalRecords;
    } catch (error) {
      throw error;
    }
  };

  const getMastersSchema = async (object: string) => {
    try {
      const { data } = await apis.get(
        `/client/api/v1/master/list/${object}`,
        {},
      );
      console.log("data");
      return data.data;
    } catch (error) {
      throw error;
    }
  };
  return {
    getFeed,
    getObjectById,
    addObjects,
    updateObject,
    objectList,
    deleteObject,
    getObjectCount,
    getMastersSchema,
  };
};

export default useObjects;
