import { cn } from "@/lib/utils";
import Sidebar from "@/pages/profile/Sidebar";
import React, { FunctionComponent, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { MobileHeader } from "./MobileHeader";
import useMediaQuery from "@/hooks/use-media-query";
import FingureSearch from "@/components/Search/modal/fingureSearch";
import FigureSearch from "@/components/Search/modal/fingureSearch";

interface HeaderProps {
  scrollToSection?: (label: string) => void;
  currentSection?: string;
  className?: string;
}

const Header: React.FC<HeaderProps> = ({
  scrollToSection,
  currentSection,
  className,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  let currentLocation = location.pathname;

  // Determine if we are on a parent route or a deeper route
  const isCatalogActive = currentLocation.startsWith("/catalog");
  const isDiscoverActive = [
    "/discover/feed",
    "/business/feed",
    "/objects/feed",
    "/artist/feed",
    "/events/feed",
  ].includes(currentLocation);
  const parentRoutes = [
    "/discover/feed",
    "/business/feed",
    "/objects/feed",
    "/objects/detail",
    "/artist/feed",
    "/events/feed",
    "/catalog/detail",
  ];
  const isParentRoute = parentRoutes.includes(currentLocation);
  const isDesktop = useMediaQuery("(min-width: 1000px)");
  const sideBar = [
    { label: "Biography" },
    // { label: "Catalogue rasionné" },
    { label: "Representation" },
    { label: "Education" },
    { label: "Events" },
    { label: "Recognition" },
    { label: "Press" },
    { label: "Notable collections" },
    { label: "Publications" },
  ];

  return (
    <>
      <header
        className={cn(
          "self-stretch z-[20] mq750:items-center bg-white box-border flex flex-row items-start justify-between py-0 w-full max-w-full gap-[20px] text-left text-sm text-grey100 font-paragraph-p3 border-b-[1px] border-solid border-[#e5e5e5] mq725:box-border font-sh5 font-normal mq450:flex mq450:items-center mq450:px-5 px-[48px]",
          "sticky top-0",
          className
        )}
      >
        <div className="box-border flex flex-row items-center justify-start max-w-full gap-6 py-0 pl-0 overflow-hidden shrink-0 mq450:justify-between mq450:pr-5 mq450:box-border mq725:box-border">
          <img
            loading="lazy"
            alt="Aisgn Logo"
            src="/logo.svg"
            className="cursor-pointer"
            onClick={() => navigate("/discover/feed")}
          />
          <div className="flex flex-row items-center justify-start mq750:hidden">
            <div
              className={cn(
                "flex flex-row items-center justify-center p-4 gap-[8px] cursor-pointer text-gray-60",
                isDiscoverActive && "text-gray-100"
              )}
              onClick={() => navigate("/discover/feed")}
            >
              {isDiscoverActive ? (
                <img
                  className="relative w-6 h-6 overflow-hidden shrink-0"
                  loading="lazy"
                  alt=""
                  src="/menu-icons.svg"
                />
              ) : (
                <img
                  className="relative w-6 h-6 overflow-hidden shrink-0"
                  loading="lazy"
                  alt=""
                  src="/discover-inactive.svg"
                />
              )}
              <div className="relative text-base leading-[17px] inline-block min-w-[53px]">
                Discover
              </div>
            </div>

            <div
              className="flex flex-row items-center justify-center p-4 gap-[8px] text-gray-60 cursor-pointer"
              onClick={() => navigate("/catalog/detail")}
            >
              {isCatalogActive ? (
                <img
                  className="relative w-6 h-6 overflow-hidden shrink-0"
                  alt=""
                  src="/catalog-active.svg"
                />
              ) : (
                <img
                  className="relative w-6 h-6 overflow-hidden shrink-0"
                  alt=""
                  src="/menu-icons-1.svg"
                />
              )}
              <div
                className={cn(
                  "relative leading-[17px] inline-block min-w-[53px] text-base",
                  isCatalogActive && "text-gray-100"
                )}
              >
                Catalogue
              </div>
            </div>
          </div>
        </div>
        <div className="box-border flex flex-col items-start justify-start max-w-full px-0 pt-2 pb-0 mq750:py-4">
          <div className="self-stretch flex flex-row items-center justify-start mq450:justify-end gap-[24px]">
            {!currentLocation.startsWith("/search") && (
              <FingureSearch>
                <div className="flex items-center border border-solid border-[#CFCFCF] mq750:hidden mq450:hidden min-w-[232px] justify-center h-10 rounded-full transition-all duration-300 ease-in-out focus-within:justify-start focus-within:gap-2 focus-within:px-3">
                  <img
                    src="/iconsearch.svg"
                    alt="Search Icon"
                    className="transition-all duration-300 ease-in-out"
                  />
                  <input
                    type="text"
                    className="w-full h-full placeholder:text-sm placeholder:font-sh5 max-w-[70px] tracking-[0.7px] font-sh5 font-normal outline-none transition-all duration-300 ease-in-out placeholder:text-center focus:placeholder:text-left focus:max-w-full border-transparent bg-transparent"
                    placeholder="Search"
                  />
                </div>
              </FingureSearch>
            )}

            <div className="flex-row items-center hidden gap-6 mq450:flex mq750:flex">
              <FigureSearch>
                <img
                  className="hidden w-6 h-6 mq450:block mq750:block"
                  src="/iconsearch.svg"
                />
              </FigureSearch>
              <Sidebar>
                <div className="flex flex-col items-start justify-start px-0 pb-0 cursor-pointer [@media(min-width:751px)]:hidden mq750:hidden">
                  <img
                    className="relative w-6 h-6 overflow-hidden shrink-0"
                    alt=""
                    src="/button-1.svg"
                  />
                </div>
              </Sidebar>
              {/* <img
              className="hidden w-6 h-6 mq450:block mq750:block "
              src="/iconinbox.svg"
            />
            <img
              className="hidden w-6 h-6 mq450:block mq750:block"
              src="/button@2x.png"
            /> */}
            </div>

            <div className="flex items-center h-full">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_2006_45002)">
                  <path
                    d="M21.49 17.5498L21.5 2.50977H2.52L2.51 17.7498L2.5 21.0398L7.11 17.5798L21.49 17.5498Z"
                    stroke="#1D1D1D"
                    stroke-miterlimit="10"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_2006_45002">
                    <rect width="24" height="24" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </div>

            <div className="flex items-center h-full">
              <svg
                width="22"
                height="22"
                viewBox="0 0 22 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M20.5 21.5098H1.5L5 6.50977H11L11.5 6.5H17L20.5 21.5098Z"
                  stroke="#1D1D1D"
                  stroke-miterlimit="10"
                />
                <path
                  d="M8.03704 10C8.03704 10 7.44444 1 11 1C14.5556 1 13.963 10 13.963 10"
                  stroke="#1D1D1D"
                  stroke-miterlimit="10"
                />
              </svg>
            </div>

            {/* <div className="flex flex-col items-start justify-start px-0 pt-2 pb-0 mq750:hidden mq450:hidden">
            <div className="flex flex-row items-center justify-end relative gap-[24px]">
              <img
                className="relative w-6 h-6 overflow-hidden shrink-0"
                loading="lazy"
                alt=""
                src="/iconinbox.svg"
              />

              <div className="h-[9px] w-[9px] absolute !m-[0] top-[-1.5px] right-[-1px] rounded-[50%] bg-red-100 box-border z-[1] border-[0px] border-solid border-white" />
            </div>
          </div>
          <div className="flex flex-col items-start justify-start px-0 pt-2 pb-0 mq750:hidden mq450:hidden">
            <img
              className="relative object-cover w-6 h-6 overflow-hidden shrink-0"
              loading="lazy"
              alt=""
              src="/button@2x.png"
            />
          </div> */}
            <Sidebar>
              <div className="flex flex-col z-[999999] items-start justify-start px-0 pb-0 cursor-pointer mq450:hidden mq750:hidden">
                <img
                  className="relative w-6 h-6 overflow-hidden shrink-0"
                  alt=""
                  src="/button-1.svg"
                />
              </div>
            </Sidebar>
          </div>
        </div>
      </header>
      {location.pathname === "/user/profile" && (
        <MobileHeader
          data={sideBar}
          active={currentSection}
          onClick={scrollToSection}
        />
      )}
    </>
  );
};

export default React.memo(Header);
